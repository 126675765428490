<template>
	<base-layout>
		<template v-slot:header>
			About The European Broker Meeting
		</template>
		<template v-slot:main>
			<div >
				<div class='bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-6 mb-4'>
					<h2 class="text-lg font-bold leading-5 text-red-700 mt-4 mb-4">The European Broker Meeting</h2>
					www.EuropeanBrokerMeeting.com is a networking event for Computer Traders organized by www.TheBrokerSite.com, Europe’s Leading B2B Computer Trading Exchange.
					In 2004 The Broker Site started organizing the www.EuropeanBrokerMeeting.com. With over 700 traders attending each year, the EBM has grown into the largest get-together for the computer trading industry in Europe.
					The EBM is the opportunity to meet many traders at the same time at the same place. Each year the EBM changes location and it has already been organized in Amsterdam, Athens, Barcelona, Berlin, Budapest, Dublin, Lisbon, Monte Carlo, Paris, Prague, Riga, Rome and Warsaw.
					<br /><br />
					<em>The European Broker Meeting LOGO (1280x144)</em><br />
					<a href="../media/img/ebm_logo_red.jpg" target="_blank" class='text-red-600 hover:underline '>The European Broker Meeting Logo JPEG</a><br />
					<a href="../media/img/ebm_logo_red.png" target="_blank" class='text-red-600 hover:underline '>The European Broker Meeting Logo PNG</a><br />
					<a href="../media/img/ebm_logo_red.bmp" target="_blank" class='text-red-600 hover:underline '>The European Broker Meeting Logo BMP</a><br />
					<a href="../media/img/ebm_logo_red.gif" target="_blank" class='text-red-600 hover:underline '>The European Broker Meeting Logo GIF</a><br />
					<a href="../media/img/ebm_logo_red.tif" target="_blank" class='text-red-600 hover:underline '>The European Broker Meeting Logo TIFF</a><br />
				</div>
			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'AboutEBM',
  components: {
    BaseLayout
  }
}
</script>