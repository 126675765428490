import { mapActions } from 'vuex'
import eventBus from '../../utils/eventBus'

export default {

  data: () => ({
    possibleMatches: {
			doShow: false, 
			broadcastId: null,
			broadcast: null,
		},
  }),
  mounted(){
    eventBus.$on("showPossibleMatchesFromOwnBroadcast", async id => {
			await this.loadPossibleMatches(id, false, true)
		})
	  eventBus.$on("broadcastSend", async ({id}) => {
		  await this.loadPossibleMatches(id)
	  })
  },
  watch: {
		"user.possibleMatchesDisabled"(possibleMatchesDisabled){
			this.updateUserPossibleMatched(possibleMatchesDisabled)
		}
	},
  methods: {
    ...mapActions(['getBroadcast', 'getBroadcastPossibleMatches', 'getBroadcastPossibleMatchesOld', 'updateUserPossibleMatched']),
    async loadPossibleMatches(broadcastId, showOutputStrategy = false, isFromOwnBroadcasts = false){
			if(this.user.possibleMatchesDisabled && !isFromOwnBroadcasts) return; //don't show if user explicitly doesn't want possible matches

			const respBroadcast = await this.getBroadcast(broadcastId)
			const queryListId = respBroadcast.broadcast.listId
			const respPossibleMatches = await this.getBroadcastPossibleMatches({broadcastId, queryListId})
			const respPossibleMatchesOld = await this.getBroadcastPossibleMatchesOld({broadcastId, strategy: this.$route.query.possibleMatchStrategy, showOutputStrategy, isFromOwnBroadcasts})

			// something went wrong, but this shows only extra functionality (possible matches), so we just skip it
			if(respBroadcast.status !== 200 || respPossibleMatches.status !== 200) return;
			// if(!respPossibleMatches.payload.results.length && !isFromOwnBroadcasts) return; // don't show if no results

			// console.log('Query Broadcast List ID', respBroadcast.broadcast.listId)

			this.possibleMatches = {
				doShow: true,
				broadcastId,
				broadcast: respBroadcast.broadcast,
				results: respPossibleMatches.payload.results.slice(0, 10),
				resultsOld: respPossibleMatchesOld.payload.results.slice(0, 10),
			}
		}
  }
}