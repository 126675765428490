<template>
	<base-layout>
		<template v-slot:header>
			Social Media
		</template>
		<template v-slot:main>
			<div >
				<div class='bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-6 mb-4'>
					<br />
					<em>LinkedIn</em><br />
					<a href="https://www.linkedin.com/companies/213490" target="_blank"  class='text-red-600 hover:underline '>The Broker Site BV Company Profile</a><br />
					<a href="https://www.linkedin.com/companies/649818" target="_blank"  class='text-red-600 hover:underline '>The European Broker Meeting Company Profile</a><br />

					<br />
					<em>Twitter</em><br />
					<a href="https://twitter.com/thebrokersite" target="_blank"  class='text-red-600 hover:underline '>The Broker Site</a><br />
					<a href="https://twitter.com/tbs_pc" target="_blank"  class='text-red-600 hover:underline '>Desktop  List</a><br />
					<a href="https://twitter.com/tbs_laptop" target="_blank"  class='text-red-600 hover:underline '>Laptop List</a><br />
					<a href="https://twitter.com/tbs_electronics" target="_blank"  class='text-red-600 hover:underline '>Electronics List</a><br />
					<a href="https://twitter.com/TBS_Software" target="_blank"  class='text-red-600 hover:underline '>Software List</a><br />
					<a href="https://twitter.com/TBS_server" target="_blank"  class='text-red-600 hover:underline '>Server List</a><br />
					<a href="https://twitter.com/tbs_gsm" target="_blank"  class='text-red-600 hover:underline '>Mobile  List</a><br />
					<a href="https://twitter.com/tbs_networking" target="_blank"  class='text-red-600 hover:underline '>Networking List</a><br />
					<a href="https://twitter.com/TBS_printer" target="_blank"  class='text-red-600 hover:underline '>Printer List</a><br />
					<a href="https://twitter.com/EBM_News" target="_blank"  class='text-red-600 hover:underline '>EBM  News</a><br />

					<br />
					<em>Facebook</em><br />
					<a href="https://www.facebook.com/pages/The-Broker-Site/376439571865?v=info" target="_blank"  class='text-red-600 hover:underline '>TBS Facebook Page</a><br />
				</div>
			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'social',
  components: {
    BaseLayout
  }
}
</script>