<template>
	<base-layout>
		<template v-slot:header>
			Inventory Hits
		</template>
		<template v-slot:main>
			<div >
				<div class='-mt-2 -mb-8 text-sm '>
					Total number of hits: <span class='font-bold'>{{nrInventoryHitsTotal}}</span><br/>
				</div>
				<div class="flex flex-col mt-12">
					<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
						<div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
							<table class="min-w-full">
								<thead>
									<tr>
										<th class="table-cell-header">Searched for</th>
										<th class="table-cell-header">Part Found</th>
										<th class="table-cell-header">Searched by</th>
										<th class="table-cell-header">Searched on</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in inventoryHitsEnriched" :key="index" class="bg-white hover:bg-red-50 cursor-pointer transition duration-150 ease-in-out hover-higlight-anchor" :class='{"": index % 2}'>
										<td class="table-cell">{{item.searchterms}}</td>
										<td class="table-cell">{{item.partnumber}}</td>
										<td class="table-cell">
											<a v-if='item.companyid' href='javascript:void(0)' class='text-red-700 hover:underline' @click="navToCompany(item)">{{item.name}}</a>
											<span v-else>{{item.name}}</span>
										</td>
										<td class="table-cell">{{item.dateHuman}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<Pagination :itemList="inventoryHitsEnriched" :payload="payload" :pageSize="pageSize"></Pagination>
			</div>
		</template>
	</base-layout>
</template>

<script>
import _ from 'lodash'
import BaseLayout from '@/components/BaseLayout.vue'
import ListMixin from '@/components/mixins/List'

import { mapActions, mapState, mapGetters } from 'vuex';

export default {
	mixins: [ListMixin],
  name: 'InventoryHits',
  components: {
    BaseLayout,
  },
  data: () => ({
  	pageSize: 15,
		payload: {
  		page: 1,
		},
		fetchActionName: "getInventoryHits", // used in list-mixin 
		numResultsName: "nrInventoryHitsTotal", // used in list-mixin
  }),
  computed: {
  	...mapState([ 'nrInventoryHitsTotal']),
		...mapGetters(['inventoryHitsEnriched']),
  },
  methods: {
  	...mapActions(["getInventoryHits"]),
  	navToCompany(item){
			this.$router.push({ name: 'companyDetail', params: { id: item.companyid } })
		},
  }
}
</script>

