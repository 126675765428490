
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';

const contactOrder = ['Main', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th', '29th', '30th', '31st', '32nd', '33rd', '34th', '35th', '36th', '37th', '38th', '39th', '40th']


export default {
  enrichProfile: (data) => {
    const cacheBreaker = uuidv4().slice(0, 4)
    return {
      status: 200,
      company: _.defaults(data.result, {
        picUrl: data.result.username ? `https://www.thebrokersite.com/images/logos/${data.result.username}.jpg?${cacheBreaker}` : null,
        telLink: `tel:${data.result.tel}`,
        picCSS: {
          width: "240px", 
          height: "60px",
          background: `url('https://www.thebrokersite.com/images/logos/${data.result.username}.jpg?${cacheBreaker}') no-repeat right center / contain`,
          "margin-bottom": "-20px"
        }
      }),
      contacts: _.map(data.result.contacts, (contact, i) => _.extend(contact, {
        label: `${contactOrder[i]} contact`,
        instantMessaging: contact.imValue ? `${contact.imName}: ${contact.imValue}` : null,
        telLink: `tel:${contact.tel}`,
        picCSS: {
          background: `url('${contact.profilePic}') no-repeat right center / cover`,
        }
      }))
    }
  }
}


