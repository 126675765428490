<template>
	<base-layout>
		<template v-slot:header>
			The Broker Site Videos
		</template>
		<template v-slot:main>
			<div >
				<div class='bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-6 mb-4'>
				<br />
				The EBM 2018 :: Amsterdam<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/h1YK01TCp6s" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


				<br />
				The EBM 2018 :: Amsterdam (Teaser Trailer)<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/y-Y-B04edNo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

				<br />
				The EBM 2017 :: Warsaw<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/9btmcGxRVW4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

				<br />
				The EBM 2013 :: Riga<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/2slnhDNX1qk" frameborder="0" allowfullscreen ></iframe>

				<br />
				The EBM 2012 :: We are going to Budapest<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/6KV7oHl29zU" frameborder="0" allowfullscreen ></iframe>

				<br />
				The EBM :: Budapest<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/npdpBVmmCh0" frameborder="0" allowfullscreen ></iframe>

				<br />
				The EBM 2011 :: Thank you for 7 years of Success!<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/3O53URUsNNM" frameborder="0" allowfullscreen ></iframe>

				<br />
				The EBM 2010 Lisbon Portugal:<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/ddmev_Hd9Ac" frameborder="0" allowfullscreen ></iframe>

				<br />
				The EBM Prague:<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/B5-T9laa8tk" frameborder="0" allowfullscreen ></iframe>
				<br />
				The EBM Prague in the words of Kent Jeppesen:<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/paXu4PTItdc" frameborder="0" allowfullscreen ></iframe>
				<br />
				The EBM Prague in the words of Bianca Straver:<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/PosESfPUOkM" frameborder="0" allowfullscreen ></iframe>
				<br />
				The EBM Prague in the words of Cristoffer Sandell:<br />
				<iframe width="480" height="325" src="https://www.youtube.com/embed/Ho7bF8hKlW8" frameborder="0" allowfullscreen ></iframe>
				</div>
			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'videos',
  components: {
    BaseLayout
  }
}
</script>