<template>
	<base-layout :withHeaderStructure="true" v-if='broadcast'>
		<template v-slot:header>
			<nav >
				<router-link :to="{name: 'broadcasts', query: lastSearch.broadcasts}" class="flex items-center text-sm leading-5 font-medium text-gray-400 hover:text-gray-200 focus:outline-none focus:underline transition duration-150 ease-in-out">
					<svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
					<path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
					</svg>
					Back to broadcasts
				</router-link>
			</nav>
			<div class="mt-1 mb-2 md:flex md:items-center md:justify-between">
				<div class="flex-1 min-w-0 mt-2 ">
					<h3 class='leading-7 text-gray-400 text-xl leading-9 sm:truncate tracking-tight'>
						{{broadcast.broadcastListName}} Broadcast, {{broadcast.dateAsString}}
					</h3>
					<h1 class="font-bold leading-7 text-gray-900 text-2xl sm:text-3xl leading-9 sm:truncate tracking-tight">{{broadcast.subject}}</h1>
				</div>
				
				<div v-if='user && user.isServiceOnly===0' class="mt-4 flex md:mt-0 md:ml-4">
					<button @click='showReplyModal' type="button" class="btn btn-primary">Reply to Broadcast</button>
				</div>

			</div>

		</template>
		<template v-slot:main>
			<div>
				<div v-if="!user" class='mb-6 text-sm btn btn-muted text-red-700 border-red-700 hover:text-red-700 block'>
					<a href='javascript:void(0)' @click="toFreeTrialPage" class='hover:underline'>Please register here for your free trial to see full broadcast details!</a>
				</div>
				<div class="grid grid-cols-12 gap-6">
					<div class="col-span-12 lg:col-span-12 bg-white shadow overflow-hidden sm:rounded-lg">
						<div class="grid grid-cols-2 px-4 py-5 border-b border-gray-200 sm:px-6">
							<div class="text-left">
								<h3 class="text-lg leading-6 font-medium text-gray-900">Broadcast Details</h3>
							</div>
							<div v-if='settingsMap.enableBroadcastShareButtons == 1 && isOwnBroadcast' class='grid grid-cols-3 gap-2 ml-auto mr-0'>
								<div>
									<a :href="'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://news.thebrokersite.com/?id='+broadcast.id+'&bc=1&share=facebook')" target="_blank" rel="noopener" title="Share on Facebook"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
									<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
										<path d="M 85.033 90 C 87.776 90 90 87.776 90 85.033 V 4.967 C 90 2.223 87.776 0 85.033 0 H 4.967 C 2.223 0 0 2.223 0 4.967 v 80.066 C 0 87.776 2.223 90 4.967 90 H 85.033 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(60,90,153); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
										<path d="M 50.916 83.204 V 48.351 h 11.699 l 1.752 -13.583 h -13.45 v -8.672 c 0 -3.933 1.092 -6.612 6.731 -6.612 l 7.193 -0.003 V 7.332 c -1.244 -0.166 -5.513 -0.535 -10.481 -0.535 c -10.37 0 -17.47 6.33 -17.47 17.954 v 10.017 H 25.16 v 13.583 h 11.729 v 34.853 H 50.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
									</g>
									</svg></a>
								</div>

								<div>
									<a :href="'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent('https://news.thebrokersite.com/?id='+broadcast.id+'&bc=1&share=linkedin')" target="_blank" rel="noopener" title="Share on LinkedIn"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
									<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
										<path d="M 0 6.447 C 0 2.887 2.978 0 6.651 0 h 76.698 C 87.022 0 90 2.887 90 6.447 v 77.106 C 90 87.114 87.022 90 83.349 90 H 6.651 C 2.978 90 0 87.114 0 83.553 V 6.447 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,102,153); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
										<path d="M 20.485 29.151 c 4.74 0 7.691 -3.121 7.691 -7.021 c -0.088 -3.988 -2.95 -7.022 -7.601 -7.022 c -4.65 0 -7.69 3.034 -7.69 7.022 c 0 3.9 2.95 7.021 7.512 7.021 H 20.485 L 20.485 29.151 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
										<path d="M 27.282 75.339 v -40.64 H 13.688 v 40.64 H 27.282 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
										<path d="M 34.804 75.339 h 13.594 V 52.644 c 0 -1.215 0.088 -2.428 0.447 -3.296 c 0.983 -2.427 3.219 -4.94 6.975 -4.94 c 4.919 0 6.887 3.727 6.887 9.19 v 21.741 h 13.592 V 52.037 c 0 -12.483 -6.706 -18.291 -15.65 -18.291 c -7.333 0 -10.553 4.073 -12.342 6.847 h 0.091 v -5.894 H 34.804 C 34.982 38.513 34.804 75.339 34.804 75.339 L 34.804 75.339 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
									</g>
									</svg></a>
								</div>

								<div>
									<a :href="'https://twitter.com/intent/tweet?text=' + broadcast.subject + '+' + encodeURIComponent('https://news.thebrokersite.com/?id='+broadcast.id+'&bc=1&share=x')" target="_blank" rel="noopener" title="Share on X (Twitter)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
									<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
										<polygon points="24.89,23.01 57.79,66.99 65.24,66.99 32.34,23.01 " style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
										<path d="M 0 0 v 90 h 90 V 0 H 0 z M 56.032 70.504 L 41.054 50.477 L 22.516 70.504 h -4.765 L 38.925 47.63 L 17.884 19.496 h 16.217 L 47.895 37.94 l 17.072 -18.444 h 4.765 L 50.024 40.788 l 22.225 29.716 H 56.032 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
									</g>
									</svg></a>
								</div>
							</div>
								<!-- <div class="text-right">
								<h3 v-if='!companyIsFavorite'><a href='javascript:void(0)' @click="setCompAsFavorite(true)" class='text-red-600 hover:underline'>Add {{broadcast.company.name}} to your favorites</a></h3>
								<h3 v-else class="text-right"><a href='javascript:void(0)' @click="setCompAsFavorite(false)" class='text-red-600 hover:underline'>Remove {{broadcast.company.name}} from your favorites</a></h3>
							</div> -->
						</div>

						<div class='px-6 my-6'>
							<div v-html="broadcast.messageFormatted" class="break-words"></div>
							<!-- <dl class='mt-2 mb-4'>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 lg:grid-cols-4">
									<dt class="text-sm leading-5 font-medium text-gray-500">Broadcast List</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 lg:col-span-3">
										{{broadcast.broadcastListName}}
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 lg:grid-cols-4">
									<dt class="text-sm leading-5 font-medium text-gray-500">Quantity</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 lg:col-span-3">
										{{broadcast.quantity}}
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 lg:grid-cols-4">
									<dt class="text-sm leading-5 font-medium text-gray-500">Brand</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 lg:col-span-3">
										{{broadcast.brand}}
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 lg:grid-cols-4">
									<dt class="text-sm leading-5 font-medium text-gray-500">Description</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 lg:col-span-3">
										{{broadcast.description}}
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 lg:grid-cols-4">
									<dt class="text-sm leading-5 font-medium text-gray-500">Condition</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 lg:col-span-3">
										{{broadcast.condition}}
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 lg:grid-cols-4">
									<dt class="text-sm leading-5 font-medium text-gray-500">Price</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 lg:col-span-3">
										{{broadcast.price}}
									</dd>
								</div>
							</dl> -->
						</div>
					</div>
				</div>

				<modal v-if='user' v-show="doShowReplyModal" @submit="submitReply" @cancel="closeReplyModal"
					header="Reply to broadcast" 
					customCssClasses="sm:max-w-2xl"
					affirmativeButtonLabel='Send'>
					<template v-slot:body>
						<div>
								<ValidationObserver ref="sendMessageObserver">
								<dl class='mt-4 mb-4 sm:-ml-6 sm:-mr-6'>
									<div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
										<dt class="text-sm leading-5 font-medium text-gray-500">To</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											{{broadcast.company.name}}<br/>
											{{broadcast.contact.name}} ({{broadcast.contact.email}})
										</dd>
									</div>
									<div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
										<dt class="text-sm leading-5 font-medium text-gray-500">From</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<div>{{user.name}} ({{user.email}})</div>
											<div>{{user.companyName}}</div>
										</dd>
									</div>
									<div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
										<dt class="text-sm leading-5 font-medium text-gray-500">Telephone</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											{{user.tel}}
										</dd>
									</div>
									<div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
										<dt class="text-sm leading-9 font-medium text-gray-500">Subject</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<input class="form-input block w-full"  placeholder="Reply Subject" v-model="broadcastReplySubject" >
												<span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</dd>
									</div>
									<div class='mt-4 sm:ml-6 sm:mr-6'>
										<ValidationProvider rules="required" v-slot="{ errors }">
											<textarea rows="12" class="form-input block w-full"  placeholder="Reply Body" v-model="broadcastReplyBody" ></textarea>
											<span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
										</ValidationProvider>
									</div>
								</dl>
							</ValidationObserver>
						</div>
				  </template>
				</modal>

			</div>
		</template>
	</base-layout>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'
import Modal from '@/components/modal.vue'

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import store from '../store'
import eventBus from '../utils/eventBus'

const _ = require('lodash');

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'CompanyDetail',
  components: {
    BaseLayout,
    Modal,
    ValidationProvider,
    ValidationObserver,
  },
  async beforeRouteEnter(to, from, next) {
  	try{
  		const resp = await store.dispatch('getBroadcast', to.params.id)
			if(resp.status !== 200){
				eventBus.$emit("createSnackbar", {
					type: "error", 
					title: "Broadcast removed",
					body: "This broadcast has been removed by the posting company.",
					timeoutInMS: 5000,
				})
				return;
			}
			next(vm => { 
				vm.broadcast = resp.broadcast 
				document.title =  `${vm.broadcast.subject} - The Broker Site`
			})
  	} catch(e){
  		console.log("err", e)
  	}
  },
  created(){
	this.companyIsFavorite = this.user.favCompanies.includes(this.broadcast.companyId)
  },
  mounted() {
	this.isOwnBroadcast = this.broadcast.contactId === this.user.id
  },
  data(){
		return {
			broadcast: null,
			doShowReplyModal: false,
			broadcastReplySubject: null, 
			broadcastReplyBody: null,
			companyIsFavorite: false,
			enableShareButtons: true,
			isOwnBroadcast: false,
		}
	},
	computed: {
		...mapState(["route", 'user', 'lastSearch', 'settingsMap']),
	},

	methods: {
		...mapActions(["sendBroadcastReply", 'setAsFavorite']),
		showReplyModal(){
			this.broadcastReplySubject = `Re: ${this.broadcast.subject}`
			this.broadcastReplyBody =`${this.user.signature}===================================================\nOriginal message from ${this.broadcast.contact.email}:\n\n${this.broadcast.message}`
			this.doShowReplyModal = true;
		},
		async submitReply(){

			const isValid = await this.$refs.sendMessageObserver.validate();
      if (!isValid) return;

      const payload = {
      	broadcastId: this.broadcast.id,
      	subject: this.broadcastReplySubject, 
      	body: this.broadcastReplyBody, 
      }

      const resp = await this.sendBroadcastReply(payload);

  		if(resp.data.status !== 200){
   			eventBus.$emit("createSnackbar", {
   				type: "error", 
   				title: "Something went wrong",
   				body: "Message delivery failed. Please try again. <br/> If the problem persists please contact The Broker Site",
   				timeoutInMS: 5000,
   			})
   		} else {
   			eventBus.$emit("createSnackbar", {
   				type: "success", 
   				title: "Message sent",
   			})
  			this.closeReplyModal();
   		}
		},
		closeReplyModal(){
			this.doShowReplyModal = false;

			// Reset on next frame
  		// https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#validate-before-submit
      requestAnimationFrame(() => {
        this.$refs.sendMessageObserver.reset();
      });
		},
		toFreeTrialPage(){
   		this.$router.push({name: `freeTrial` });
   	},
		checkIfCompanyIsFavorite() {
			this.companyIsFavorite = this.user.favCompanies.includes(this.broadcast.companyId)
			return this.companyIsFavorite
		},

		async setCompAsFavorite(sw) {
			const favoriteDTO = {'companyId': this.broadcast.companyId, 'byContactId': this.user.id, 'action': sw};
			const resp = await this.setAsFavorite(favoriteDTO)

			let bodyMsgFail = ''
			let bodyMsgSuccess = ''
			
			if (sw) {
				bodyMsgFail = "This Company was not added as favorite. Please try again. <br/> If the problem persists please contact The Broker Site"
				bodyMsgSuccess = "This Company was saved in your Favorites."
				this.companyIsFavorite = true;
			} else {
				bodyMsgFail = "This Company was not removed from your favorites. Please try again. <br/> If the problem persists please contact The Broker Site"
				bodyMsgSuccess = "This Company was removed from your Favorites."
				this.companyIsFavorite = false;
			}

     		if(resp.data.status !== 200){
				// if (sw) {
				// 	this.user.favCompanies.push(this.broadcast.companyId)
				// } else {
				// 	const index = this.user.favCompanies.indexOf(this.broadcast.companyId)
				// 	if (index !== -1) this.user.favCompanies.splice(index, 1)
				// }

     			eventBus.$emit("createSnackbar", {
     				type: "error", 
     				title: "Something went wrong",
     				body: bodyMsgFail,
     				timeoutInMS: 5000,
     			})
     		} else {
     			eventBus.$emit("createSnackbar", {
     				type: "success", 
     				title: bodyMsgSuccess,
     				
     			})
     		}
		},

	},
}
</script>
