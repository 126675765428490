<template>
	<base-layout>
		<template v-slot:header>
			Mediakit
		</template>
		<template v-slot:main>
			<div >
				
				<div class='block py-1'><router-link to='/media-kit/about-tbs' class='text-red-600 hover:underline'>About The Broker Site BV</router-link></div>
				<div class='block py-1'><router-link to='/media-kit/about-ebm' class='text-red-600 hover:underline'>About The European Broker Meeting</router-link></div>
				<div class='block py-1'><a href='media/ebm_2009.pdf' target="_blank" class='text-red-600 hover:underline '>The EBM Prague 2009</a></div>
				<div class='block py-1'><a href='media/ebm_2010.pdf' target="_blank" class='text-red-600 hover:underline '>The EBM Lisbon 2010</a></div>
				<div class='block py-1'><a href='media/ebm_2011.pdf' target="_blank" class='text-red-600 hover:underline '>The EBM Berlin 2011</a></div>
				<div class='block py-1'><a href='media/ebm_2012.pdf' target="_blank" class='text-red-600 hover:underline '>The EBM Budapest 2012</a></div>
				<div class='block py-1'><a href='media/ebm_2013.pdf' target="_blank" class='text-red-600 hover:underline '>The EBM Riga 2013</a></div>
				<div class='block py-1'><a href='media/ebm_2014.pdf' target="_blank" class='text-red-600 hover:underline '>The EBM Dublin 2014</a></div>
				<div class='block py-1'><a href='media/ebm_2015.pdf' target="_blank" class='text-red-600 hover:underline '>The EBM Athens 2015</a></div>
				<div class='block py-1'><a href='media/ebm_2016.pdf' target="_blank" class='text-red-600 hover:underline '>The EBM Barcelona 2016</a></div>
				<div class='block py-1'><a href='media/ebm_2017.pdf' target="_blank" class='text-red-600 hover:underline '>The EBM Warsaw 2017</a></div>
				<div class='block py-1'><a href='media/ebm_2018.pdf' target="_blank" class='text-red-600 hover:underline '>The EBM Amsterdam 2018</a></div>
				<div class='block py-1'><a href='media/ebm_2019.pdf' target="_blank" class='text-red-600 hover:underline'>The EBM Monte Carlo 2019</a></div>
				<div class='block py-1'><a href='media/ebm_2021.pdf' target="_blank" class='text-red-600 hover:underline'>The EBM Budapest 2021</a></div>
				<div class='block py-1'><a href='media/ebm_2022.pdf' target="_blank" class='text-red-600 hover:underline'>The EBM Athens 2022</a></div>
				<div class='block py-1'><a href='media/ebm_2023.pdf' target="_blank" class='text-red-600 hover:underline'>The EBM Malta 2023</a></div>
				<div class='block py-1'><router-link to='/media-kit/advertising' class='text-red-600 hover:underline '>Advertising Possibilities</router-link></div>
				<div class='block py-1'><router-link to='/media-kit/videos' class='text-red-600 hover:underline'>TBS Videos</router-link></div>
				<div class='block py-1'><a href='https://www.youtube.com/thebrokersite' class='text-red-600 hover:underline' target="_blank">The Broker Site’s Channel on YouTube</a></div>
				<div class='block py-1'><router-link to='/media-kit/social' class='text-red-600 hover:underline'>Social Media</router-link></div>
			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'Mediakit',
  components: {
    BaseLayout
  }
}
</script>
