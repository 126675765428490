<template>
  <div>
    <modal v-if='selectedContact' v-show="showContactModal" @submit="sendMessage" @cancel="closeContactModal"
           :header="modalHeader"
           customCssClasses="sm:max-w-xl"
           :disableSubmit="!user && !captchaResponse"
           affirmativeButtonLabel='Send'>
      <template v-slot:body>

        <ValidationObserver ref="sendMessageObserver"><!-- v-slot="{ valid }" -->
          <dl class='mt-4 mb-4 sm:-ml-6 sm:-mr-6'>

            <div v-if='user'>
              <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="text-sm leading-5 font-medium text-gray-500">From</dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                  <div>{{ user.name }} ({{ user.email }})</div>
                  <div class="hidden md:block">{{ user.companyName }}</div>
                </dd>
              </div>
            </div>
            <div v-else>

              <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="text-sm leading-9 font-medium text-gray-500">Full Name</dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input v-model='payload.senderName' class="form-input block w-full">
                    <span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
                  </ValidationProvider>
                </dd>
              </div>

              <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="text-sm leading-9 font-medium text-gray-500">Email Address</dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                  <ValidationProvider rules="required|email" v-slot="{ errors }">
                    <input v-model='payload.senderEmail' class="form-input block w-full">
                    <span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
                  </ValidationProvider>
                </dd>
              </div>

              <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                <dt class="text-sm leading-9 font-medium text-gray-500">Company Name</dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <input v-model='payload.companyName' class="form-input block w-full">
                    <span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
                  </ValidationProvider>
                </dd>
              </div>

            </div>
            <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
              <dt class="text-sm leading-5 font-medium text-gray-500">To</dt>
              <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                <div>{{ selectedContact.name }}</div>
              </dd>
            </div>

            <div class="sm:py-0 md:py-2 lg:py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
              <dt class="text-sm leading-9 font-medium text-gray-500">Subject</dt>
              <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <input v-model='payload.subject' class="form-input block w-full">
                  <span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
            </div>

            <div class="sm:py-0 md:py-2 lg:py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
              <dt class="text-sm leading-9 font-medium text-gray-500">Message</dt>
              <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <textarea v-model='payload.message' class="form-input block w-full h-24 md:h-32" ref="messageTextarea"
                            @focus="setCursorToStart"></textarea>
                  <span class='text-red-500 text-sm mt-1 block'>{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
            </div>
          </dl>
        </ValidationObserver>

        <vue-recaptcha v-if='!user' class='float-right'
                       ref="recaptcha"
                       @verify="onVerify"
                       :sitekey="recaptchaKey">
        </vue-recaptcha>

      </template>
    </modal>
  </div>
</template>

<script>


import { mapState, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, max } from 'vee-validate/dist/rules';
import modal from '@/components/modal.vue'
import captchaMixin from '@/components/mixins/captcha'
import eventBus from '../utils/eventBus'

const qsParams = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

extend('email', {
  ...email,
  message: 'This field should be a correct email-address'
});
extend('required', {
  ...required,
  message: 'This field is required'
});

extend('max', {
  ...max,
  message: (fieldName, placeholders) => {
    return `This field must have at most ${placeholders.length} characters`;
  }
});

export default {
  mixins: [captchaMixin],
  name: 'ModalContact',
  props: {
    action: null,
  },
  components: {
    modal,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    selectedContact: null,
    showContactModal: false,
    payload: {
      senderEmail: null,
      senderName: null,
      companyName: null,
      recipientEmail: null,
      subject: null,
      message: null
    },
  }),
  computed: {
    ...mapState(['user', 'route']),
    modalHeader() {
      return `Message to ${this.selectedContact.name}`
    },
  },
  methods: {
    ...mapActions(["sendMailToTBS", "sendMailToContact"]),

    resetContactPayload() {
      this.payload = {
        senderEmail: null,
        senderName: null,
        recipientEmail: null,
        recipientId: null,
        subject: null,
        message: this.user ? this.user.signature : "",
        isFromServicePage: this.route.query.iS === "1" || this.route.name === "services",
      }
    },
    openSendMessageModal(contact) {
      this.resetContactPayload();
      this.selectedContact = contact;
      this.showContactModal = true;
      // console.log(this.payload)
    },
    async sendMessage() {

      const isValid = await this.$refs.sendMessageObserver.validate();
      if (!isValid) return;

      this.payload.senderEmail = this.payload.senderEmail || this.user.email;
      this.payload.senderName = this.payload.senderName || this.user.name;
      this.payload.companyName = this.payload.companyName || this.user.companyName;
      this.payload.recipientEmail = this.selectedContact.email;
      this.payload.recipientId = this.selectedContact.id;
      this.payload.captcha = this.captchaResponse;

      const resp = await this[this.action](this.payload);

      if (resp.data.status !== 200) {
        eventBus.$emit("createSnackbar", {
          type: "error",
          title: "Something went wrong",
          body: "Message delivery failed. Please try again. <br/> If the problem persists please contact The Broker Site",
          timeoutInMS: 5000,
        })
      } else {
        eventBus.$emit("createSnackbar", {
          type: "success",
          title: "Message sent",
        })
        this.closeContactModal();
      }

    },
    closeContactModal() {
      this.showContactModal = false;

      // Reset on next frame
      // https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#validate-before-submit
      requestAnimationFrame(() => {
        this.$refs.sendMessageObserver.reset();
        this.$refs.recaptcha.reset()
        this.captchaResponse = null;
      });

    },
    setCursorToStart() {
      const textarea = this.$refs.messageTextarea
      textarea.setSelectionRange(0, 0)
      textarea.scrollTop = 0
    },
  }
}
</script>
<style>

</style>
