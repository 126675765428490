<template>
	<base-layout>
		<template v-slot:header>
			Grading Guidelines
		</template>
		<template v-slot:main>
			<GradingGuidelinesBody></GradingGuidelinesBody>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import GradingGuidelinesBody from '@/components/GradingGuidelinesBody.vue'

export default {
  name: 'GradingGuidelines',
  components: {
    BaseLayout,
    GradingGuidelinesBody
  }
}
</script>
