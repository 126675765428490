<template>
	<base-layout :withHeaderStructure="true">
		<template v-slot:header>
			<h1 class="text-3xl mt-5 leading-6 text-gray-900 font-bold tracking-tight">
				Broadcast Rules
			</h1>
			<div class='leading-5 mt-3 mb-3 text-gray-700 text-sm'  > 
				Please read these rules carefully.<br/>
				Members that don't keep to these rules will be removed from the broadcast lists and from www.TheBrokerSite.com!
			</div>
		</template>
		<template v-slot:main>
			<BroadcastRulesBody></BroadcastRulesBody>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import BroadcastRulesBody from '@/components/BroadcastRules.vue'

export default {
  name: 'BroadcastRules',
  components: {
    BaseLayout,
    BroadcastRulesBody
  }
}
</script>
