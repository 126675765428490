<template>
	<base-layout :withHeaderStructure="true">
		<template v-slot:header>
			<div>
				<nav >
					<router-link :to="{name: 'parts', query: lastSearch.parts}" class="flex items-center text-sm leading-5 font-medium text-gray-400 hover:text-gray-200 focus:outline-none focus:underline transition duration-150 ease-in-out">
						<svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
						<path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
						</svg>
						Back to parts
					</router-link>
				</nav>

				<div class="mt-5 mb-3 md:flex md:items-center md:justify-between">
					<div class="flex-1 min-w-0">
						<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:text-3xl leading-9 sm:truncate">
						{{part.partNumber}} {{part.description}}
						</h2>
					</div>

					<div class="mt-4 flex md:mt-0 md:ml-4" v-if='!part.isRFQDisabled'>
						<button  @click='$refs.modalRFQ.openModal(part, true)' type="button" class="btn btn-primary">Request For Quote</button>
					</div>

				</div>
			</div>
		</template>
		<template v-slot:main>
			<div>

		<div v-if="!user" class='mb-6 text-sm btn btn-muted text-red-700 border-red-700 hover:text-red-700 block'>
			<a href='javascript:void(0)' @click="toFreeTrialPage" class='hover:underline'>Please register here for your free trial to see full company details!</a>
		</div>

				<div class="grid grid-cols-12 gap-6">
					<div class="col-span-12 lg:col-span-6 bg-white shadow overflow-hidden sm:rounded-lg">
						<div class="px-4 py-5 border-b border-gray-200 sm:px-6">
							<h3 class="text-lg leading-6 font-medium text-gray-900">Item Details</h3>
						</div>
						<div>
							<dl class='mt-2 mb-4'>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Part Number</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{part.partNumber || '-'}}</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Manufacturer</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										{{part.manufacturer}}
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Description</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										{{part.description}}
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Quantity</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										{{part.quantity}}
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Condition</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										{{part.partCondition}}
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Price</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										<span v-if="part.price>0">{{ currencySymbol }} {{ formatNumber(Math.round(part.price * conversion)) }}</span>
										<span v-else>CALL</span>
										
									</dd>
								</div>
							</dl>
						</div>
					</div>
					<div class="col-span-12 lg:col-span-6 bg-white shadow overflow-hidden sm:rounded-lg">
						<div class="px-4 py-5 border-b border-gray-200 sm:px-6">
							<h3 class="text-lg leading-6 font-medium text-gray-900">Company Details</h3>
						</div>
						<div>
							<dl class='mt-2 mb-4'>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Company Name</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										<button class='text-red-600' @click="toCompanyPage">{{part.company.name}}</button>
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Contact person(s)</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										{{part.contactsCombined}}
									</dd>
								</div>
								<div v-if="user" class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Telephone</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										<a class='text-red-700 hover:underline' :href="telLink">{{part.company.tel}}</a>
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Country</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										{{part.company.country}}
									</dd>
								</div>
								<div class=" px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
									<dt class="text-sm leading-5 font-medium text-gray-500">Company Description</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										{{part.company.description}}
									</dd>
								</div>
								<div v-if='part.company.isTrusted' class='px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
									<dt class="text-sm leading-5 font-medium text-gray-500">
										<div class='member_trusted'></div>
									</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										<strong>This company is a 'Trusted Member'.</strong> Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.
									</dd>
								</div>
								<div v-if='part.company.isGold' class='px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
									<dt class="text-sm leading-5 font-medium text-gray-500">
										<div class='member_gold'></div>
									</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										<strong>This company is a 'Gold Member'.</strong> Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.
									</dd>
								</div>
								<div v-if='part.company.isDiamond' class='px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
									<dt class="text-sm leading-5 font-medium text-gray-500">
										<div class='member_diamond'></div>
									</dt>
									<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
										<strong>This company is a 'Diamond Member'.</strong> Diamond members have been on www.TheBrokerSite.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.
									</dd>
								</div>
							</dl>
						</div>
					</div>
				</div>

				<ModalRFQ ref='modalRFQ'></ModalRFQ>

			</div>
		</template>
	</base-layout>
</template>

<script>


import { mapState } from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'
import ModalRFQ from '@/components/ModalRFQ.vue'

import store from '../store'
import eventBus from '../utils/eventBus'

const _ = require('lodash');

export default {
  name: 'PartDetail',
  components: {
    BaseLayout,
    ModalRFQ,
  },

  beforeRouteEnter(to, from, next) {
		store.dispatch('getPart', to.params.id).then(resp => {
  		if(resp.status !== 200){
  			eventBus.$emit("createSnackbar", {
					type: "error", 
					title: "Part not found.",
					body: "If you feel this is incorrect, please contact The Broker Site",
					timeoutInMS: 5000,
				})
  			return
  		}
  		next(vm => {
  			vm.part = resp.part;
				document.title =  `${vm.part.description} - The Broker Site`
			})
  	})
  },
  data(){
		return {
			part: {
				company: {}
			},
			conversion: null,
		}
	},
	created() {
		this.setConversion()
	},
	computed: {
		...mapState(["route", 'lastSearch', "user", "currency", "currencyMap"]),
		telLink(){
			if(this.part && this.part.company) return `tel:${this.part.company.tel}`
			return null;
		},
		currencySymbol(){
			switch(this.currency){
				case "EUR":
					return "€"
				case "USD":
					return "$"
				case "GBP":
					return "£"
			}
			return "€"
		},
	},
	methods: {
		toCompanyPage(){
			this.$router.push({path: `/companies/${this.part.company.id}` });
		},
     	toFreeTrialPage(){
     		this.$router.push({name: `freeTrial` });
     	},		
		setConversion() {
			switch(this.currency) {
			case "EUR":
				this.conversion = 1
				break;
			case "USD":
				this.conversion = this.currencyMap.USD;
				break
			case "GBP":
				this.conversion = this.currencyMap.GBP;
				break
			default:
				this.conversion = 1;
			}
		},
		formatNumber(number) {
			return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(number);
		},
	}
}
</script>

<style scoped>
	.member_trusted{
		width: 80px; 
		height: 80px;
		background: url("../assets/trusted.png");
		background-size: cover;
	}
	.member_gold{
		width: 80px; 
		height: 80px;
		background: url("../assets/gold.png");
		background-size: cover;
	}
</style>
