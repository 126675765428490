<template>
	<div class="relative lg:ml-24 lg:mr-24 mt-4">
		<!-- <svg class="absolute top-0 left-0 transform -translate-x-16 -translate-y-16 scale-50 h-36 w-36 text-indigo-200 opacity-50" stroke="currentColor" fill="none" viewBox="0 0 144 144">
			<path stroke-width="2" d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z" />
			</svg> -->

			<!-- border-b-2 border-gray-200-->
			<div v-for="(item, index) in testimonials" :key="index" class='leading-5 pb-3 mb-3 text-gray-700 text-sm '  >
				<div v-if='item.dateHuman'  class="float-right mr-12 uppercase text-gray-400 text-sm">{{item.dateHuman}}</div>
				<div class='italic'>{{item.body}}</div>
				<div class="font-semibold mt-1">{{item.attr}} </div>
			</div>
	</div>
</template>

<script>

export default {
	name: 'Testimonials',
	props: {
		testimonials: Array
	},
}
</script>