<template>
	<base-layout :withHeaderStructure="true">
		<template v-slot:header>
			<h1 class="text-3xl mt-5 leading-6 text-gray-900 font-bold tracking-tight">
				Testimonials
			</h1>
			<div class='leading-5 mt-3 mb-3 text-gray-700 text-sm'  > 
				Over the years, The Broker Site has built up an impressive reputation, being Europe's leading Computer Trading Exchange.<br/>
				Don't take our word for it, feel free to see what our members say about The Broker Site!
			</div>
		</template>
		<template v-slot:main>
			<testimonials :testimonials='testimonials'/>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import Testimonials from '@/components/Testimonials.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'TestimonialsPage',
  components: {
    BaseLayout, 
    Testimonials
  },
  computed: {
  	...mapState([ 'testimonials']),
  },
 
}
</script>
