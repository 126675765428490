<template>
  <div>

    <modal v-show="showConfirmDeleteModal" @submit="delBroadcastConfirm" @cancel="showConfirmDeleteModal = false"
           header="Confirm delete"
           affirmativeButtonLabel='Yes, Delete'>
      <template v-slot:body>
        <div>Are you sure you want to delete this broadcast?</div>
      </template>
    </modal>

    <modal v-show="showConfirmResendModal" @submit="resendBroadcastConfirm" @cancel="showConfirmResendModal = false"
           header="Confirm Broadcast resend"
           affirmativeButtonLabel='Yes, Resend'>
      <template v-slot:body>
        <div>Are you sure you want to resend this broadcast?</div>
      </template>
    </modal>

    <!-- Shown only on homepage -->
    <div v-if='isHome && user' class="mb-3 md:flex md:items-center md:justify-between">
      <div class="flex-1 min-w-0">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-2xl sm:text-3xl leading-9 sm:truncate">
          Broadcasts
        </h2>
      </div>
      <div class="relative inline-block text-left">
        <div v-if='user && user.isServiceOnly===0' class="mt-4 flex md:mt-0 md:ml-4">
          <button type="button" class="btn btn-primary" @click="showComposeBroadcast">Compose</button>
          <button type="button" class="ml-3 text-sm btn btn-muted text-red-700 border-red-700 hover:text-red-700"
                  @click='showMainDropdown = !showMainDropdown'>More
            <svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"/>
            </svg>
          </button>
        </div>
        <div v-if='showMainDropdown' v-on-clickaway="closeMainDropdown"
             class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-50">
          <div class="rounded-md bg-white shadow-xs">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a href="javascript:void(0)" @click="showBroadcastSettings"
                 class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                 role="menuitem">Broadcast Settings</a>
              <a href="javascript:void(0)" @click="showBroadcastRulesModal = true; showMainDropdown = false"
                 class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                 role="menuitem">Broadcast Rules</a>
              <a href="javascript:void(0)" @click="showGradingGuidelinesModal = true; showMainDropdown = false"
                 class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                 role="menuitem">Grading Guidelines</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Search form -->
    <form @submit.self.prevent="doSearch" v-if='user && !isPossibleMatches' class='mt-8 sm:mt-0 relative'>
      <div class="grid grid-cols-12 gap-3 sm:gap-4">
        <div class="col-span-12 sm:col-span-12 lg:col-span-3">
          <label for="bcSearch" class="form-label block">Search for broadcasts containing</label>
          <input v-model="tempQuery" id="bcSearch" class="form-input mt-1 block w-full"/>
          <!-- Exact Match Checkbox Single Search -->
          <div class="mt-1 sm:mt-2">
            <input id="exactPhrase" type="checkbox"
                   class="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out"
                   v-model="payload.exactPhrase">
            <label for="exactPhrase" class="ml-2 text-sm leading-5">
              Show only exact matches
            </label>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
          <label for="bcCat" class="form-label block">Show</label>
          <select v-model="payload.listType" id="bcCat" class="form-select mt-1 block w-full">
            <option v-for="(option, index) in broadcastOptionsInclAll" :key="index" :value="option.id">
              {{ option.label }}
            </option>
          </select>
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
          <label for="bcType" class="form-label block">Broadcast Type</label>
          <select v-model="payload.type" id="bcType" class="form-select mt-1 block w-full">
            <option v-for="(option, index) in listOptions" :key="index" :value="option.id">{{ option.label }}</option>
          </select>
        </div>

        <div class="col-span-12 sm:col-span-12 lg:col-span-3">
          <div class="float-right w-full lg:float-left lg:mt-6 text-right">

            <button @click="doSearch" type="button" class="btn btn-primary">Search</button>
            <a href='javascript:void(0)' v-if='showSS' @click='showBroadcastSaveDropdown = true'
               class="ml-3 text-sm btn btn-muted text-red-700 border-red-700 hover:text-red-700">Save
              <svg class="-mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"/>
              </svg>
            </a>
          </div>
        </div>

        <div v-if='showBroadcastSaveDropdown && showSS' v-on-clickaway="closeBroadcastSaveDropdown"
             class="origin-top-right absolute right-0 mt-20 w-72 rounded-md shadow-lg">
          <div class="rounded-md bg-white shadow-xs">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <a href="javascript:void(0)" @click='createSavedSearch()'
                 class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                 role="menuitem">Save with immediate email updates</a>
              <a href="javascript:void(0)" @click='createSavedSearch(true)'
                 class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                 role="menuitem">Save with daily email updates</a>
            </div>
          </div>
        </div>

      </div>

    </form>

    <!-- <a v-if='!isHome && user' @click="navToRSS" href='javascript:void(0)' class='h-8 text-red-600 hover:underline float-right text-right text-sm mt-2'>Subscribe to these results using RSS</a> -->
    <span class='h-6 block' v-if='!isPossibleMatches'></span>

    <button
        v-if="user && payload.companyId && !isSelectedCompanyMyCompany && this.activeTab === '0' && !isPossibleMatches"
        @click="payload.companyId = null; doSearch()"
        class='mt-8 -mb-4 text-sm btn btn-muted text-red-700 border-red-700 hover:line-through hover:text-red-700'>
      Broadcasts shown for {{ companyNameMap[payload.companyId] }}
    </button>

    <div v-if='showSS' class='clearfix mt-6 -mb-6 border-b border-gray-200'>
      <div class="sm:hidden mb-4">
        <label for="tabs" class="sr-only">Select a tab</label>
        <select v-model='activeTab' class="form-select mt-1 block w-full" @change="broadcastTab(activeTab)">
          <option value="0">All Broadcasts</option>
          <option v-if="user.isServiceOnly===0" value="3">From Favorites</option>
          <option v-if="user.isServiceOnly===0" value="1">My Broadcasts</option>
          <option value="2">Saved Searches</option>
        </select>
      </div>
      <div class="hidden sm:inline-block">
        <div class="">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <a href="javascript:void(0)" @click="broadcastTab('0')" class='mr-3'
               :class="{'tab-inactive': activeTab !== '0', 'tab-active': activeTab === '0'}">
              All Broadcasts
            </a>
            <a v-if="user.isServiceOnly===0" href="javascript:void(0)" @click="broadcastTab('3')" class='mr-4'
               :class="{'tab-inactive': activeTab !== '3', 'tab-active': activeTab === '3'}" aria-current="page">
              From Favorites
            </a>
            <a v-if="user.isServiceOnly===0" href="javascript:void(0)" @click="broadcastTab('1')" class='mr-4'
               :class="{'tab-inactive': activeTab !== '1', 'tab-active': activeTab === '1'}" aria-current="page">
              My Broadcasts
            </a>
            <a href="javascript:void(0)" @click="broadcastTab('2')" class='mr-4'
               :class="{'tab-inactive': activeTab !== '2', 'tab-active': activeTab === '2'}">
              Saved Searches
            </a>
          </nav>
        </div>
      </div>

      <div v-if='!isHome && nrBroadcasts' class='float-right hidden md:inline-block'>
        <select v-model='pageSize' class="mt-2 mb-2 form-select pr-8">
          <option v-for='availPageSize in availPageSizes' :key='availPageSize.value' :value='availPageSize.value'>
            {{ availPageSize.label }}
          </option>
        </select>
      </div>
    </div>

    <!-- Table -->
    <div class="relative mt-4 sm:mt-12 flex flex-col" v-if="activeTab ==='0' || activeTab ==='1' || activeTab ==='3'">

      <div v-if='actionItem && dropdownType === 2' v-on-clickaway="unsetActionItem"
           class="origin-top-right absolute right-0 mt-20 w-72 rounded-md shadow-lg z-10" ref='ownBroadcastDropdown'>
        <div class="rounded-md bg-white shadow-xs">
          <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <a v-if='actionItem.isOkToResend' href="javascript:void(0)" @click='showConfirmResendModal = true'
               class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
               role="menuitem">Resend broadcast</a>
            <a href="javascript:void(0)" @click.stop="showPossibleMatchesFromOwnBroadcast(actionItem.id)"
               class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
               role="menuitem">Show possible matches</a>
            <div class="grid grid-cols-2">
              <div class="block px-4 py-2 text-sm leading-5 text-gray-700" role="menuitem">Share broadcast</div>
              <div class='grid grid-cols-3 gap-2 ml-auto mr-2 mt-1 mb-2'>
                <div>
                  <a :href="'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://news.thebrokersite.com/?id='+actionItem.id+'&bc=1&share=facebook')"
                     target="_blank" rel="noopener" title="Share on Facebook">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                         width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
									<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                     transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
										<path
                        d="M 85.033 90 C 87.776 90 90 87.776 90 85.033 V 4.967 C 90 2.223 87.776 0 85.033 0 H 4.967 C 2.223 0 0 2.223 0 4.967 v 80.066 C 0 87.776 2.223 90 4.967 90 H 85.033 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(60,90,153); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    <path
                        d="M 50.916 83.204 V 48.351 h 11.699 l 1.752 -13.583 h -13.45 v -8.672 c 0 -3.933 1.092 -6.612 6.731 -6.612 l 7.193 -0.003 V 7.332 c -1.244 -0.166 -5.513 -0.535 -10.481 -0.535 c -10.37 0 -17.47 6.33 -17.47 17.954 v 10.017 H 25.16 v 13.583 h 11.729 v 34.853 H 50.916 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
									</g>
									</svg>
                  </a>
                </div>

                <div>
                  <a :href="'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent('https://news.thebrokersite.com/?id='+actionItem.id+'&bc=1&share=linkedin')"
                     target="_blank" rel="noopener" title="Share on LinkedIn">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                         width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
									<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                     transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
										<path
                        d="M 0 6.447 C 0 2.887 2.978 0 6.651 0 h 76.698 C 87.022 0 90 2.887 90 6.447 v 77.106 C 90 87.114 87.022 90 83.349 90 H 6.651 C 2.978 90 0 87.114 0 83.553 V 6.447 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,102,153); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    <path
                        d="M 20.485 29.151 c 4.74 0 7.691 -3.121 7.691 -7.021 c -0.088 -3.988 -2.95 -7.022 -7.601 -7.022 c -4.65 0 -7.69 3.034 -7.69 7.022 c 0 3.9 2.95 7.021 7.512 7.021 H 20.485 L 20.485 29.151 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    <path d="M 27.282 75.339 v -40.64 H 13.688 v 40.64 H 27.282 z"
                          style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
                          transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
                    <path
                        d="M 34.804 75.339 h 13.594 V 52.644 c 0 -1.215 0.088 -2.428 0.447 -3.296 c 0.983 -2.427 3.219 -4.94 6.975 -4.94 c 4.919 0 6.887 3.727 6.887 9.19 v 21.741 h 13.592 V 52.037 c 0 -12.483 -6.706 -18.291 -15.65 -18.291 c -7.333 0 -10.553 4.073 -12.342 6.847 h 0.091 v -5.894 H 34.804 C 34.982 38.513 34.804 75.339 34.804 75.339 L 34.804 75.339 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
									</g>
									</svg>
                  </a>
                </div>

                <div>
                  <a :href="'https://twitter.com/intent/tweet?text=' + actionItem.subject + '+' + encodeURIComponent('https://news.thebrokersite.com/?id='+actionItem.id+'&bc=1&share=x')"
                     target="_blank" rel="noopener" title="Share on X (Twitter)">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                         width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
									<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                     transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
										<polygon points="24.89,23.01 57.79,66.99 65.24,66.99 32.34,23.01 "
                             style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                             transform="  matrix(1 0 0 1 0 0) "/>
                    <path
                        d="M 0 0 v 90 h 90 V 0 H 0 z M 56.032 70.504 L 41.054 50.477 L 22.516 70.504 h -4.765 L 38.925 47.63 L 17.884 19.496 h 16.217 L 47.895 37.94 l 17.072 -18.444 h 4.765 L 50.024 40.788 l 22.225 29.716 H 56.032 z"
                        style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                        transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round"/>
									</g>
									</svg>
                  </a>
                </div>
              </div>
            </div>
            <a href="javascript:void(0)" @click.stop="delBroadcast"
               class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 border-t"
               role="menuitem">Remove broadcast</a>
          </div>
        </div>
      </div>

      <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">

        <h2 v-if='!user'
            class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-2xl sm:text-3xl leading-9 -mt-6 mb-4">Latest
          Broadcasts</h2>

        <div v-if='nrBroadcasts'>
          <!-- Big screen -->
          <div
              class="align-middle hidden md:inline-block min-w-full shadow overflow-hidden sm:rounded-md border-b border-gray-200">
            <table class="w-full table-fixed">
              <thead>
              <tr>
                <th class="table-cell-header w-64">Broadcast</th>
                <th class="table-cell-header w-4 pr-0 pl-0"><!-- emblem gold/trusted --></th>
                <th class="table-cell-header w-24 pl-2">From</th>
                <th class="table-cell-header w-16">Date</th>
              </tr>
              </thead>
              <tbody>
              <tr @click="navToBroadcast(item, $event)" v-for="(item, index) in broadcastsEnrichedWrapper" :key="index"
                  class="table-row hover-higlight-anchor" :class='{"bg-gray-50": index % 2}'>

                <!-- hover-higlight -->
                <td class="table-cell text-gray-900"><span :title='item.subject' class='truncate align-middle'>
										{{ item.subject }}
									</span>
                </td>
                <td class="table-cell pr-0 pl-0">
                  <div v-if="item.isDiamond" class='member_diamond'
                       title="This company is a 'Diamond Member'. Diamond members have been on www.TheBrokerSite.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                  <div v-if="item.isGold" class='member_gold'
                       title="This company is a 'Gold Member'. Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                  <div v-if="item.isTrusted" class='member_trusted'
                       title="This company is a 'Trusted Member'. Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                </td>
                <td class="table-cell text-gray-500 pl-2" :class="{'company-cell':user}" :title='item.companyName'
                    @click.stop="navToCompany(item, false)">
                  <div class='truncate'>{{ item.companyName }}</div>
                </td>
                <!-- <td v-else class="table-cell text-gray-500 pl-2" :class="{'company-cell':user}" :title='item.companyName' @click.stop="navToCompany(item, true)">
                  <div class='truncate'>{{item.companyName}}</div>
                </td> -->
                <td class="table-cell text-gray-500 relative" :title='item.dateAsStringLong'>
                  {{ item.dateAsString }}

                  <button v-if='item.isOwn' class="absolute top-2 right-1" @click.stop='setActionItem(item, 2)'>
                    <svg width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                         class="transform transition-transform duration-500 ease-in-out">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                    </svg>
                  </button>

                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <!-- Small screen -->
          <div
              class="align-middle inline-block md:hidden min-w-full shadow overflow-hidden sm:rounded-md border-b border-gray-200">
            <table class="w-full table-fixed">
              <thead>
              <tr>
                <th class="table-cell-header w-64">Broadcasts</th>
              </tr>
              </thead>
              <tbody>
              <tr @click="navToBroadcast(item, $event)" v-for="(item, index) in broadcastsEnrichedWrapper" :key="index"
                  class="table-row hover-higlight-anchor" :class='{"bg-gray-50": index % 2}'>
                <td class="table-cell text-gray-900">
                  <div :title='item.subject' class='truncate align-middle text-red-700'>{{ item.subject }}</div>
                  <div class='relative'>

                    <div class='truncate' :class="{'company-cell':user}" :title='item.companyName'>
                      <div v-if="item.isDiamond" class='member_diamond float-left mr-2'
                           title="This company is a 'Diamond Member'. Diamond members have been on www.TheBrokerSite.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                      <div v-if="item.isGold" class='member_gold float-left mr-2'
                           title="This company is a 'Gold Member'. Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                      <div v-if="item.isTrusted" class='member_trusted float-left mr-2'
                           title="This company is a 'Trusted Member'. Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                      <span>
													<span class='inline-block float-left'>{{ item.companyName }}</span>
													<span class='inline-block float-left mt-1 ml-1' @click.stop="navToCompany(item, true)">
														<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
															<path data-v-6cabaa3f="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
														</svg>
													</span>
												</span>

                      <button v-if='item.isOwn' class="absolute top-2 right-1"
                              @click.stop='setActionItem(item, 2, true)'>
                        <svg width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                             class="transform transition-transform duration-500 ease-in-out">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
                        </svg>
                      </button>

                    </div>
                    <div class='text-gray-500'>{{ item.dateAsString }}</div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination v-if='!isPossibleMatches' :itemList="broadcastsEnriched" :payload="payload"
                  :pageSize="pageSize"></Pagination>
    </div>

    <div class="relative mt-4 sm:mt-12 flex flex-col" v-if="activeTab ==='2'"> <!-- Saved Searches -->

      <div v-if='actionItem && dropdownType === 1' v-on-clickaway="unsetActionItem"
           class="origin-top-right absolute right-6 mt-20 w-72 rounded-md shadow-lg" ref='ssDropdown'>
        <div class="rounded-md bg-white shadow-xs">
          <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <a href="javascript:void(0)" @click='switchSavedSearchNotificationTypeAndClose'
               class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
               role="menuitem">Switch delivery method</a>
            <a href="javascript:void(0)" @click='removeSavedSearchAndClose'
               class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
               role="menuitem">Remove</a>
          </div>
        </div>
      </div>

      <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <!-- big screen -->
        <div
            class="align-middle hidden md:inline-block min-w-full shadow overflow-hidden sm:rounded-md border-b border-gray-200">
          <table class="w-full table-fixed">
            <thead>
            <tr>
              <th class="table-cell-header w-48">Saved Search</th>
              <th class="table-cell-header w-16">Created on</th>
              <th class="table-cell-header w-16">Last Hit</th>
              <th class="table-cell-header w-16">Get Updates</th>
              <th class="table-cell-header w-24 text-right">Actions</th>
            </tr>
            </thead>
            <tbody v-if='savedSearches.length'>
            <tr v-for="(item, index) in savedSearches" :key="index" class="table-row hover-higlight-anchor"
                :class='{"bg-gray-50": index % 2}' @click="navToSavedSearchResults(item)">
              <td class="table-cell text-gray-900 hover-higlight"><span :title='item.search'
                                                                        class='truncate align-middle'>{{
                  item.search
                }}</span>
              </td>
              <td class="table-cell text-gray-600"><span class='truncate align-middle'>{{ item.created }}</span></td>
              <td class="table-cell text-gray-900 font-bold"><span
                  class='truncate align-middle'>{{ item.lastHit }}</span></td>
              <td class="table-cell">
                <span v-if='item.doUpdateImmediately'
                      class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
                      title='Receive a mail the moment a broadcast occurs that satisfies your search'>Immediately</span>
                <span v-if='!item.doUpdateImmediately'
                      class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                      title='Receive daily mails summarizing the broadcasts that satisfy your search'>Daily</span>
              </td>
              <td class="table-cell text-right">
                <button type="button" class="ml-3 pr-3 text-sm btn btn-muted" @click.stop='setActionItem(item, 1)'>
                  Actions
                  <svg fill="currentColor" viewBox="0 0 20 20" class="-mr-1 ml-2 h-5 w-5">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr class="table-row">
              <td class="table-cell text-gray-900" colspan='5'>
                <div>No Saved searches yet. Please go ahead and create your first Saved Search by clicking 'Save'
                  above
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- small screen -->
        <div
            class="align-middle inline-block md:hidden min-w-full shadow overflow-hidden sm:rounded-md border-b border-gray-200">
          <table class="w-full table-fixed">
            <thead>
            <tr>
              <th class="table-cell-header">Saved Search</th>
              <th class="table-cell-header text-right">Actions</th>
            </tr>
            </thead>
            <tbody v-if='savedSearches.length'>

            <tr v-for="(item, index) in savedSearches" :key="index" class="table-row hover-higlight-anchor"
                :class='{"bg-gray-50": index % 2}' @click="navToSavedSearchResults(item)">
              <td class="table-cell text-gray-900 hover-higlight">
                <span :title='item.search' class='truncate align-middle'>{{ item.search }}</span>
                <!-- <span class='truncate align-middle'>{{item.created}}</span> -->
                <!-- <span class='truncate align-middle'>Last hit: {{item.lastHit}}</span> -->
                <div class='mt-1'>
                  <span v-if='item.doUpdateImmediately'
                        class="inline-flex items-center -ml-2.5 px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
                        title='Receive a mail the moment a broadcast occurs that satisfies your search'>Delivery: Immediately</span>
                  <span v-if='!item.doUpdateImmediately'
                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                        title='Receive daily mails summarizing the broadcasts that satisfy your search'>Delivery: Daily</span>
                </div>
              </td>
              <td class="table-cell text-right">
                <button type="button" class="pr-3 pl-0 text-sm btn btn-muted"
                        @click.stop='setActionItem(item, 1, true)'>
                  <svg fill="currentColor" viewBox="0 0 20 20" class="-mr-1 ml-2 h-5 w-5">
                    <path fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"></path>
                  </svg>
                </button>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr class="table-row">
              <td class="table-cell-noellipsis text-gray-900" colspan='2'>
                <div>No Saved searches yet. Please go ahead and create your first Saved Search by clicking 'Save'
                  above
                </div>
              </td>
            </tr>
            </tbody>


          </table>
        </div>
      </div>
    </div>

    <div v-if='isHome && user' class='mt-4 clearfix'>
      <button @click="$router.push({ name: 'broadcasts'})"
              class='float-right text-sm btn btn-muted text-red-700 border-red-700 hover:text-red-700'>Show all
        broadcasts
      </button>
    </div>

    <modal v-if='user' v-show="showCompanyModal" @submit="closeCompanyModal" @cancel="closeCompanyModal"
           :doSkipHeader="true"
           :doSkipCancel="true"
           customCssClasses="sm:max-w-6xl"
           affirmativeButtonLabel='Close'>
      <template v-slot:body>
        <CompanyBody :isInModal="true" :selectedCompanyEnriched='selectedCompany'
                     :contactsEnriched="selectedCompanyContacts"></CompanyBody>
      </template>
    </modal>

    <modal v-if='user' v-show="showBroadcastComposeModal" @submit="submitBroadcast" @cancel="closeComposeBroadcastModel"
           header="Compose Broadcast"
           headerLink="/help?id=1"
           headerLinkLabel="Need help?"
           customCssClasses="sm:max-w-2xl"
           :disableSubmit="disableBroadcastSendButton || broadcastLimitReached"
           affirmativeButtonLabel='Send'>
      <template v-slot:body>
        <div>
          <form autocomplete="on">
            <ValidationObserver ref="sendBroadcast">
              <div v-if="broadcastLimitReached">
                <Callout :title="broadcastLimitData.web.title" :message="broadcastLimitData.web.message" />
              </div>
              <dl class='mt-4 mb-4 sm:-ml-6 sm:-mr-6'>
                <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 font-medium text-gray-500">Company Name</dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">{{ user.companyName }}</dd>
                </div>
                <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 sm:leading-9 font-medium text-gray-500">To</dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                    <ValidationProvider rules="required|max:2" v-slot="{ errors }">
                      <select v-model='composeBroadcastPayload.listType' class="form-select block w-full">
                        <option value="" selected>Please select broadcast list</option>
                        <option v-for="(option, index) in broadcastOptions" :key="index" :value="option.id">
                          {{ option.label }}
                        </option>
                      </select>
                      <span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </dd>
                </div>

                <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 sm:leading-9 font-medium text-gray-500">From</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                    <select v-model='composeBroadcastPayload.fromEmail' v-if='user.emailAlt'
                            class="form-select block w-full">
                      <option :value="user.email">Primary ({{ user.email }})</option>
                      <option :value="user.emailAlt">Alternative ({{ user.emailAlt }})</option>
                    </select>
                    <div v-else class='leading-9 font-bold'>
                      {{ user.name }} ({{ user.email }})
                    </div>
                  </dd>
                </div>
                <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 sm:leading-9 font-medium text-gray-500">Broadcast type</dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                    <select v-model='composeBroadcastPayload.broadcastType' class="form-select block w-full">
                      <option value='wts'>WTS (Want to Sell)</option>
                      <option value='wtb'>WTB (Want to Buy)</option>
                    </select>
                  </dd>
                </div>
                <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 sm:leading-9 font-medium text-gray-500">Quantity</dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                    <ValidationProvider rules="required|max:10" v-slot="{ errors }">
                      <input v-model='composeBroadcastPayload.quantity' class="form-input block w-full">
                      <span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </dd>
                </div>
                <div class=" py-1 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 sm:leading-9 font-medium text-gray-500">Brand</dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                    <ValidationProvider rules="required|max:50" v-slot="{ errors }">
                      <input v-model='composeBroadcastPayload.brand' class="form-input block w-full">
                      <span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </dd>
                </div>
                <div class=" py-1 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 sm:leading-9 font-medium text-gray-500">Desc. / Partnumber</dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                    <ValidationProvider rules="required|max:50" v-slot="{ errors }">
                      <input v-model='composeBroadcastPayload.desc' class="form-input block w-full">
                      <span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
                    </ValidationProvider>
                  </dd>
                </div>
                <div class=" py-1 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 sm:leading-9 font-medium text-gray-500">Condition</dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                    <div class="grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                      <div class="sm:col-span-3">
                        <ValidationProvider rules="required|max:20" v-slot="{ errors }">
                          <input v-model='composeBroadcastPayload.condition' class="form-input block w-full">
                          <span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="sm:col-span-3 leading-9">
                        <a class="hover:underline text-red-600 font-bold" href="javascript:void(0)"
                           @click="navToGradingGuidelines">Grading Guidelines</a>
                      </div>
                    </div>
                  </dd>
                </div>
                <div class=" py-1 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 sm:leading-9 font-medium text-gray-500">Product Link</dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                    <input v-model='composeBroadcastPayload.pLink' class="form-input block w-full">
                  </dd>
                </div>
                <div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt class="text-sm leading-5 sm:leading-9 font-medium text-gray-500">Price</dt>
                  <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
                    <div class="grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6">
                      <div class="sm:col-span-3">
                        <ValidationProvider rules="required|max:10" v-slot="{ errors }">
                          <input v-model='composeBroadcastPayload.price' class="form-input block w-full">
                          <span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="sm:col-span-3">
                        <select v-model='composeBroadcastPayload.currency' class="form-select block w-full">
                          <option value="EUR">Euro</option>
                          <option value="USD">US Dollar</option>
                          <option value="GBP">UK Pound</option>
                        </select>
                      </div>
                    </div>
                  </dd>
                </div>

                <div class='mt-4 sm:ml-6 sm:mr-6'>
                  <textarea v-model='composeBroadcastPayload.additionalInfo' @focus='textAreaFocus' rows="12"
                            class="form-input block w-full" placeholder="Enter any additional messages here"></textarea>
                </div>
              </dl>
            </ValidationObserver>
          </form>
        </div>
      </template>
    </modal>

    <BroadcastSettings v-if='user' ref='BroadcastSettings'/>

    <modal v-show="showBroadcastRulesModal" @submit="showBroadcastRulesModal = false"
           :doSkipHeader="true"
           :doSkipCancel="true"
           customCssClasses="sm:max-w-6xl"
           affirmativeButtonLabel='Close'>
      <template v-slot:body>
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
          <a class="hover:underline" href="javascript:void(0)" @click="navToBroadcastRules">
            Broadcast Rules
            <svg class="inline-block ml-2 -mt-1" width="18" height="18" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
            </svg>
          </a>
        </h3>
        <div class='mb-4'>
          Please read these rules carefully.<br/>
          Members that don't keep to these rules will be removed from the broadcast lists and from
          www.TheBrokerSite.com!
        </div>
        <BroadcastRulesBody></BroadcastRulesBody>
      </template>
    </modal>

    <modal v-show="showGradingGuidelinesModal" @submit="showGradingGuidelinesModal = false"
           :doSkipHeader="true"
           :doSkipCancel="true"
           customCssClasses="sm:max-w-6xl"
           affirmativeButtonLabel='Close'>
      <template v-slot:body>
        <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
          <a class="hover:underline" href="javascript:void(0)" @click="navToGradingGuidelines">
            Grading Guidelines
            <svg class="inline-block ml-2 -mt-1" width="18" height="18" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
            </svg>
          </a>
        </h3>
        <GradingGuidelinesBody></GradingGuidelinesBody>
      </template>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import { mixin as clickaway } from 'vue-clickaway';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

import ListMixin from '@/components/mixins/List'
import GradingGuidelinesBody from '@/components/GradingGuidelinesBody.vue'
import BroadcastRulesBody from '@/components/BroadcastRules.vue'
import BroadcastSettings from '@/components/BroadcastSettings.vue'

import eventBus from '../utils/eventBus'

import { mapActions, mapGetters, mapState } from 'vuex';
import Callout from "@/components/Callout.vue";

export default {
  mixins: [ListMixin, clickaway],
  name: 'BroadcastList',
  components: {
    Callout,
    GradingGuidelinesBody,
    BroadcastRulesBody,
    BroadcastSettings,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isHome: Boolean,
    isPossibleMatches: Boolean,
  },
  data: () => ({
    dropdownType: null,
    payload: {
      query: null,
      listType: null,
      type: null,
      page: 1,
      companyId: null,
      favorites: [],
      exactPhrase: false,
    },

    fetchActionName: "getBroadcasts",
    numResultsName: "nrBroadcasts", //used in list-mixin

    showMainDropdown: false,
    showBroadcastComposeModal: false,
    showBroadcastRulesModal: false,
    showConfirmDeleteModal: false,
    showConfirmResendModal: false,
    showGradingGuidelinesModal: false,
    showBroadcastSaveDropdown: false,
    disableBroadcastSendButton: false,

    composeBroadcastPayload: {
      listType: "99",
      fromEmail: null,
      broadcastType: "wts",
      quantity: null,
      brand: null,
      desc: null,
      condition: null,
      pLink: null,
      price: null,
      currency: "EUR",
      additionalInfo: null,
    },

    actionItem: null,
    activeTab: '0', //0 = all / 1 = my / 2 = searches / 3 = favorites

    broadcastLimitReached: false,
    broadcastLimitData: {
      web: {
        title: null,
        message: null
      }
    },
  }),
  computed: {
    ...mapState(['broadcastMap', 'nrBroadcasts']),
    ...mapGetters(['broadcastsEnriched', 'broadcastEnrichedFN', 'companyNameMap', 'savedSearches']),
    broadcastsEnrichedWrapper() {
      return this.broadcastsExplicit ? this.broadcastEnrichedFN(this.broadcastsExplicit) : this.broadcastsEnriched
    },
    isSelectedCompanyMyCompany() {
      return this.user && this.payload.companyId === this.user.companyId
    },
    showSS() {
      return this.user && !this.isPossibleMatches//all logged-in users
      // return this.user && this.user.companyId === "1408"
    },
    broadcastOptions() {
      return _.orderBy(_.map(this.broadcastMap, (cat, id) => _.extend({
        id,
        label: `${cat.name} Broadcasts`
      }, cat)), "order")
    },
    broadcastOptionsInclAll() {
      return [{
        id: null,
        name: "All Broadcasts",
        label: "All Broadcasts"
      }, ...this.broadcastOptions]
    },
    listOptions() {
      return [{
        id: null,
        label: "All"
      }, {
        id: "WTB",
        label: "WTB"
      }, {
        id: "WTS",
        label: "WTS"
      }]
    }
  },
  async created() {
    if (this.showSS) {
      await this.getSavedSearches()
    }

    await this.fetchBroadcastLimit()
  },
  watch: {
    'composeBroadcastPayload.listType': function(newVal, oldVal) {
      // When selected list changes, check broadcast limit for this list
      this.fetchBroadcastLimit();
    }
  },
  methods: {
    ...mapActions([
      "getBroadcasts", 'createBroadcast', 'deleteBroadcast', 'getSavedSearches',
      'switchSavedSearchNotificationType', 'removeSavedSearch', 'persistSavedSearch', 'resendBroadcast', 'getBroadcastLimit'
    ]),

    showPossibleMatchesFromOwnBroadcast(id) {
      this.unsetActionItem()
      eventBus.$emit("showPossibleMatchesFromOwnBroadcast", id)
    },
    //////////////////
    // Saved seach
    broadcastTab(type) {
      switch (type) {
        case "0": { // all
          this.activeTab = '0'
          this.tempQuery = null
          this.payload = {
            query: null,
            listType: null,
            type: null,
            page: 1,
            companyId: null,
          }
          this.doSearch(true);
          break;
        }
        case "1": { // mine
          this.activeTab = '1'
          this.tempQuery = null
          this.payload = {
            query: null,
            listType: null,
            type: null,
            page: 1,
            companyId: this.user.companyId,
            onlyOwnBroadcasts: true,
          }
          this.doSearch(true);
          break;
        }
        case "2": { // saved searches
          this.activeTab = '2'
          this.tempQuery = null
          this.payload = {
            query: null,
            listType: null,
            type: null,
            page: 1,
            companyId: null,
          }
          break;
        }
        case "3": { // broadcasts by favorites
          this.activeTab = '3'
          this.tempQuery = null
          this.payload = {
            query: null,
            listType: null,
            type: null,
            page: 1,
            companyId: null,
            favorites: this.user.favCompanies,
          }
          this.doSearch(true);
          break;
        }
        default:
          throw new Error(`tab not supported: ${type}`)
      }
    },
    setActionItem(item, dropdownType, isMobile) {
      this.actionItem = item
      this.dropdownType = dropdownType;
      this.$nextTick(() => { //hackish: need to render first before we have access to $refs

        let $dropDownEl;
        let increment;
        let fixedOffsetH;
        switch (dropdownType) {
          case 1: { //saved search
            $dropDownEl = this.$refs.ssDropdown;
            increment = isMobile ? 76 : 57;
            fixedOffsetH = 20;
            break;
          }
          case 2: { //my broadcast
            $dropDownEl = this.$refs.ownBroadcastDropdown;
            increment = isMobile ? 90 : 43
            fixedOffsetH = isMobile ? 28 : -5;
            break;
          }
          case 3: { //from favorites
            $dropDownEl = this.$refs.ownBroadcastDropdown;
            increment = isMobile ? 90 : 43
            fixedOffsetH = isMobile ? 28 : -5;
            break;
          }
          default:
            throw `dropdownType not supported: ${dropdownType}`
        }

        $dropDownEl.style.transform = `translateY(${fixedOffsetH + (item.index * increment)}px)`; //set position
      })
    },
    unsetActionItem() {
      this.actionItem = null
      this.dropdownType = null
    },
    async createSavedSearch(isDigest = false) {

      const payload = {
        q: this.tempQuery ? this.tempQuery : undefined,
        // q: this.payload.query ? this.payload.query : undefined, //payload.query only set on 'save'
        listType: this.payload.listType ? +this.payload.listType : undefined,
        broadcastType: this.payload.type ? this.payload.type : undefined,
        companyId: this.payload.companyId ? +this.payload.companyId : undefined,
        isDigest
      }

      try {
        await this.persistSavedSearch(payload)
        this.activeTab = '2' // to saved search tab
        this.showBroadcastSaveDropdown = false

      } catch (err) {
        eventBus.$emit("createSnackbar", {
          type: "error",
          title: "Saved Search could not be created",
          body: "Please try again. If this occur keeps occuring, please contact The Broker Site",
          timeoutInMS: 5000,
        })
      }
    },
    async switchSavedSearchNotificationTypeAndClose() {
      try {
        await this.switchSavedSearchNotificationType(this.actionItem)
        this.actionItem = null
      } catch (err) {
        eventBus.$emit("createSnackbar", {
          type: "error",
          title: "Saved Search could not be updated",
          body: "Please try again. If this occur keeps occuring, please contact The Broker Site",
          timeoutInMS: 5000,
        })
      }
    },
    async removeSavedSearchAndClose() {
      try {
        await this.removeSavedSearch(this.actionItem)
        this.actionItem = null
      } catch (err) {
        eventBus.$emit("createSnackbar", {
          type: "error",
          title: "Saved Search could not be removed",
          body: "Please try again. If this occur keeps occuring, please contact The Broker Site",
          timeoutInMS: 5000,
        })
      }
    },
    navToSavedSearchResults(ss) {
      let q = `/broadcasts`
      const qArr = _.compact([
        ss.q ? `query=${ss.q}` : null,
        ss.listType ? `listType=${ss.listType}` : null,
        ss.broadcastType ? `type=${ss.broadcastType}` : null,
      ])

      if (qArr.length) {
        q += `?${qArr.join("&")}`
      }

      this.$router.push({path: q});
      this.activeTab = '0'
    },
    // END saved search
    ////////////////////////

    closeBroadcastSaveDropdown() {
      this.showBroadcastSaveDropdown = false;
    },
    textAreaFocus(e) { //set cursor at beginning when focusing textarea
      e.target.selectionEnd = 0;
    },
    navToBroadcast(item, event) {
      if (event.ctrlKey || event.metaKey || this.isPossibleMatches) return window.open(this.$router.resolve({path: `/broadcasts/${item.id}`}).href, '_blank');
      this.$router.push({path: `/broadcasts/${item.id}`});
    },
    navToBroadcastRules() {
      window.open(this.$router.resolve({name: 'broadcastRules'}).href, '_blank');
      // this.$router.push({name: `broadcastRules` });
    },
    navToGradingGuidelines() {
      window.open(this.$router.resolve({name: 'gradingGuidelines'}).href, '_blank');
      // this.$router.push({name: `gradingGuidelines` });
    },
    navToRSS() {
      // Naviagate to /RSS including the path as set on broadcast
      const p = this.$route.fullPath;
      let q = ""
      if (~p.indexOf("?")) {
        q = "?" + p.substring(p.indexOf("?") + 1);
      }
      this.$router.push({path: `/rss${q}`});
    },
    closeMainDropdown() {
      this.showMainDropdown = false;
    },
    showBroadcastSettings() {
      this.$refs.BroadcastSettings.$emit("showModal")
      this.showMainDropdown = false;
    },
    delBroadcast() {
      this.showConfirmDeleteModal = true;
    },
    delBroadcastConfirm() {
      return this.deleteBroadcast(this.actionItem)
          .then(resp => {
            if (resp.data.status !== 200) throw resp;
            this.actionItem = null;
            this.showConfirmDeleteModal = false;
            this.$router.go(this.$router.currentRoute)
            eventBus.$emit("createSnackbar", {
              type: "success",
              title: "Broadcast successfully deleted",
            })
          })
          .catch(err => {
            eventBus.$emit("createSnackbar", {
              type: "error",
              title: "Broadcast could not be deleted",
              body: "Please try again. If this error keeps occuring, please contact The Broker Site",
              timeoutInMS: 5000,
            })
          })
    },
    resendBroadcastConfirm() {
      return this.resendBroadcast(this.actionItem)
          .then(resp => {
            if (resp.data.status !== 200) throw resp;
            this.actionItem = null;
            this.showConfirmResendModal = false;
            this.$router.go(this.$router.currentRoute)
            eventBus.$emit("createSnackbar", {
              type: "success",
              title: "Broadcast successfully resent",
            })
          })
          .catch(err => {
            eventBus.$emit("createSnackbar", {
              type: "error",
              title: "Broadcast could not be resent",
              body: "Please try again. If this error keeps occuring, please contact The Broker Site",
              timeoutInMS: 5000,
            })
          })
    },
    showComposeBroadcast() {

      _.extend(this.composeBroadcastPayload, {
        listType: "",
        fromEmail: this.user.email,
        broadcastType: "wts",
        quantity: null,
        brand: null,
        desc: null,
        condition: null,
        pLink: null,
        price: null,
        currency: "EUR",
        additionalInfo: "", //`\n ${this.user.signature}`. Add signature on server instead
      });

      this.showBroadcastComposeModal = true;
      this.disableBroadcastSendButton = false;
    },
    toCompanyPageNoUser() {
      this.$router.push({path: `/companies/${this.part.company.id}`});
    },

    async fetchBroadcastLimit() {
      try {
        const response = await this.getBroadcastLimit(this.composeBroadcastPayload)
        // console.log('broadcast limit', response)
        this.broadcastLimitReached = response.data.payload.broadcastLimitReached
        this.broadcastLimitData = response.data.payload.broadcastLimitData
      } catch (error) {
        console.error('Failed to get broadcast limit data', error)
      }
    },

    async submitBroadcast() {

      const isValid = await this.$refs.sendBroadcast.validate();
      if (!isValid) return;

      this.disableBroadcastSendButton = true;

      const resp = await this.createBroadcast(this.composeBroadcastPayload);

      if (resp.data.status !== 200) {

        if (resp.data.payload.msg === "Broadcast rules not obeyed.") {

          let reason;
          switch (resp.data.payload.extra) {
            case "DUPL":
              reason = "Duplicate broadcast attempt"
              break;
            case "DAYLIMIT":
              reason = "Limit of maximum 20 broadcasts per day"
              break;
            case "LIMIT":
              reason = "Limit of maximum 5 broadcasts per list per contact per day"
              break;
            case "SPAM":
              reason = "Spam Limit of maximum 5 broadcasts per 15 minutes"
              break;
          }

          eventBus.$emit("createSnackbar", {
            type: "error",
            title: "Broadcast rules not obeyed",
            body: `Broadcast could not be sent. <br/> Reason: ${reason}`,
            timeoutInMS: 5000,
          })
        } else {
          eventBus.$emit("createSnackbar", {
            type: "error",
            title: "Something went wrong",
            body: "Broadcast could not be sent. Please try again. <br/> If the problem persists please contact The Broker Site",
            timeoutInMS: 5000,
          })
        }

      } else {

        const snackPayload = {
          type: "success",
          title: "Broadcast sent",
          timeoutInMS: window.tbs.isTestMode ? 10000 : undefined,
        }

        if (window.tbs.isTestMode) {
          snackPayload.body = `(ONLY DURING DEMO/TEST): Hi all, here's an <a href="http://s-brokr-app02.nedcomp.nl:3001/stats/${resp.data.payload.id}" target="_blank" class="font-bold">url</a> to check the stats of your broadcast`;
        }

        eventBus.$emit("broadcastSend", {id: resp.data.payload.id})
        eventBus.$emit("createSnackbar", snackPayload)
        this.closeComposeBroadcastModel();
      }
    },
    closeComposeBroadcastModel() {
      this.showBroadcastComposeModal = false;
      requestAnimationFrame(() => {
        this.$refs.sendBroadcast.reset();
      });
    },
  },
}
</script>
<style scoped>
.member_trusted {
  width: 24px;
  height: 24px;
  background: url("../assets/trusted_small.png");
  background-size: cover;
}

.member_gold {
  width: 24px;
  height: 24px;
  background: url("../assets/gold_small.png");
  background-size: cover;
}

.member_diamond {
  width: 24px;
  height: 24px;
  background: url("../assets/diamond_small.png");
  background-size: cover;
}

.hover-higlight-anchor:hover .bin-icon {
  display: inline-block
}
</style>