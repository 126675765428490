<template>
	<base-layout>
		<template v-slot:header>
			Subscribe to our Newsletter
		</template>
		<template v-slot:main>
			<div >
				

			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'Newsletter',
  components: {
    BaseLayout
  }
}
</script>
