<template>
  <base-layout :class='{isMultiSearch}'>
    <template v-slot:header>
      <span v-if='!payload.doShowAdvanced'>Parts Search</span>
      <span v-else>Advanced Parts Search</span>
    </template>
    <template v-slot:main>
      <div>
        <!-- Search form -->
        <form @submit.self.prevent="doSearch">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 sm:col-span-6 lg:col-span-5">
              <label for="pSearch" class="form-label block truncate">Part Number, Description, Keywords</label>
              <input v-model="tempQuery" id="pSearch" class="form-input mt-1 block w-full" :placeholder="placeholder"
                     @keyup.enter='doSearch'/>

              <!-- <div v-if='!payload.doShowAdvanced' class="w-64 mt-2 mb-2">
                <input id="onlyFavs" type="checkbox" class="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out">
                <label for="onlyFavs" class="ml-2 text-sm leading-5">
                  Show only results from Favorites
                </label>
              </div> -->

              <!-- Exact Match Checkbox Single Search -->
              <div v-if='!payload.doShowAdvanced' class="w-64 mt-2 mb-2">
                <input id="exactPhrase" type="checkbox" class="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out" v-model="payload.exactPhrase">
                <label for="exactPhrase" class="ml-2 text-sm leading-5">
                  Show only exact matches
                </label>
              </div>


              <a v-if='!payload.doShowAdvanced && user' href='javascript:void(0)' @click="toAdvancedSearch"
                 class='text-sm mt-2 text-red-600 hover:underline block hidden lg:inline-block'>Advanced Search</a>

              <input v-if='payload.doShowAdvanced' v-model="tempQuery2" id="pSearch"
                     class="form-input mt-1 block w-full" :placeholder="placeholder" @keyup.enter='doSearch'/>
              <input v-if='payload.doShowAdvanced' v-model="tempQuery3" id="pSearch"
                     class="form-input mt-1 block w-full" :placeholder="placeholder" @keyup.enter='doSearch'/>
              <input v-if='payload.doShowAdvanced' v-model="tempQuery4" id="pSearch"
                     class="form-input mt-1 block w-full" :placeholder="placeholder" @keyup.enter='doSearch'/>
              <input v-if='payload.doShowAdvanced' v-model="tempQuery5" id="pSearch"
                     class="form-input mt-1 block w-full" :placeholder="placeholder" @keyup.enter='doSearch'/>

            </div>

            <div class="col-span-12 sm:col-span-6 lg:col-span-5 relative" v-if='payload.doShowAdvanced'>
              <div class='lg:absolute lg:bottom-0 lg:left-0 lg:right-0'>
                <label for="cCountry" class="form-label block">Country</label>
                <select v-model="payload.country" id="cCountry" class="form-select mt-1 block w-full">
                  <option v-for="item in countriesOptions" :value="item.value" :key='item.value'>{{ item.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-span-12 sm:col-span-12 lg:col-span-2 relative">
              <button v-if='!payload.doShowAdvanced' @click="doSearch" :disabled='tempQuery.length < 2' type="button"
                      class="btn btn-primary float-right sm:float-left lg:mt-6">Search
              </button>
              <button v-else @click="doSearch" :disabled='tempQuery.length < 2' type="button"
                      class="btn btn-primary float-right sm:float-left lg:mt-6 lg:absolute bottom-0">Search
              </button>
            </div>

            <!-- <div v-if='payload.doShowAdvanced' class="w-64 mt-0">
              <input id="onlyFavs" type="checkbox" class="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out">
              <label for="onlyFavs" class="ml-2 text-sm leading-5">
                Show only results from Favorites
              </label>
            </div> -->

            <!-- Exact Match Checkbox Advanced Search -->
            <div v-if='payload.doShowAdvanced' class="w-64 mt-0">
              <input id="exactPhrase" type="checkbox" class="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out" v-model="payload.exactPhrase">
              <label for="exactPhrase" class="ml-2 text-sm leading-5">
                Show only exact matches
              </label>
            </div>

            <a v-if='payload.doShowAdvanced' href='javascript:void(0)' @click="toStandardSearch"
               class='col-span-12 -mt-2 text-sm text-red-600 hover:underline block'>Standard Search</a>

          </div>
        </form>

        <button v-if="payload.companyId" @click="payload.companyId = null; doSearch()"
                class='mt-8 -mb-4 text-sm btn btn-muted text-red-700 border-red-700 hover:line-through hover:text-red-700'>
          Parts shown for {{ companyNameMap[payload.companyId] }}
        </button>
        <button v-if="payload.country && !payload.doShowAdvanced" @click="payload.country = null; doSearch()"
                class='mt-8 -mb-4 text-sm btn btn-muted text-red-700 border-red-700 hover:line-through hover:text-red-700'>
          Parts shown for {{ payload.country }}
        </button>

        <!-- Table -->
        <div v-if='isValidSearch'>
          <div v-for="(cat, index) in partsEnriched" :key="index">
            <div v-if='isMultiSearch' class='mt-4 sm:mt-12 -mb-8 text-sm '>
              <span class='font-bold'>Search for: {{ cat.query }}</span> ({{ cat.numResults }} results)

              <a v-if='isMultiSearch' @click="navToSinglePartSearch(cat.query, payload.country)"
                 href='javascript:void(0)' class='text-red-600 hover:underline float-right  mt-2 font-normal'>See
                results for '{{ cat.query }}'</a>

            </div>
            <div class="flex flex-col mt-4 sm:mt-12">
              <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">

                <div v-if='nrParts && !isMultiSearch' class='float-right hidden lg:inline-block'>
                  <select v-model='pageSize' class="mt-1 mb-2 form-select pr-8">
                    <option v-for='availPageSize in availPageSizes' :key='availPageSize.value'
                            :value='availPageSize.value'>{{ availPageSize.label }}
                    </option>
                  </select>
                </div>

                <!-- <div class='float-left text-sm text-red-600 hover:underline mr-8'>All Results</div>
                <div class='float-left text-sm text-red-600 hover:underline'>Favorite Results Only</div> -->
                <div v-if='nrParts'>
                  <!-- Big screen -->
                  <div v-if="partsEnriched[0].payload.hasLandingPage" class="pt-2 text-red-600 font-semibold"><a
                      v-bind:href="'/'+partsEnriched[0].payload.q" target="_blank" rel="noopener">Availability and
                    statistics for part {{ partsEnriched[0].payload.q }}</a></div>
                  <div
                      class="align-middle hidden lg:inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="w-full table-fixed">
                      <thead>
                      <tr>
                        <th class="table-cell-header hover:text-red-700 w-24" @click='sort("Partnumber")'>Partnumber /
                          Description
                        </th>
                        <th class="table-cell-header hover:text-red-700 w-12" @click='sort("Manufacturer")'>
                          Manufacturer
                        </th>
                        <th class="table-cell-header hover:text-red-700 w-6" @click='sort("Quantity")'>Quantity</th>
                        <th class="table-cell-header hover:text-red-700 w-6" @click='sort("PartCondition")'>Condition
                        </th>
                        <th class="table-cell-header w-10" @click='sort("Price")'>
                          <span class='hover:text-red-700'>Price</span>
                          <div class='inline-block'>
                            <a href='javascript:void(0)' @click.stop='changeCurrency("EUR")'
                               class='text-red-600 hover:text-red-700 ml-2'>€</a>
                            <a href='javascript:void(0)' @click.stop='changeCurrency("USD")'
                               class='text-red-600 hover:text-red-700 ml-1'>$</a>
                            <a href='javascript:void(0)' @click.stop='changeCurrency("GBP")'
                               class='text-red-600 hover:text-red-700 ml-1'>£</a>
                          </div>

                        </th>
                        <th class="table-cell-header pr-0 pl-0 w-2"><!-- Emblem gold/trusted --></th>
                        <th class="table-cell-header pl-2 hover:text-red-700 w-20" @click='sort("inv_companyname")'>
                          Company
                        </th>
                        <th class="table-cell-header w-8"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr @click="navToPart(item, $event)" v-for="(item, index2) in cat.results" :key="index2"
                          class="bg-white hover:bg-red-50 cursor-pointer transition duration-150 ease-in-out hover-higlight-anchor"
                          :class='{"": index2 % 2}'>
                        <td class="table-cell text-gray-900 hover-higlight">
                          <div
                              class="text-red-600 font-semibold leading-4  max-w-72  hover-higlight transition duration-150 ease-in-out truncate">
                            {{ item.partNumber }}
                          </div>
                          <div class='leading-4 max-w-72 truncate' :title='item.description'>{{ item.description }}
                          </div>
                        </td>
                        <td class="table-cell">{{ item.manufacturer }}</td>
                        <td class="table-cell">{{ item.quantity }}</td>
                        <td class="table-cell">{{ item.condition }}</td>
                        <td class="table-cell">
                          <span
                              v-if="item.price != 'CALL'">{{ currencySymbol }} {{ formatNumber(item.priceLocalCurrency) }}</span>
                          <span v-else>{{ item.priceLocalCurrency }}</span>
                        </td>
                        <td class="table-cell pr-0 pl-0">
                          <div v-if="item.isDiamond" class='member_diamond'
                               title="This company is a 'Diamond Member'. Diamond members have been on www.TheBrokerSite.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                          <div v-if="item.isGold" class='member_gold'
                               title="This company is a 'Gold Member'. Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                          <div v-if="item.isTrusted" class='member_trusted'
                               title="This company is a 'Trusted Member'. Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                        </td>
                        <td class="table-cell text-gray-500 company-cell font-semibold pl-2" :title='item.companyName'
                            @click.stop="navToCompany(item)">
                          <!-- <div v-if="item.isGold || item.isTrusted" class='truncate inline-block max-w-full align-middle pr-16'>
                            <span v-if="item.isGold" title='Gold member' class="tag ml-2 bg-yellow-200 text-yellow-800 float-right -mr-16"><div class='member_gold'></div></span>
                            <span v-if="item.isTrusted" title='Trusted member' class="tag ml-2 bg-gray-100 text-gray-800 float-right -mr-16"><div class='member_trusted'></div></span>
                            {{item.companyName}}
                          </div>
                          <div v-else>{{item.companyName}}</div> -->
                          <div class='max-w-72 truncate'>{{ item.companyName }}</div>
                          <div class='leading-4 text-gray-500 font-normal text-xs max-w-72 truncate'>
                            {{ item.country }} &bull; {{ item.companyTel }}
                            <!-- {{item.country}} -->
                          </div>
                        </td>

                        <td class="table-cell">
                          <button @click.stop='$refs.modalRFQ.openModal(item)'
                                  class="relative items-center btn btn-muted text-red-700 border-red-700 hover:text-red-700">
                            RFQ
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- Small screen -->
                  <div
                      class="align-middle inline-block lg:hidden min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="w-full table-fixed">
                      <thead>
                      <tr>
                        <th class="table-cell-header hover:text-red-700 w-24">Parts</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr @click="navToPart(item, $event)" v-for="(item, index2) in cat.results" :key="index2"
                          class="bg-white hover:bg-red-50 cursor-pointer transition duration-150 ease-in-out hover-higlight-anchor"
                          :class='{"": index2 % 2}'>
                        <td class="table-cell text-gray-900 hover-higlight">
                          <div class="text-red-600 ">
                            <a href='javascript:void(0)' @click.stop='$refs.modalRFQ.openModal(item)'
                               class="float-right ml-2 inline-block text-red-700 underline">[RFQ]</a>
                            <div class='truncate mr-12'>
                              <span v-if='item.manufacturer'>{{ item.manufacturer }} - {{ item.partNumber }}</span>
                              <span v-else>{{ item.partNumber }}</span>
                            </div>
                          </div>
                          <div class='truncate font-semibold' :title='item.companyName'
                               @click.stop="navToCompany(item, true)">
                            <div v-if="item.isDiamond" class='member_diamond float-left mr-2'
                                 title="This company is a 'Diamond Member'. Diamond members have been on www.TheBrokerSite.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                            <div v-if="item.isGold" class='member_gold float-left mr-2'
                                 title="This company is a 'Gold Member'. Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                            <div v-if="item.isTrusted" class='member_trusted float-left mr-2'
                                 title="This company is a 'Trusted Member'. Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
                            {{ item.companyName }}
                          </div>
                          <div class='truncate' :title='item.description'>{{ item.description }}</div>

                          <div class='hidden md:block truncate text-gray-500'>
                            Quantity: {{ item.quantity }} - Condition: {{ item.condition }} -
                            Price:
                            <span
                                v-if="item.price != 'CALL'">{{ currencySymbol }} {{ formatNumber(item.priceLocalCurrency) }}</span>
                            <span v-else>{{ item.priceLocalCurrency }}</span>
                          </div>

                          <div class='block md:hidden text-gray-500'>
                            <div class='truncate'>Quantity: {{ item.quantity }}</div>
                            <div class='truncate'>Condition: {{ item.condition }}</div>
                            <div class='truncate'>
                              Price:
                              <span
                                  v-if="item.price != 'CALL'">{{ currencySymbol }} {{ item.priceLocalCurrency }}</span>
                              <span v-else>{{ item.priceLocalCurrency }}</span>
                            </div>
                          </div>
                        </td>

                        <!-- <td class="table-cell">

                        </td> -->
                      </tr>
                      </tbody>
                    </table>
                  </div>

                </div>


              </div>
            </div>

            <Pagination v-if='!isMultiSearch' :itemList="cat.results" :payload="payload"
                        :pageSize="pageSize"></Pagination>
          </div>
        </div>

        <!-- Top Searches big screen //-->
        <!-- <div v-if='numResults === 0' class="rounded-lg shadow-lg overflow-hidden bg-white p-6 relative mt-8 mb-8 hidden lg:block">
          <div class='hero-3'></div>
          <h2 class='text-xl font-semibold text-red-700 mb-2 leading-6'>Top Searches</h2>
          <p class='text-sm text-gray-600 pb-4 mt-4'>
          <router-link
            :to='item.link' v-for='item in topSearchesExtended' :key='item.label'
            class='hover:underline font-semibold mr-3 text-red-600'
          >
            <div class="grid grid-cols-7 gap-6">
              <div>{{item.label}}</div>
              <div class="col-span-6">{{item.desc}}</div>
            </div>
          </router-link>
          </p>
        </div> -->

        <div v-if='numResults === 0 || !isValidSearch'
             class="rounded-lg shadow-lg overflow-hidden bg-white p-6 relative mt-8 mb-8 hidden lg:block">
          <div class='hero-3'></div>
          <h2 class='text-xl font-semibold text-red-700 mb-2 leading-6'>Top Searches</h2>
          <p class='text-sm text-gray-600 pb-4 mt-4'>
            <router-link
                :to='item.link' v-for='item in topSearchesExtended' :key='item.label'
                class='font-semibold mr-3 text-red-600'
            >
              <div>{{ item.label }}</div>
              <div class="font-normal text-gray-600">{{ item.desc }}</div>
            </router-link>
          </p>
        </div>

        <!-- Top Searches small screen //-->
        <div v-if='numResults === 0 || !isValidSearch'
             class="rounded-lg shadow-lg overflow-hidden bg-white p-6 relative mt-8 mb-8 block lg:hidden">
          <div class='hero-3'></div>
          <h2 class='text-xl font-semibold text-red-700 mb-2 leading-6'>Top Searches</h2>
          <p class='text-sm text-gray-600 pb-4 mt-4'>
            <router-link
                :to='item.link' v-for='item in topSearchesExtended' :key='item.label'
                class='hover:underline font-semibold mr-3' :class='item.clazz'
            >
              {{ item.label }}
            </router-link>
          </p>
        </div>


        <modal v-show="showCompanyModal" @submit="closeCompanyModal" @cancel="closeCompanyModal"
               :doSkipHeader="true"
               :doSkipCancel="true"
               customCssClasses="sm:max-w-6xl"
               affirmativeButtonLabel='Close'>
          <template v-slot:body>
            <CompanyBody :isInModal="true" :selectedCompanyEnriched='selectedCompany'
                         :contactsEnriched="selectedCompanyContacts"></CompanyBody>
          </template>
        </modal>

        <ModalRFQ ref='modalRFQ'></ModalRFQ>

      </div>
    </template>
  </base-layout>
</template>

<script>
import _ from 'lodash'
import BaseLayout from '@/components/BaseLayout.vue'
import ListMixin from '@/components/mixins/List'
import ModalRFQ from '@/components/ModalRFQ.vue'

import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ListMixin],
  name: 'Parts',
  components: {
    BaseLayout,
    ModalRFQ,
  },
  data: () => ({
    payload: {
      query: "",
      query2: "",
      query3: "",
      query4: "",
      query5: "",
      country: "",
      page: 1,
      companyId: null,
      orderCol: "",
      orderDesc: false,
      doShowAdvanced: false,
      doFavsOnly: false,
      exactPhrase: false,
    },
    // advanced
    tempQuery2: "",
    tempQuery3: "",
    tempQuery4: "",
    tempQuery5: "",
    fetchActionName: "getParts",
    numResultsName: "nrParts", //used in list-mixin
    // doShowAdvanced: false,
    topSearches: [],
  }),
  async created() {
    this.topSearches = await this.getTopSearches()
  },
  computed: {
    ...mapState(['nrParts', 'currency']),
    ...mapGetters(['partsEnriched', 'countriesOptions', 'companyNameMap']),
    isValidSearch() {
      return _.compact([this.payload.query, this.payload.query2, this.payload.query3, this.payload.query4, this.payload.query5]).length;
    },
    isMultiSearch() {
      return _.compact([this.payload.query, this.payload.query2, this.payload.query3, this.payload.query4, this.payload.query5]).length > 1;
    },
    placeholder() {
      return !this.payload.doShowAdvanced ? "E.g.: HP/IBM or Laptop" : "Partnumber, Description, Keywords"
    },
    currencySymbol() {
      switch (this.currency) {
        case "EUR":
          return "€";
        case "USD":
          return "$";
        case "GBP":
          return "£";
      }
      return "€"
    },
    topSearchesExtended() {
      const items = _.map(this.topSearches, (item, i) => ({
        label: item.partnumber,
        desc: item.description,
        link: `/parts?query=${item.partnumber}`,
        clazz: i % 3 === 0 ? 'text-yellow-500' : (i % 3 === 1 ? 'text-red-600' : 'text-red-800')
        // clazz: 'text-red-600', // in this page there should be no color difference between parts, unlike the home page
      }))
      // console.log(items)
      return items;
    },
  },
  methods: {
    ...mapActions(["getParts", "getTopSearches"]),
    ...mapMutations(['changeCurrency']),
    navToPart(item, event) {
      if (event.ctrlKey || event.metaKey) return window.open(this.$router.resolve({path: `/parts/${item.id}`}).href, '_blank');
      this.$router.push({path: `/parts/${item.id}`});
    },
    toAdvancedSearch() {
      this.payload.doShowAdvanced = true
      this.$router.push({name: "parts", query: this.payload});
    },
    toStandardSearch() {

      this.payload.query2 = "";
      this.payload.query3 = "";
      this.payload.query4 = "";
      this.payload.query5 = "";
      this.payload.doShowAdvanced = false

      this.$router.push({name: "parts", query: this.payload});
    },
    navToSinglePartSearch(q, country) {
      this.$router.push({name: "parts", query: {query: q, country}});
    },
    sort(orderCol) {
      if (this.payload.orderCol === orderCol) {
        this.payload.orderDesc = !this.payload.orderDesc
      } else {
        this.payload.orderCol = orderCol
      }
      this.doSearch();
    },
    formatNumber(number) {
      return new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(number);
    },

  }
}
</script>
<style scoped>
.member_trusted {
  width: 24px;
  height: 24px;
  background: url("../assets/trusted_small.png");
  background-size: cover;
}

.member_gold {
  width: 24px;
  height: 24px;
  background: url("../assets/gold_small.png");
  background-size: cover;
}

.member_diamond {
  width: 24px;
  height: 24px;
  background: url("../assets/diamond_small.png");
  background-size: cover;
}

/* only cursor = pointer for single standard search (non multisearch) */
th {
  cursor: pointer;
}

.isMultiSearch th, .isMultiSearch th span {
  cursor: default;
}

.isMultiSearch th:hover, .isMultiSearch th:hover span {
  color: #6b7280;
}

</style>
