<template>
	<base-layout>
		<template v-slot:header>
			Page not found
		</template>
		<template v-slot:main>
			<div >
				
				<strong>Oops! The content you are looking for is not available or no longer exists.</strong>
                <br /><br />
                Please check the spelling of the URL you requested and try again.<br />
                Feel free to <router-link to='/contact' class='text-red-600 hover:underline'>contact us</router-link> and let us know what information you are looking for if you think you are viewing this message by mistake.
                <br /><br />
                <router-link to='/broadcasts' class='text-red-600 hover:underline'>Looking for Broadcasts?</router-link><br />
                <router-link to='/parts' class='text-red-600 hover:underline'>Looking for Parts?</router-link><br />
                <router-link to='/companies' class='text-red-600 hover:underline'>Looking for Companies?</router-link><br /><br />
                <router-link to='/news?catId=1' class='text-red-600 hover:underline'>Fraud warnings</router-link><br />
                <router-link to='/news' class='text-red-600 hover:underline'>TBS News</router-link><br /><br />
                <router-link to='/' class='text-red-600 hover:underline'>TBS Homepage</router-link><br />
                <br /><br />
                <br /><br />
			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'PageNotFound',
  components: {
    BaseLayout
  }
}
</script>
