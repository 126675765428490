<template>
	<div>
		<strong>Please use the TBS Grading Guidelines as a manual to set up your own Grading System.</strong>
    <br />
    Please make sure to post your own Grading System on your website and to send it with your invoice when selling used equipment.
    <br />
    TBS advises to only buy or sell used equipment with an item by item description.
    <br /><br />
	<!-- To file a complaint about another member, go to their company profile on TBS and click on the "File Complaint" button. Make sure to provide a brief but clear description of the complaint and upload your Purchase Order and Invoice. Make sure that the quality of the goods you are buying is always mentioned on the PO and Invoice. We do not accept complaints that are filed without a description, PO or Invoice. Please note that TBS doesn't mediate in cases with a value below 2000 Euro or where hardware is sold in "As Is" condition, please only buy "As Is" from companies you have a long term relationship with. TBS does not mediate in Software cases!  -->
	To file a complaint about another member, navigate to their company profile on TBS and click the "File Complaint" button. Please ensure you provide a brief yet clear description of the issue and upload both your Purchase Order (PO) and Invoice.
	It's essential that the quality/grade of the goods is clearly stated on the PO and Invoice, as complaints without these details cannot be accepted.<br>
	<strong>Important Notes:</strong> TBS does not mediate in cases involving <strong>C-grade or D-grade hardware</strong>, transactions valued below <strong>€2,000</strong>, or goods sold <strong>"As Is."</strong> We strongly advise only purchasing "As Is" items from companies with whom you have an established long-term relationship. TBS does <strong>not</strong> mediate disputes related to software transactions.

    <br /><br />

    <table class="bg-white">
    	<thead>
		    <tr class='bg-gray-100'>
		      <th width=50><strong>Grade</strong></th>
		      <th width=145>Desktop</th>
		      <th width=145>Laptop</th>
		      <th width=145>Monitor</th>
		      <th width=145>Server</th>
		      <th width=145>Mobile Phone/Tablet</th>
		      <th width=145>Printers</th>
		    </tr>
	  	</thead>
	    <tbody>
		    <tr>
		      <td><strong>A</strong></td>
		      <td>-Tested and 100% Functional.<br />-100% Good optical state but you can see that it has been used.</td>
		      <td>-Tested and 100% Functional.<br />-100% good optical state but you can see that it has been used.<br />-Max. 5 dead pixels on Screen.<br />-100% complete should come with adaptor.<br />-Battery should hold power for 30 minutes.</td>
		      <td>-Tested and 100% Functional.<br />-100% good optical state but you can see that it has been used.<br />-Max. 5 dead pixels on Screen.</td>
		      <td>-Tested and 100% Functional.<br />-100% Good optical state but you can see that it has been used.<br />-Should come with rails</td>
		      <td>-Tested and 100% Functional.<br />-100% good optical state but you can see that it has been used.<br />-Max. 5 dead pixels on Screen.<br />-100% complete should come with adaptor.<br />-Battery should hold power for 30 minutes.</td>
		      <td>-Second user equipment that is complete, cleaned, tested and generally serviced, with any apparent defective parts repaired/replaced as necessary to meet warranty requirements. Grade A units are in good cosmetic condition, allowing for normal usage and the age of the unit (some slight discolouration is normal on older printers). Grade A does not actually mean ‘mint’ or ‘refurbished’.</td>
		    </tr>
		    <tr>
		      <td><strong>B</strong></td>
		      <td>-Tested and 100% Functional.<br />-Small cosmetic damage like small scratches, cracks, removed security marks etc.</td>
		      <td>-Tested and 100% Functional.<br />-Small cosmetic damage like small scratches, cracks, removed security marks etc.<br />-Adaptor can be missing.<br />-Should start up on Battery.</td>
		      <td>-Tested and 100% Functional.<br />-Small cosmetic damage like small scratches, cracks, removed security marks etc.<br />-Adaptor can be missing.</td>
		      <td>-Tested and 100% Functional.<br />-Small cosmetic damage like small scratches, cracks, removed security marks etc.<br />-With or without rails.</td>
		      <td>-Tested and 100% Functional.<br />-Small cosmetic damage like small scratches, cracks, removed security marks etc.<br />-Adaptor can be missing.<br />-Should start up on battery.</td>
		      <td>-Second user equipment that is cleaned, tested and working, but may have cosmetic flaws, parts missing or minor problems which will be defined with the sale. This is a common grade for older printers where new plastic covers are unavailable or uneconomic to source at the price offered.</td>
		    </tr>
		    <tr>
		      <td><strong>C</strong></td>
		      <td>-Repairable but not functional.<br />-Large cracks, missing DVD-RAM-HDD, missing chassis parts, etc.-Non removable password.</td>
		      <td>-Repairable but not functional.<br />-Large cracks, missing DVD-RAM-HDD, missing chassis parts, defect monitor, missing keys, no battery.<br />-Non removable password</td>
		      <td>Scrap not functional can only be used for parts.</td>
		      <td>-Repairable but not functional.<br />-Without rails.<br />-Large cracks, missing RAM-HDD, missing chassis parts, etc.<br />-Non removable password</td>
		      <td>-Repairable but not functional.</td>
		      <td>-Second user equipment that is sold as-is. May be working or not, but condition and terms will be stated with sale. Warranty is usually DOA (if working) or without warranty if not.</td>
		    </tr>
		    <tr>
		      <td><strong>D</strong></td>
		      <td>-Scrap not functional can only be used for parts.</td>
		      <td>-Scrap not functional can only be used for parts.</td>
		      <td>-Scrap not functional can only be used for parts.</td>
		      <td>-Scrap not functional can only be used for parts.</td>
		      <td>-Scrap not functional can only be used for parts.</td>
		      <td>-Scrap not functional can only be used for parts.</td>
		    </tr>
	  	</tbody>
    </table>
	</div>
</template>

<script>

export default {
  name: 'GradingGuidelinesBody',
}
</script>
<style scoped>
	td, th{
		font-size: 0.85rem;
		vertical-align: top;
		padding: 8px;
		border: 1px solid #ddd;
	}
</style>