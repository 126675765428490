<template>
	<base-layout>
		<template v-slot:header>
			Advertising
		</template>
		<template v-slot:main>
			<div >
				<div class='bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-6 mb-4'>
					<h2 class="text-lg font-bold leading-5 text-red-700 mt-4 mb-4">Advertising Opportunities</h2>
					Daily Average sessions: >3,000<br />
					Daily Average Page Views: >18,000
					<br /><br />
					<em>Banner Advertising</em><br />
					TBS offers banner advertising on most of its pages.<br />
					At the bottom of each page, 5 banners are shown which are in a rotation of multiple banners, and the size of these banners is 185 x 185 pixels.<br />
					Banners are sold for a minimum of 12 months.<br />
					<br />
					Please <a href="/contact" class='text-red-600 hover:underline '>contact us</a>, if you are interested in advertising with us.<br />
					<br />
					Only 4 Banners per view<br />
					185 x 185 pixels per banner image<br /><br />
					</div>
			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'Advertising',
  components: {
    BaseLayout
  }
}
</script>