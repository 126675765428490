<template>
	<base-layout :doSkipHeader="true">
		<template v-slot:main>
			<div class="grid lg:grid-cols-2 gap-4 md:gap-8">
				<dd>

					<div class="text-3xl md:text-4xl font-bold">
						Take your IT-business<br />to the <span class="text-red-600">next level</span>
					</div>

					Fill out the form for your 1-month free trial

					<div class="hidden md:block">
						<div class="px-3 py-3 md:px-6">
							<div class="clear-left py-2">
								<div class="relative p-1 border-2 border-transparent rounded-full hover:text-white focus:outline-none focus:text-white float-left">
									<div class='tag absolute left-0 top-0 mt-0 bg-red-600 text-white text-xs px-1.5 py-0.5 leading-tight font-normal'>1</div>
								</div>
								<div class="w-5/6 ml-4 float-left">Sign up through the form</div>
							</div>
							
							<div class="clear-left py-2">
								<div class="relative p-1 border-2 border-transparent rounded-full hover:text-white focus:outline-none focus:text-white float-left">
									<div class='tag absolute left-0 top-0 mt-0 bg-red-600 text-white text-xs px-1.5 py-0.5 leading-tight font-normal'>2</div>
								</div>
								<div class="w-5/6 ml-4 float-left">No payment details required</div>
							</div>
						
							<div class="clear-left py-2">
								<div class="relative p-1 border-2 border-transparent rounded-full hover:text-white focus:outline-none focus:text-white float-left">
									<div class='tag absolute left-0 top-0 mt-0 bg-red-600 text-white text-xs px-1.5 py-0.5 leading-tight font-normal'>3</div>
								</div>
								<div class="w-5/6 ml-4 float-left">Get approved for your free trial</div>
							</div>
						
							<div class="clear-left py-2">
								<div class="relative p-1 border-2 border-transparent rounded-full hover:text-white focus:outline-none focus:text-white float-left">
									<div class='tag absolute left-0 top-0 mt-0 bg-red-600 text-white text-xs px-1.5 py-0.5 leading-tight font-normal'>4</div>
								</div>
								<div class="w-5/6 ml-4 float-left">Enjoy all member benefits without trial limitations</div>
							</div>
						</div>

						<br /><br />

						During the free trial you will be able to

						<div class="px-3 py-3 md:px-6">
							<div class="clear-left py-2">
								<svg class="w-5 float-left -mr-2 mt-0" viewBox="0 0 48 48">
									<path fill="#ec1c24" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z" />
								</svg>
								<div class="w-5/6 md:w-auto ml-4 float-left">Access the trading network of <strong>2700+ verified professional traders</strong></div>
							</div>
							
							<div class="clear-left py-2">
								<svg class="w-5 float-left -mr-2 mt-0" viewBox="0 0 48 48">
									<path fill="#ec1c24" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z" />
								</svg>
								<div class="w-5/6 ml-4 float-left">Buy and sell your IT-hardware <strong>safe and secure</strong></div>
							</div>
						
							<div class="clear-left py-2">
								<svg class="w-5 float-left -mr-2 mt-0" viewBox="0 0 48 48">
									<path fill="#ec1c24" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z" />
								</svg>
								<div class="w-5/6 ml-4 float-left">Find the right deal among <strong>600+ daily broadcasts</strong></div>
							</div>
						
							<div class="clear-left py-2">
								<svg class="w-5 float-left -mr-2 mt-0" viewBox="0 0 48 48">
									<path fill="#ec1c24" d="M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z" />
								</svg>
								<div class="w-5/6 ml-4 float-left">Reach the perfect customer through <strong>targeted audiences</strong></div>
							</div>
						</div>
					</div>
				</dd>

				<dd>
					<div class="mx-0 md:mx-16 px-6 md:px-0 rounded-lg shadow-lg overflow-hidden bg-white mt-0 md:mt-2">
							<div v-show='isFirst'>
								<ValidationObserver ref="registerForm1">
								<h1 class="text-2xl font-bold text-gray-900 px-4 sm:px-4 mt-8 block text-center">Company details</h1>

								<dl class='mt-4 mb-4'>
								
									<div class="w-full px-2 md:px-6">
										<div class="text-sm leading-9 font-medium text-gray-500">Company name <span class="text-red-600">*</span></div>
										<div class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0">
											<ValidationProvider name="Company name" rules="required" v-slot="{ errors }">
												<input ref="companyName" v-model='payload.company' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</div>

										<div class="text-sm leading-9 font-medium text-gray-500">Company address and city <span class="text-red-600">*</span></div>
										<div class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0">
											<ValidationProvider name="Company address" rules="required" v-slot="{ errors }">
												<input v-model='payload.address' class="form-input block w-full">
												<span class="text-gray-400 text-xs">Example: Stadionweg 18, Amsterdam</span>
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</div>

										<div class="text-sm leading-9 font-medium text-gray-500">Company zip code</div>
										<div class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0">
											<ValidationProvider v-slot="{ errors }">
												<input v-model='payload.zip' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</div>


										<div class="text-sm leading-9 font-medium text-gray-500">Company country of origin <span class="text-red-600">*</span></div>
										<div class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0">
											<ValidationProvider name="Company country" rules="required" v-slot="{ errors }">
												<select v-model="payload.country" class="form-select block w-full">
													<option v-for="item in countriesOptionsPleaseSelect" :value="item.value" :key='item.value'>{{item.name}}</option>
												</select>
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</div>

										<div class="grid mt-12 place-items-center">
											<button @click='whichStep(2)' type="button" :class='next1Class' class="rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">Next &gt;</button>
											<div class="mt-12">
												<div class="float-left"><img src="/img/circle_on.png" alt="" title="" width=18 height=18 /></div>
												<div class="float-left mt-2"><img src="/img/line_off.png" alt="" title="" width=41 height=3 /></div>
												<div class="float-left"><img src="/img/circle_off.png" alt="" title="" width=18 height=18 /></div>
												<div class="float-left mt-2"><img src="/img/line_off.png" alt="" title="" width=41 height=3 /></div>
												<div class="float-left"><img src="/img/circle_off.png" alt="" title="" width=18 height=18 /></div>
											</div>
										</div>
									</div>
								</dl>
								</ValidationObserver>
							</div>

							<div v-show='isSecond'>
								<ValidationObserver ref="registerForm1">
								<h1 class="text-2xl font-bold text-gray-900 px-4 sm:px-4 mt-8 block text-center">Company details</h1>

								<dl class='mt-4 mb-4'>
								
									<div class="w-full px-2 md:px-6">
										<div class="text-sm leading-9 font-medium text-gray-500">Company description</div>
										<div class="mt-1 mb-2 text-sm leading-4 text-gray-900 sm:mt-0">
											<textarea v-model='payload.desc' class="form-input block w-full" rows="3"></textarea>
										</div>

										<div class="text-sm leading-9 font-medium text-gray-500">Company website</div>
										<div class="mt-1 mb-2 text-sm leading-4 text-gray-900 sm:mt-0">
											<input v-model='payload.website' class="form-input block w-full">
										</div>

										<div class="text-sm leading-9 font-medium text-gray-500">Company {{vatLabel}}</div>
										<div class="mt-1 mb-2 text-sm leading-4 text-gray-900 sm:mt-0">
											<input v-model='payload.vat' class="form-input block w-full">
										</div>


										<div class="text-sm leading-9 font-medium text-gray-500">How did you hear from us?</div>
										<div class="mt-1 mb-2 text-sm leading-4 text-gray-900 sm:mt-0">
											<ValidationProvider name="Heard from" rules="required" v-slot="{ errors }">
											<select v-model="payload.hear" class="form-select block w-full">
												<option v-for="item in hearOptions" :value="item.value" :key='item.value'>{{item.name}}</option>
											</select>
											<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</div>

										<div class="grid grid-cols-2 mt-12 place-items-center">
											<dd><button @click='whichStep(1)' type="button" class="bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">&lt; Back</button></dd>
											<dd><button :disabled='disableNext2' @click='whichStep(3)' type="button" class="bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5" :class='next2Class'>Next &gt;</button></dd>
											<div class="mt-12 col-span-2">
												<div @click='whichStep(1)' class="float-left cursor-pointer"><img src="/img/circle_full.png" alt="" title="" width=18 height=18 /></div>
												<div class="float-left mt-2"><img src="/img/line_on.png" alt="" title="" width=41 height=3 /></div>
												<div class="float-left"><img src="/img/circle_on.png" alt="" title="" width=18 height=18 /></div>
												<div class="float-left mt-2"><img src="/img/line_off.png" alt="" title="" width=41 height=3 /></div>
												<div class="float-left"><img src="/img/circle_off.png" alt="" title="" width=18 height=18 /></div>
											</div>
										</div>

									</div>
								</dl>
								</ValidationObserver>
							</div>

							<div v-show='isThird'>
								<ValidationObserver ref="registerForm3">
								<h1 class="text-2xl font-bold text-gray-900 px-4 sm:px-4 mt-8 block text-center">Personal details</h1>

								<dl class='mt-4 mb-4'>
								
									<div class="w-full px-2 md:px-6">
										<div class="text-sm leading-9 font-medium text-gray-500">Full name <span class="text-red-600">*</span></div>
										<div class="mt-1 text-sm leading-4 text-gray-900 sm:mt-0">
											<ValidationProvider name="Full name" rules="required" v-slot="{ errors }">
												<input v-model='payload.name' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</div>

										<div class="text-sm leading-9 font-medium text-gray-500">Function <span class="text-red-600">*</span></div>
										<div class="mt-1 text-sm leading-4 text-gray-900 sm:mt-0">
											<ValidationProvider name="Function" rules="required" v-slot="{ errors }">
												<input v-model='payload.function' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</div>

										<div class="text-sm leading-9 font-medium text-gray-500">Email <span class="text-red-600">*</span></div>
										<div class="mt-1 text-sm leading-4 text-gray-900 sm:mt-0">
											<ValidationProvider name="Email" rules="required" v-slot="{ errors }">
												<input v-model='payload.email' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</div>

										<div class="text-sm leading-9 font-medium text-gray-500">Phone <span class="text-red-600">*</span></div>
										<div class="mt-1 text-sm leading-4 text-gray-900 sm:mt-0">
											<ValidationProvider name="Phone" rules="required" v-slot="{ errors }">
												<input v-model='payload.tel' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</div>

										<div class="flex items-center py-2 px-4 sm:px-6">
											<input v-model='payload.isContractAgreed' id="rfq_sendAll" type="checkbox" class="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out">
											<label for="rfq_sendAll" class="ml-4 block text-sm leading-5">
												I have read, understood and agree to the 
												<a href="javascript:void(0)"  class='text-red-700 hover:underline' @click='showContractModal = true'>contract terms</a>
											</label>
										</div>

										<div class="flex py-2 px-0 md:px-4">
											<vue-recaptcha class='w-full px-0 md:pl-12 mt-6'
												ref="recaptcha"
												@verify="onVerify"
												:sitekey="recaptchaKey">
											</vue-recaptcha>
										</div>

										<div class="grid grid-cols-2 mt-12 place-items-center">
											<dd><button @click='whichStep(2)' type="button" class="bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">&lt; Back</button></dd>
											<dd><button @click='join' type="button" :class='submitClass' class="rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5">Sign up ></button></dd>
											<div class="mt-12 col-span-2">
												<div @click='whichStep(1)' class="float-left cursor-pointer"><img src="/img/circle_full.png" alt="" title="" width=18 height=18 /></div>
												<div class="float-left mt-2"><img src="/img/line_on.png" alt="" title="" width=41 height=3 /></div>
												<div @click='whichStep(2)' class="float-left cursor-pointer"><img src="/img/circle_full.png" alt="" title="" width=18 height=18 /></div>
												<div class="float-left mt-2"><img src="/img/line_on.png" alt="" title="" width=41 height=3 /></div>
												<div class="float-left"><img src="/img/circle_on.png" alt="" title="" width=18 height=18 /></div>
											</div>
										</div>

									</div>
								</dl>
								</ValidationObserver>
							</div>

							<div v-show='isFinal'>
								<h1 class="text-2xl font-bold text-gray-900 px-2 md:px-4 mt-8 block text-center">Thanks for signing up</h1>

								<div class="my-12 text-center px-4 md:px-20 text-md">
									We will send you your login details as soon as we have <u>verified your profile</u>!<br /><br />
									<!-- After this free trial there are no obligations towards membership and you can stop the free trial at any given moment by sending us an email to stop the trial.<br /><br /> -->
										Trade Safe,<br /><br />
										The Broker Site Team
									<br><br><br><br><br>
									<!-- <div class="grid mt-4 place-items-center"><img src="/img/Confetti.png" alt="Thanks and Trade Safe!" title="Thanks and Trade Safe!" width=90 /></div>
									<div class="mt-10" v-show='showThumbs'>
										Did you find this form easy to use?
										<div class="grid mt-4 mx-24 place-items-center grid-cols-2 gap-6">
											<div><img @click='rate(1)' src="/img/thumbs_up.png" alt="Yes, great!" title="Yes, great!" width=38 class="hover:opacity-75 cursor-pointer" /></div>
											<div><img @click='rate(0)' src="/img/thumbs_down.png" alt="No, not so much" title="No, not so much" width=38 class="hover:opacity-75 cursor-pointer" /></div>
										</div>
									</div>
									<div class="mt-10" v-show='!showThumbs'>
										<strong>Thank you for your feedback!</strong><br /><br />
									</div> -->
								</div>

								<div class="grid grid-cols-1 place-items-center">
									<div class="my-8">
										<div class="float-left"><img src="/img/circle_full.png" alt="" title="" width=18 height=18 /></div>
										<div class="float-left mt-2"><img src="/img/line_on.png" alt="" title="" width=41 height=3 /></div>
										<div class="float-left"><img src="/img/circle_full.png" alt="" title="" width=18 height=18 /></div>
										<div class="float-left mt-2"><img src="/img/line_on.png" alt="" title="" width=41 height=3 /></div>
										<div class="float-left"><img src="/img/circle_full.png" alt="" title="" width=18 height=18 /></div>
									</div>
								</div>
							</div>

					</div>
				</dd>
			</div>

			<div class="clear-both"></div>

			<div class="mt-12 text-center text-4xl">Join over <span class="text-red-600">2,700 Traders</span></div>
			<div class="text-center text-sm">including the companies</div>

			<div class="mt-8 md:mt-4 grid grid-cols-1 gap-12 md:gap-0 md:grid-cols-4 place-items-center">
				<dd class="content-center mx-4"><img src="/img/Inrego_Logo.png" alt="Inrego" title="Inrego" width=220 /></dd>
				<dd class="content-center mx-4"><img src="/img/Global Resale_Logo.png" alt="Global Resale" title="Global Resale" width=220 /></dd>
				<dd class="content-center mx-4"><img src="/img/FlexIT_Logo.png" alt="Flex IT" title="Flex IT" width=220 /></dd>
				<dd class="content-center mx-4"><img src="/img/LedMax_Logo.png" alt="Ledmax" title="Ledmax" width=220 /></dd>
			</div>

			<div class="grid grid-cols-1 gap-8 md:gap-0 md:grid-cols-2 my-8 place-items-center">
				<div class="relative grid grid-cols-1 w-3/4 rounded-lg p-4 pl-16 text-sm shadow-lg overflow-visible max-w-none flex bg-white mt-2">
					<div class="absolute left-0 top-10 -ml-12">
						<img src="/img/Rod_Neale.png" alt="Rod Neale - A2C Services" title="Rod Neale - A2C Services" width=100 />
					</div>
					<div>We have been members since 1999 and for IT Related Trading it is the only B2B site that gives us total coverage. TBS is a tool that is worth paying for!</div>
					<div class="w-16 h-px mt-2 mb-1 bg-gray-400"></div>
					<div class="font-extrabold">Rod Neale</div>
					<div class="">A2C Services</div>
				</div>
				
				<div class="relative grid grid-cols-1 w-3/4 rounded-lg p-4 pl-16 text-sm shadow-lg overflow-visible max-w-none flex bg-white mt-2">
					<div class="absolute left-0 top-10 -ml-12">
						<img src="/img/Ghapios_Garas.png" alt="Ghapios Garas - Simpatico Tech" title="Ghapios Garas - Simpatico Tech" width=100 />
					</div>
					<div>The Broker Site is very easy to use and easy to understand. Our company gained new clients that we may not have been able to access previously.</div>
					<div class="w-16 h-px mt-2 mb-1 bg-gray-400"></div>
					<div class="font-extrabold">Ghapios Garas</div>
					<div class="">Simpatico Tech</div>
				</div>
			</div>

			<!-- This might not have to stay once the page is done -->
			<div class="clear-both"></div>


				

				<modal v-show="showContractModal" @submit="showContractModal = false"
					:doSkipHeader="true"
					:doSkipCancel="true"
					customCssClasses="sm:max-w-6xl"
					affirmativeButtonLabel='Close'>
					<template v-slot:body>
						<ContractComponent></ContractComponent>
				  </template>
				</modal>

			</div>
		</template>
	</base-layout>
</template>

<script>

import _ from 'lodash'
import { mapGetters, mapActions, mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import axios from 'axios'
import eventBus from '../utils/eventBus'
import BaseLayout from '@/components/BaseLayout.vue'
import ContractComponent from '@/components/ContractComponent.vue'
import Modal from '@/components/modal.vue'
import captchaMixin from '@/components/mixins/captcha'

export default {
  name: 'FreeTrial',
	mixins: [captchaMixin],
  components: {
    BaseLayout,
    ValidationObserver,
    ValidationProvider,
    Modal,
    ContractComponent,
  },
  data: () => ({
  	showContractModal: false,
	isDone: false,
	isFirst: true,
	isSecond: false,
	isThird: false,
	isFinal: false,
	showThumbs: true,
  	payload: {
  		company: null, 
  		address: null,
  		city: null,
  		zip: null,
  		country: "", 
  		desc: "", 

  		name: null,
  		function: null,
  		email: null,
  		tel: null,
  		website: null,

  		vat: null,
  		hear: "", 
		password: null,

		isContractAgreed: false,
		isFromQrCode: false,
		}, 
  	// disableSubmit: true
  }),
  computed: {
  	...mapGetters(['countriesOptionsPleaseSelect']),
	...mapState(['route', 'user']),
  	next1Class(){
  		if(this.disableNext1) return "border-gray-400 bg-gray-400 cursor-not-allowed"
		return "bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red "
  	},
  	next2Class(){
  		if(this.disableNext2) return "border-gray-400 bg-gray-400 cursor-default"
		return "bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red "
  	},
  	submitClass(){
  		if(this.disableSubmit) return "border-gray-400 bg-gray-400 cursor-not-allowed"
		return "bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red "
  	},
  	hearOptions(){
		if (this.route.query.ref !== "qr") {
			return [
				{value: "", name: "None"},
				{value: "Google/Search", name: "Google or other search engine"},
				{value: "Facebook", name: "Facebook"},
				{value: "LinkedIn", name: "LinkedIn"},
				{value: "Business Relation", name: "Business Relation"},
				{value: "TBS Sales", name: "TBS Sales"},
				{value: "TBS Mailing", name: "TBS Mailing"},
			]
		}
		return [{value: "TBS QR Code", name: "TBS QR Code"}]
  	},
	disableNext1() {
		return !this.payload.company || !this.payload.address || !this.payload.country
	},
	disableNext2() {
		// nothing mandatory to fill up!
		return !this.payload.hear
		// return false;
	},
	disableSubmit(){
		return !this.payload.company || !this.payload.address || !this.payload.country || !this.payload.name || !this.payload.function || !this.payload.email || !this.payload.tel || !this.payload.isContractAgreed || !this.captchaResponse
	},
	vatLabel(){
		return this.payload.country === 'UK' ? "EORI" : "VAT"
	},
  },
  mounted() {
    this.focusInput();
  },
  methods: {
		...mapActions(["register"]),

    focusInput() {
      this.$refs.companyName.focus();
    },

  	async join(){
		if (this.disableSubmit) {
			this.$refs.registerForm3.validate()
			return
		}

		if (this.route.query.ref === "qr") {
			this.payload.hear = "TBS QR Code"
		}

		const payload = _.extend({captcha: this.captchaResponse}, this.payload)

		this.register(payload).then(resp => {
			if(resp.data.status !== 200) throw resp.data;
			this.isDone = true;
			this.isFirst  = false;
			this.isSecond = false;
			this.isThird = false;
			this.isFinal = true;
		}).catch(err => {
			eventBus.$emit("createSnackbar", {
				type: "error", 
				title: "Registration failed",
				body: err.payload.msg,
				timeoutInMS: 5000
			});
		})
  	},

	async rate(intRating) {
		// record the rating anonymously for now
		this.rateRegForm = (
			await axios.post(`/api/misc/rateRegForm/rating/${intRating}`, { rating: intRating, company: this.payload.company }).then(payload=> {
				console.log(payload.data, payload.headers)
				this.showThumbs = false
			})
		)
		// console.log("Company " + this.rateRegForm)
	},

	async whichStep(stepCount) {
		if (stepCount==1) {
			this.isFirst  = true
			this.isSecond = false
			this.isThird = false
			this.isFinal  = false
			this.captchaResponse = false
		} else if (stepCount==2) {
			if (this.disableNext1) {
				this.$refs.registerForm1.validate()
				return
			}
			this.isFirst  = false
			this.isSecond = true
			this.isThird = false
			this.isFinal  = false
			this.captchaResponse = false
		} else if (stepCount==3) {
			this.isFirst  = false
			this.isSecond = false
			this.isThird = true
			this.isFinal  = false
			this.captchaResponse = false
		}
	}
  }
}
</script>
