<template>
	<div>
		<div class='mb-4'>
			<h3 class='text-red-700 font-medium'>Send emails only to the related Broadcast list</h3>
			Please send your broadcasts to the related list only. For example do not send Server broadcasts to the PC broadcast.
		</div>
		<div class='mb-4'>
			<h3 class='text-red-700 font-medium'>Only specific WTB and WTS emails related to IT products</h3>
			Please use our broadcasts for specific WTB WTS emails only. Do not use the TBS Broadcasts for greetings, wishes or any other emails not related to IT products.
		</div>
		<div class='mb-4'>
			<h3 class='text-red-700 font-medium'>Maximum five (5) broadcasts per list per day</h3>
			You are allowed to send 5 broadcasts to each list every day, more broadcasts will be rejected to keep our members mailboxes from overflowing.
		</div>
		<div class='mb-4'>
			<h3 class='text-red-700 font-medium'>Maximum five (5) broadcasts at the same time</h3>
			You are not allowed to send more than 5 broadcasts at the same time, please wait 30 minutes after sending 5 broadcasts.
		</div>
		<div class='mb-4'>
			<h3 class='text-red-700 font-medium'>Maximum twenty (20) broadcasts per day</h3>
			You are not allowed to send more than 20 broadcasts per day.
		</div>
		<div class='mb-4'>
			<h3 class='text-red-700 font-medium'>You can only send your broadcasts once per week</h3>
			You are not allowed to send the same broadcasts each day, when your broadcast is not successful try it again the next week not the next day
		</div>
		<div class='mb-4'>
			<h3 class='text-red-700 font-medium'>Please send PLAIN TEXT messages only</h3>
			If messages are sent in the HTML format, they will not be broadcast. Please consult your technical contact on how to send plain text messages only.
		</div>
		<div class='mb-4'>
			<h3 class='text-red-700 font-medium'>No attachments</h3>
			Attachments often contain viruses. Broadcasts with attachments will be rejected to keep the list absolutely virus free.
		</div>
	</div>
</template>

<script>

export default {
  name: 'BroadcastRulesBody',
}
</script>
