<template>
	<base-layout>
		<template v-slot:header>
			Favorites
		</template>
		<template v-slot:main>
			<div>

				<p class='py-4'>
				Below is a list of TBS Companies that you have marked as your favorites.<br/>
				You currently have <strong>{{companies.length}}</strong> favorites.
				</p>

				<!-- Table -->
				<div class="mt-4 flex flex-col hidden md:block relative">
					<div v-if='showDropdownBool' v-on-clickaway="hideDropdown" class="origin-top-right absolute right-0 mt-20 w-72 rounded-md shadow-lg z-10" ref='menuDropdown'>
						<div class="rounded-md bg-white shadow-xs">
							<div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
								<a href="javascript:void(0)" @click='showBroadcasts' class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">View Broadcasts</a>
								<a href="javascript:void(0)" @click.stop="visitFavorite" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 border-b" role="menuitem">Go to Company Profile</a>
								<a href="javascript:void(0)" @click.stop="delFavorite" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Remove from Favorites</a>
							</div>
						</div>
					</div>

					<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
						<div v-if='companies.length' class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
							<table class="min-w-full">
								<thead>
									<tr>
										<th class="table-cell-header pr-0 pl-3 cursor-pointer"><!-- emblem gold/trusted/diamond --></th>
										<th class="table-cell-header pl-0 hover:text-red-700 cursor-pointer" @click='sort("companyname")'>Company Name</th>
										<th class="table-cell-header hover:text-red-700 cursor-pointer" @click='sort("Country")'>Country</th>
										<th class="table-cell-header hover:text-red-700 cursor-pointer" @click='sort("dateFaved")'>Date Added to Favorites</th>
										<th class="table-cell-header hover:text-red-700 cursor-pointer"></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in companies" :key="index" class="bg-white hover:bg-red-50 cursor-pointer transition duration-150 ease-in-out hover-higlight-anchor" :class='{"": index % 2}'>
										<td class="table-cell text-gray-900 pr-0 pl-3" @click='showCompany(item)'> 
											<div v-if="item.trusted==4" class='member_diamond' title="This company is a 'Diamond Member'. Diamond members have been on www.TheBrokerSite.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
											<div v-if="item.trusted==2" class='member_gold' title="This company is a 'Gold Member'. Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
											<div v-if="item.trusted==1" class='member_trusted' title="This company is a 'Trusted Member'. Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
										</td>
										<td class="table-cell text-gray-900 pl-0" @click.stop='fetchCompanyAndDisplayModal(item.id)'> 
											<span :title='item.name' class='inline-block font-semibold truncate hover-higlight max-w-48 align-middle'>{{item.name}}</span>
										</td>
										<td class="table-cell text-gray-500">{{item.country}}</td>
										<td class="table-cell text-gray-900" :title='item.dateFavedLong'>
											{{item.dateFaved}}
										</td>
										<td class="table-cell text-gray-500 relative text-right">
											<button @click='confirmDelFavorite(item)' type="button" class="w-28 inline-block text-sm text-center btn btn-muted text-red-700 border-red-700 hover:text-red-700 sm:mt-0 mt-3 py-1 px-2">
													<div class='grid grid-cols-3 gap-0' title="Remove this company from your favorites.">
													<div class='favorite_on cursor-pointer'></div>
													<div class=''>Favorite</div>
												</div>
											</button>

											<!-- <span title='Remove this company from your favorites'>
											<button type="button" @click.stop='showDropdown(item, index, false)' class="absolute top-2 right-1">
												<svg width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="transform transition-transform duration-500 ease-in-out">
													<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
												</svg>
											</button>
											</span> -->
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div class="mt-12 flex flex-col block md:hidden">
					<div v-if='showDropdownBool' v-on-clickaway="hideDropdown" class="origin-top-right absolute right-6 mt-20 w-64 rounded-md shadow-lg z-10" ref='menuDropdownMobile'>
						<div class="rounded-md bg-white shadow-xs">
							<div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
								<a href="javascript:void(0)" @click='showBroadcasts' class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">View Broadcasts</a>
								<a href="javascript:void(0)" @click.stop="visitFavorite" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 border-b" role="menuitem">Go to Company Profile</a>
								<a href="javascript:void(0)" @click.stop="delFavorite" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Remove from Favorites</a>
							</div>
						</div>
					</div>
					<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
						<div v-if='companies.length' class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
							<table class="w-full table-fixed">
								<thead>
									<tr>
										<th class="table-cell-header pr-0 pl-3 ">Companies</th>
										<th class="table-cell-header pr-0 pl-3 "></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in companies" :key="index" class="bg-white hover:bg-red-50 cursor-pointer transition duration-150 ease-in-out hover-higlight-anchor" :class='{"": index % 2}'>
										<td class="table-cell  pr-0 pl-3 "> 
											<div class='truncate' @click.stop='fetchCompanyAndDisplayModal(item.id)'>
												<div v-if="item.isDiamond" class='float-left mr-2 member_diamond' title="This company is a 'Diamond Member'. Diamond members have been on www.TheBrokerSite.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
												<div v-if="item.isGold" class='float-left mr-2 member_gold' title="This company is a 'Gold Member'. Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
												<div v-if="item.isTrusted" class='float-left mr-2 member_trusted' title="This company is a 'Trusted Member'. Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
												<span class='text-red-700 font-semibold'>{{item.name}}</span>
											</div>
											<div class="truncate">{{item.description}}</div>
											<div class="truncate text-gray-500">{{item.country}}</div>
										</td>
										<td class="table-cell pr-2 pl-3 text-right">
											<button @click='confirmDelFavorite(item)' type="button" class="w-8 inline-block text-sm text-center btn btn-muted text-red-700 hover:text-red-700 sm:mt-0 mt-3 py-1 px-1">
													<div class='grid grid-cols-3 gap-0' title="Remove this company from your favorites.">
													<div class='favorite_on cursor-pointer'></div>
												</div>
											</button>
											<!-- <span title='Remove this company from your favorites'>
											<button type="button" @click.stop='showDropdown(item, index, true)' class="float-right -ml-px relative inline-flex items-center pl-2 pr-1 py-2 rounded-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
												<svg width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="transform transition-transform duration-500 ease-in-out">
													<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path>
												</svg>
											</button>
											</span> -->
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<modal v-if='user' v-show="showCompanyModal" @submit="closeCompanyModal" @cancel="closeCompanyModal"
					:doSkipHeader="true"
					:doSkipCancel="true"
					customCssClasses="sm:max-w-6xl"
					affirmativeButtonLabel='Close'>
					<template v-slot:body>
						<CompanyBody :isInModal="true" :selectedCompanyEnriched='selectedCompany' :contactsEnriched="selectedCompanyContacts"></CompanyBody>
				</template>
				</modal>

				<modal v-if='selectedItem' v-show="showModal" @submit="closeModal" @cancel="closeModal"
					:doSkipHeader="true"
					:doSkipCancel="true"
					customCssClasses="sm:max-w-2xl"
					affirmativeButtonLabel='Close'>
					<template v-slot:body>
						<div>

							<div v-if='selectedItem.trusted === 1'>
								<strong>{{selectedItem.name}} is a Trusted Member of The Broker Site</strong>

								<p class='py-4'>
								{{selectedItem.name}} has earned the TBS Trusted Members Sign as they have been on TBS for 3 or more years, we have no unresolved complaints listed against them and they offer a 1 month return to base warranty.
								</p>
							</div>

							<div v-else-if='selectedItem.trusted === 2'>
								<strong>{{selectedItem.name}} is a Gold Member of The Broker Site</strong>

								<p class='py-4'>
								{{selectedItem.name}} has earned the TBS Gold Members Sign as they have been on TBS for 10 or more years, we have no unresolved complaints listed against them and they offer a 1 month return to base warranty.
								</p>
							</div>

							<div v-else-if='selectedItem.trusted === 4'>
								<strong>{{selectedItem.name}} is a Diamond Member of The Broker Site</strong>

								<p class='py-4'>
								{{selectedItem.name}} has earned the TBS Diamond Members Sign as they have been on TBS for 20 or more years, we have no unresolved complaints listed against them and they offer a 1 month return to base warranty.
								</p>
							</div>

							<p class='py-4'>
								Please feel free to <a class='text-red-600 hover:underline' href='javascript:void(0)' @click='navContact'>contact us</a> or give us a call on <a class='text-red-600 hover:underline' href='tel:+31621546725'>+31 621546725</a> if you want to know more about {{selectedItem.name}}.
							</p>
							<p class='py-4'>
							Please visit the <a class='text-red-600 hover:underline' :href='"/companies/" + selectedItem.id' target="_blank" rel="noopener">TBS Profile of {{selectedItem.name}}</a> for contact information.
							</p>

						</div>
				  </template>
				</modal>

				<modal v-if='selectedItem' v-show="showConfirmModal" @submit="delFavorite" @cancel="closeConfirmModal"
					:doSkipHeader="true"
					customCssClasses="sm:max-w-2xl"
					affirmativeButtonLabel='Remove'>
					<template v-slot:body>
						<div>
							<strong>Warning!</strong><br>
							You are about to remove {{selectedItem.name}} from your favorites.<br>
							Are you sure you want to do this?<br><br>
							<span class="text-xs italic text-gray-400">You can add them again later through their company profile.</span>
						</div>
				  </template>
				</modal>

			</div>
		</template>
	</base-layout>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapActions } from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'
import modal from '@/components/modal.vue'
import ListMixin from '@/components/mixins/List'
import eventBus from '../utils/eventBus'

export default {
  mixins: [ListMixin, clickaway],
  name: 'FavoriteMembers',
  components: {
    BaseLayout,
    modal,
  },
  data: () => ({
  	companies: [], 
  	showModal: false,
  	showConfirmModal: false,
  	selectedItem: null,
	payload: {
		query: null, 
		country: "",
		page: 1,
		orderCol: "",
		orderDesc: false,
		showSearch: false,
	},
	fetchActionName: "getFavoriteMembers",
	showDropdownBool: false,
  }),
  async created(){
  	this.companies = (await this.getFavoriteMembers()).payload.results
  },
  methods: {
  	...mapActions(["getFavoriteMembers", 'setAsFavorite']),
  	dateHuman(){
  		const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  		const dateString = new Date(this.selectedItem.sinceDT).toLocaleDateString("en", options)
  		return dateString.substring(dateString.indexOf(",") + 1); //remove weekday
  	},
  	showCompany(item){
  		this.showModal = true;
  		this.selectedItem = item;
  	},
  	closeModal(){
  		this.showModal = false;
  	},
	closeConfirmModal(){
  		this.showConfirmModal = false;
	},
  	navToSelectedCompany(){
  		this.$router.push({ name: 'companyDetail', params: { id: this.selectedItem.id } })
  	},
  	navContact(){
  		this.$router.push({ path: "/contact" })
  	},
	confirmDelFavorite(item) {
  		this.showConfirmModal = true;
		this.selectedItem = item
	},
	async delFavorite() {
		this.hideDropdown()
		this.closeConfirmModal()
		const favoriteDTO = {'companyId': this.selectedItem.id, 'byContactId': this.user.id, 'action': false};
		const resp = await this.setAsFavorite(favoriteDTO)

		if(resp.data.status !== 200){
			eventBus.$emit("createSnackbar", {
				type: "error", 
				title: "Something went wrong",
				body: "This Company was not removed as favorite. Please try again. <br/> If the problem persists please contact The Broker Site",
				timeoutInMS: 5000,
			})
		} else {
			eventBus.$emit("createSnackbar", {
				type: "success", 
				title: "This Company was removed from your Favorites",
			})
		}
		this.companies = (await this.getFavoriteMembers()).payload.results
	},
	visitFavorite() {
		this.hideDropdown()
		window.open('/companies/' + this.selectedItem.id)
	},
	showBroadcasts() {
		this.hideDropdown()
		window.open('/broadcasts?companyId=' + this.selectedItem.id)
	},
	fetchCompanyAndDisplayModal(id){
		this.getCompany(id).then(resp => {
			if(resp.status !== 200) throw new Error("TODO: not found. show error page")
			this.selectedCompany = resp.company;
			this.selectedCompanyContacts = resp.contacts;
			this.showCompanyModal = true;
		})
	},
	showDropdown(item, itemIndex, isMobile){
  		this.selectedItem = item;
		this.showDropdownBool = true
		this.$nextTick(() => { //hackish: need to render first before we have access to $refs
			let $dropDownEl;
			let increment;
			let fixedOffsetH;
			$dropDownEl = isMobile ? this.$refs.menuDropdownMobile : this.$refs.menuDropdown
			increment = isMobile ? 66 : 43
			fixedOffsetH = isMobile ? 16 : 0
			$dropDownEl.style.transform = `translateY(${fixedOffsetH + (itemIndex * increment)}px)`; //set position
		})
	},
	hideDropdown(){
		this.showDropdownBool = false
	}
  }
}
</script>

<style scoped>
	.member_trusted{
		width: 24px; 
		height: 24px;
		background: url("../assets/trusted_small.png");
		background-size: cover;
	}
	.member_gold{
		width: 24px; 
		height: 24px;
		background: url("../assets/gold_small.png");
		background-size: cover;
	}
	.member_diamond{
		width: 24px; 
		height: 24px;
		background: url("../assets/diamond_small.png");
		background-size: cover;
	}
	.map-wrapper{
		width: 100%; 
		height: 500px;
		transition: height 0.5s;
	}
	.map-wrapper-hidden{
		height: 0;
	}
	.favorite_off{
		width: 22px; 
		height: 22px;
		background: url("../assets/favorite_off.png");
		background-size: cover;
	}
	.favorite_on{
		width: 22px; 
		height: 22px;
		background: url("../assets/favorite_on.png");
		background-size: cover;
	}
</style>
