<template>
	<base-layout :withHeaderStructure="true">
		<template v-slot:header>
			<nav >
				<router-link :to="{name: 'companies', query: lastSearch.companies}" class="flex items-center text-sm leading-5 font-medium text-gray-400 hover:text-gray-200 focus:outline-none focus:underline transition duration-150 ease-in-out">
					<svg class="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
					<path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
					</svg>
					Back to companies
				</router-link>
			</nav>
			<div class="mt-1 mb-2 md:flex md:items-center md:justify-between">
				<h1 class="flex-1 min-w-0 font-bold mt-2 leading-7 text-gray-900 text-2xl sm:text-3xl leading-9 sm:truncate tracking-tight">{{selectedCompany.name}}</h1>
				<div class="mt-4 flex md:mt-0 md:ml-4">
					<div v-if='selectedCompany.picUrl' :style='selectedCompanyEnriched.picCSS' @click="navCompanyWebsite(selectedCompanyEnriched.website)" class="cursor-pointer"></div>
				</div>
			</div>
			<div class="mt-1 hidden sm:flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap hidden">
				<div v-if='selectedCompany.website && selectedCompany.website !== "-"' class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
					<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"  viewBox="0 0 20 20" fill="currentColor">
					  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"/>
					  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"/>
					</svg>
					<a class='hover:underline' :href="selectedCompany.website" target="_blank">{{selectedCompany.website}}</a>
				</div>
				<div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
					<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
					<path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"/>
					</svg>
					<a v-if='selectedCompanyEnriched.mapUrl' :href='selectedCompanyEnriched.mapUrl' target='_blank' class='hover:underline' >{{selectedCompany.country}}</a>
					<span v-else>{{selectedCompany.country}}</span>
				</div>
				<div v-if='user' class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
					<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
					  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"/>
					</svg>
					<a class='hover:underline' :href="selectedCompanyEnriched.telLink">{{selectedCompany.tel}}</a>
				</div>
			</div>

		</template>
		<template v-slot:main>
			<div >
				
				<CompanyBody :selectedCompanyEnriched='selectedCompanyEnriched' :contactsEnriched="contactsEnriched"></CompanyBody>
				
				<div v-if='doShowComplaintModal' class='fixed inset-0 z-10'>
					<transition name="modal-fade">
						<div class="fixed top-4 right-0 left-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10">
							<div class="fixed inset-0 transition-opacity opacity-100 ">
								<div class="absolute inset-0 0 opacity-75" @click='closeComplaintModal'></div>
							</div>
							<div class="modal-panel bg-white rounded-lg px-4 pt-4 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full lg:p-0 opacity-100 translate-y-0 scale-100 border sm:max-w-6xl" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
								<div > 
									<div class="mt-3 sm:mt-0 sm:ml-2 text-left ">
										<div class="modal-body-shrink">
											<p class="text-sm leading-5 text-gray-500">
												<div class="lg:flex w-full">
													<div class='lg:w-1/2 px-4 py-6 order-1'>
														<h1 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-2xl leading-9 lg:mt-4 mb-4 block">File Complaint</h1>
														<input type="text" v-model="complaintDTO.complaintTitle" class="form-input block w-full mt-4 mb-4" placeholder="Title of this complaint">
														<input type="text" v-model="complaintDTO.transactionDate" class="form-input block w-full mt-4 mb-4" placeholder="Date of transaction (dd/mm/yyyy)">
														<textarea v-model='complaintDTO.body' rows="6" class="form-input block w-full" placeholder="Your brief but clear description here"></textarea>
														<input type="text" v-model="complaintDTO.resolution" class="form-input block w-full mt-4 mb-4" placeholder="What action needs to be taken to resolve this issue?">

														<div class='mt-8'>
															<label for="po_upload" class="relative cursor-pointer bg-white rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500">
																<span class="mr-2 inline-block text-sm text-center btn btn-muted text-red-700 border-red-700 hover:text-red-700">Upload PO</span>
																<span v-if='poFileName' class='inline-block'>{{poFileName}}</span>
																<input id="po_upload" name="po_upload" ref="po_upload" type="file" class="sr-only" v-on:change="handlePOUpload()">
															</label>
														</div>

														<div class='mt-4'>
															<label for="invoice_upload" class="relative cursor-pointer bg-white rounded-md font-medium text-red-600 hover:text-red-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500">
																<span class="mr-2 inline-block text-sm text-center btn btn-muted text-red-700 border-red-700 hover:text-red-700">Upload Invoice</span>
																<span v-if='invoiceFileName' class='inline-block'>{{invoiceFileName}}</span>
																<input id="invoice_upload" name="invoice_upload" ref="invoice_upload"  type="file" class="sr-only" v-on:change="handleInvoiceUpload()">
															</label>
														</div>

														<div class="mt-8 sm:flex sm:flex-row-reverse">
															<span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
																<button type="button" @click='submitComplaint' class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5" :class="submitComplaintClass">
																	File Complaint
																</button>
															</span>
															<span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
																<button @click='closeComplaintModal' type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
																	Cancel
																</button>
															</span>
														</div>

													</div>
													<div class='lg:w-1/2 ml-2 bg-red-100 py-8 px-8 order-2 text-red-800'>
														<h1 class="text-lg leading-8 font-extrabold text-gray-900 sm:text-xl leading-9 block mt-2">Instructions</h1>
														<ul class='list-disc mt-4 text-sm'>
															<li>Make sure to provide a brief but clear description of the complaint and upload your Purchase Order and Invoice. </li>
															<li>Make sure that the quality of the goods you are buying is always mentioned on the PO and Invoice. We do not accept complaints that are filed without a description, PO or Invoice. </li>
														</ul>
														<div class='mt-4 text-red-400 text-sm'>
															Please note that TBS doesn't mediate in cases with a value below 2000 Euro or where hardware is sold in "As Is" condition, please only buy "As Is" from companies you have a long term relationship with. 
															TBS does not mediate in Software cases! 
														</div>
													</div>
												</div>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</transition>
				</div>

			</div>
		</template>
	</base-layout>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import _ from 'lodash';

import BaseLayout from '@/components/BaseLayout.vue'
import CompanyBody from '@/components/CompanyBody.vue'
import Modal from '@/components/modal.vue'
import eventBus from '../utils/eventBus'

import store from '../store'

export default {
  name: 'CompanyDetail',
  components: {
    BaseLayout,
    CompanyBody,
		// Modal,
  },
  	beforeRouteEnter(to, from, next) {
		store.dispatch('getCompany', to.params.id).then(resp => {
			if(resp.status !== 200) throw new Error("TODO: not found. show error page")
			next(vm => {
				vm.selectedCompany = resp.company;
				vm.contacts = resp.contacts;
					document.title =  `${vm.selectedCompany.name} - The Broker Site`
				})
		})
	},
	data(){
		return {
			contacts:[],
			selectedCompany: {},
			doShowComplaintModal: false, 
			complaintDTO: {},
			poFileName: null,
			invoiceFileName: null,
		}
	},
	computed: {
		...mapState(["route", 'user','lastSearch']),
		selectedCompanyEnriched(){
			
			if(!this.selectedCompany || !this.selectedCompany.address || !this.selectedCompany.country) return this.selectedCompany;

			return _.extend({
				mapUrl: `https://www.google.com/maps/search/?api=1&query=${this.selectedCompany.address.split(" ").join("+")}+${this.selectedCompany.country.split(" ").join("+")}`,
			}, this.selectedCompany); //legacy reasons
		}, 
		contactsEnriched(){
			return this.contacts; //legacy reasons
		},
		isComplaintComplete(){
			return this.poFileName && this.invoiceFileName && this.complaintDTO.body && this.complaintDTO.complaintTitle && this.complaintDTO.transactionDate && this.complaintDTO.resolution
		},
		submitComplaintClass(){
			if(!this.isComplaintComplete){
				return "border-gray-400 bg-gray-400 cursor-default"
			} else {
				return "bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red "
			}
		},
	},
	methods: {
		...mapActions(['uploadComplaint']),
		closeComplaintModal(){
			this.doShowComplaintModal = false; 
		},
		submitComplaint(){
			if(!this.isComplaintComplete) return 
			this.complaintDTO.po = this.$refs.po_upload.files[0];
			this.complaintDTO.invoice = this.$refs.invoice_upload.files[0];

			const formData = new FormData();
			_.each(this.complaintDTO, (val, propName) => {
				formData.append(propName,val);
			})
			
			this.uploadComplaint({companyId: this.selectedCompany.id, formData})
			.then(() => {
				this.closeComplaintModal()
				eventBus.$emit("createSnackbar", {
   				type: "success", 
   				title: "Complaint sent",
          body: "Complaint was succesfully sent.",
   				timeoutInMS: window.tbs.isTestMode ? 10000 : undefined,
   			})

			}).catch(err => {
				 eventBus.$emit("createSnackbar", {
          type: "error", 
          title: "Something went wrong",
          body: "Sending a complaint failed. Please try again. <br/> If the problem persists please contact The Broker Site",
          timeoutInMS: 5000,
        })
			})
		},
		openComplaintModal(){
			this.doShowComplaintModal = true
			this.complaintDTO = {
				body: null,
				invoice: null, 
				po: null,
			}
			this.poFileName = null;
			this.invoiceFileName = null;
		},
		navToParts(){
			this.$router.push({ name: 'parts', query: { companyId: this.selectedCompany.id } })
		},	
		handlePOUpload(){
			let v = document.getElementById("po_upload").value
			if(~v.lastIndexOf('/')){
				v = v.substring(v.lastIndexOf('/')+1)
			}
			if(~v.lastIndexOf('\\')){
				v = v.substring(v.lastIndexOf('\\')+1)
			}
			this.poFileName = v
		},
		handleInvoiceUpload(){
			let v = document.getElementById("invoice_upload").value
			if(~v.lastIndexOf('/')){
				v = v.substring(v.lastIndexOf('/')+1)
			}
			if(~v.lastIndexOf('\\')){
				v = v.substring(v.lastIndexOf('\\')+1)
			}
			this.invoiceFileName = v
		},
		navCompanyWebsite(strUrl) {
			const a = document.createElement('a');
			a.href = strUrl;
			a.target = '_blank';
			a.rel = 'noopener';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		},

	}
}
</script>
