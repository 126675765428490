<template>
	<base-layout :withHeaderStructure="true">
		<template v-slot:header>
			<img id='logoImage' :src='selectedCompany.picUrl' class='hidden' @load="doesImageExist = true" @error="doesImageExist = false">
			<div class="mt-1 mb-2 md:flex md:items-center md:justify-between">
				<h1 class="flex-1 min-w-0 font-bold mt-2 leading-7 text-gray-900 text-2xl sm:text-3xl leading-9 sm:truncate tracking-tight">{{selectedCompany.name}}</h1>
				<div v-if='selectedCompany.username' class="mt-4 flex md:mt-0 md:ml-4">
					<div v-if='doesImageExist' class='picContainer relative'>
						<div :style='selectedCompanyEnriched.picCSS'></div> 
						<div class="absolute bottom-0 right-0 sm:right-2 -mb-11 text-sm text-red-700">
							<a href='javascript:void(0)' @click='showProfilePicModal = true' class='inline-block mr-4 hover:underline'>Upload New Logo</a>
							<a href='javascript:void(0)' @click='showConfirmDeleteLogo = true' class='inline-block hover:underline'>Remove Logo</a>
						</div>
					</div>
					<div v-else>
						<button type="button" @click='showProfilePicModal = true' class="mt-4 ml-3 btn btn-muted cursor-pointer" style='margin-bottom: -20px'>Add your logo here </button>
					</div>
				</div>
			</div>

			<div class="block h-12 md:hidden"></div>
			
			<div class="mt-1 hidden sm:flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap hidden">
				<div v-if='selectedCompany.website && selectedCompany.website !== "-"' class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
					<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"  viewBox="0 0 20 20" fill="currentColor">
					  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"/>
					  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"/>
					</svg>
					<a class='hover:underline' :href="selectedCompany.website" target="_blank">{{selectedCompany.website}}</a>
				</div>
				<div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
					<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
					<path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"/>
					</svg>
					{{selectedCompany.country}}
				</div>
				<div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mr-6">
					<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
					  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"/>
					</svg>
					<a class='hover:underline' :href="selectedCompanyEnriched.telLink">{{selectedCompany.tel}}</a>
				</div>
			</div>

			<modal v-show="showProfilePicModal" @submit="uploadLogoAndCloseModal" @cancel="showProfilePicModal = false"
				header="Upload your profile logo" 
				:disableSubmit="!hasImage"
				affirmativeButtonLabel='Upload logo'>
				<template v-slot:body>
					<div class='mt-8'>
						<image-uploader
							:debug="1"
							:maxWidth="240"
							:maxHeight="60"
							:preview=true
							:className="['fileinput', { 'fileinput--loaded' : hasImage }]"
							accept=".jpg, .png"
							@input="setImage"
						></image-uploader>
					</div>
				</template>
			</modal>

			<modal v-show="showConfirmDeleteLogo" @submit="removeLogoAndCloseModal()" @cancel="showConfirmDeleteLogo = false"
				header="Confirm delete" 
				affirmativeButtonLabel='Yes, Delete'>
				<template v-slot:body>
					<div>Are you sure you want to delete this logo?</div>
				</template>
			</modal>


		</template>
		<template v-slot:main>
			<div >
				
				<CompanyBody :selectedCompanyEnriched='selectedCompanyEnriched' :contactsEnriched="contactsEnriched" :isEditMode="true"></CompanyBody>

			</div>
		</template>
	</base-layout>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import _ from 'lodash';


import BaseLayout from '@/components/BaseLayout.vue'
import Modal from '@/components/modal.vue'
import CompanyBody from '@/components/CompanyBody.vue'
import store from '../store'
import eventBus from '../utils/eventBus'
import storeUtils from '../store/utils'

export default {
  name: 'CompanyDetail',
  components: {
    BaseLayout,
    Modal,
    CompanyBody,
  },
  beforeRouteEnter(to, from, next) {
  	store.dispatch('getProfile').then(resp => {
  		if(resp.status !== 200) return next("/")
  		next(vm => {
  			vm.selectedCompany = resp.company;
  			vm.contacts = resp.contacts;
			})
  	})
  },
  data(){
		return {
			showProfilePicModal: false,
			showConfirmDeleteLogo: false,
			logoBase64: null,
			contacts:[],
			selectedCompany: {},
			doesImageExist: false, //currently exists on server?
			hasImage: false, //selected in image upload widget?
		}
	},
	computed: {
		selectedCompanyEnriched(){
			return this.selectedCompany; //legacy reasons
		}, 
		contactsEnriched(){
			return this.contacts; //legacy reasons
		},
		...mapState(["route", 'user'])
	},
	created(){
		eventBus.$on("updateProfile", (data) => {
			const resp = storeUtils.enrichProfile(data)
			this.selectedCompany = resp.company;
  		this.contacts = resp.contacts;
		})
	},
	methods: {
		...mapActions(["uploadLogo", 'removeLogo']),
		doesProfileImageExist(){
			const http = new XMLHttpRequest();
			http.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
			http.open('HEAD', this.selectedCompany.picUrl, false);
			http.send();
			return http.status != 404;
		},
		setImage(logoBase64) {
			this.hasImage = true
			this.logoBase64 = logoBase64
		},
		async removeLogoAndCloseModal(){
			try{
				const resp = await this.removeLogo()
				if(resp.data.status !== 200) throw resp
			} catch(err){
				eventBus.$emit("createSnackbar", {
   				type: "error", 
   				title: "Logo could not be deleted",
   				body: "Please try again. If this occur keeps occuring, please contact The Broker Site",
   				timeoutInMS: 5000,
   			})
			}
			this.showConfirmDeleteLogo = false
			this.$router.go(this.$router.currentRoute)
		},
		async uploadLogoAndCloseModal(){
			try{
				const resp = await this.uploadLogo({ logo: this.logoBase64 })
				if(resp.data.status !== 200) throw resp
			} catch(err){
				eventBus.$emit("createSnackbar", {
   				type: "error", 
   				title: "Logo could not be uploaded",
   				body: "Please try again. If this occur keeps occurring, please contact The Broker Site",
   				timeoutInMS: 5000,
   			})
			}
			// TODO: refresh? // Fetch: url('https://www.thebrokersite.com/images/logos/${data.result.username}.jpg?<random?> somehow
			this.closeUploadLogoModal()
			this.$router.go(this.$router.currentRoute)
		},
		closeUploadLogoModal(){
			this.logoBase64 = null;
			this.showProfilePicModal = false
		}
	}
}
</script>