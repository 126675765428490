<template>
	<base-layout>
		<template v-slot:header>Broadcast Settings</template>
		<template v-slot:main>
			<BroadcastSettings :doShowOnSeperatePage='true'></BroadcastSettings>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import BroadcastSettings from '@/components/BroadcastSettings.vue'

export default {
  name: 'BroadcastSettingsPage',
  components: {
    BaseLayout,
    BroadcastSettings,
  }
}
</script>
