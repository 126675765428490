<template>
	<base-layout>
		<template v-slot:header>
			Companies
		</template>
		<template v-slot:main>
			<div >
				<!-- Search form --> 
				<form @submit.self.prevent="doSearchAndMap">
					<div class="grid grid-cols-12 gap-6">
						<div class="col-span-12 sm:col-span-6 lg:col-span-5">
							<label for="cSearch" class="form-label block truncate">Search Companies, Contact Persons or Specialty</label>
							<input v-model="tempQuery" id="cSearch" class="form-input mt-1 block w-full" placeholder="E.g.: HP/IBM or Laptop"/>
						</div>

						<div class="col-span-12 sm:col-span-6 lg:col-span-5">
							<label for="cCountry" class="form-label block">Country</label>
							<select v-model="payload.country" id="cCountry" class="form-select mt-1 block w-full">
								<option v-for="item in countriesOptions" :value="item.value" :key='item.value'>{{item.name}}</option>
							</select>
						</div>

						<div class="col-span-12 sm:col-span-12 lg:col-span-2">
							<button @click="doSearchAndMap" type="button" class="btn btn-primary float-right lg:float-left lg:mt-6">Search</button>
						</div>
					</div>
				</form>

				<div class='mt-6'>
					<div class='h-8'>
						<div class="text-red-700 text-sm float-left">
							<router-link v-if='user' to="/favorites" class="mr-2">Your Favorites ({{favoritesCount()}})</router-link>
						</div>
						<div class="text-red-700 text-sm float-right">
							<div v-if="isMapShown">
								<button @click="resetMap" class='mr-2'>Reset Map</button>
								<button @click="isMapShown=false">Hide Map</button>
							</div>
							<div v-else>
								<button @click="isMapShown=true">Show Map</button>
							</div>
						</div>
					</div>
					<div v-if='isGoogleMapsLoaded'> <!-- v-show instead of v-if so map doesnt get confused -->
						<div class='map-wrapper overflow-hidden' :class="{'map-wrapper-hidden': !isMapShown}">
							<GmapMap ref="mapRef" :center="{ lat: 20, lng: 20 }" :zoom="2" :options="mapOptions" class='mt-4' style="width: 100%; height: 100%">
								<gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false"> </gmap-info-window>

								<!-- <GmapCluster> -->
									<GmapMarker
										v-for="(m, index) in markersEnriched"
										:key="index"
										:position="m.position"
										:clickable="true"
										@click="toggleInfoWindow(m,index)"
									/>
								<!-- </GmapCluster> -->
							</GmapMap>
						</div>
					</div>
				</div>

				<div v-if='payload.showSearch'>

					<div class='float-right hidden md:inline-block'>
						<select  v-model='pageSize' class="mt-1 mb-2 form-select pr-8">
							<option v-for='availPageSize in availPageSizes' :key='availPageSize.value' :value='availPageSize.value'>{{availPageSize.label}}</option>
						</select>
					</div>

					<div class='text-sm font-semibold leading-5 text-gray-700 mt-4 pt-3 -mb-10 clearfix'>{{numTraderText}}</div>

					<!-- Table -->
					<div class="mt-12 flex flex-col hidden md:block">
						<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
							<div v-if='nrCompanies' class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
								<table class="min-w-full">
									<thead>
										<tr>
											<th class="table-cell-header pr-0 pl-3 cursor-pointer"><!-- emblem gold/trusted/diamond --></th>
											<th class="table-cell-header pl-1 hover:text-red-700 cursor-pointer" @click='sort("companyname")'>Company Name</th>
											<th class="table-cell-header hover:text-red-700 cursor-pointer" @click='sort("Country")'>Country</th>
											<th class="table-cell-header hover:text-red-700 cursor-pointer" @click='sort("profile")'>Company Description</th>
											<th class="table-cell-header hover:text-red-700 cursor-pointer text-center" @click='sort("favCount")'>Favored</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in companiesEnriched" :key="index" @click='navToCompany(item.id, $event)' class="bg-white hover:bg-red-50 cursor-pointer transition duration-150 ease-in-out hover-higlight-anchor" :class='{"": index % 2}'>
											<td class="table-cell text-gray-900 pr-0 pl-3"> 
												<div v-if="item.isDiamond" class='member_diamond' title="This company is a 'Diamond Member'. Diamond members have been on www.TheBrokerSite.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
												<div v-if="item.isGold" class='member_gold' title="This company is a 'Gold Member'. Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
												<div v-if="item.isTrusted" class='member_trusted' title="This company is a 'Trusted Member'. Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
											</td>
											<td class="table-cell text-gray-900 pl-1"> 
												<span :title='item.name' class='inline-block font-semibold truncate hover-higlight max-w-48 align-middle'>{{item.name}}</span>
												<!-- <span v-if="item.isGold" title='Gold member' class="tag ml-2 bg-yellow-200 text-yellow-800">gold</span>
												<span v-if="item.isTrusted" title='Trusted member' class="tag ml-2 bg-gray-100 text-gray-800">trusted</span> -->
											</td>
											<td class="table-cell text-gray-500">{{item.country}}</td>
											<td class="table-cell text-gray-900"><div class="max-w-144 truncate">{{item.description}}</div></td>
											<td class="table-cell text-gray-500 text-center"><span v-if='item.favCount>0'>{{item.favCount}}</span></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="mt-12 flex flex-col block md:hidden">
						<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
							<div v-if='nrCompanies' class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
								<table class="w-full table-fixed">
									<thead>
										<tr>
											<th class="table-cell-header pr-0 pl-3 ">Companies</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in companiesEnriched" :key="index" @click='navToCompany(item.id)' class="bg-white hover:bg-red-50 cursor-pointer transition duration-150 ease-in-out hover-higlight-anchor" :class='{"": index % 2}'>
											<td class="table-cell  pr-0 pl-3 "> 
												<div class='truncate'>
													<div v-if="item.isDiamond" class='float-left mr-2 member_diamond' title="This company is a 'Diamond Member'. Diamond members have been on www.TheBrokerSite.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
													<div v-if="item.isGold" class='float-left mr-2 member_gold' title="This company is a 'Gold Member'. Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
													<div v-if="item.isTrusted" class='float-left mr-2 member_trusted' title="This company is a 'Trusted Member'. Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
													<span class='text-red-700 font-semibold'>{{item.name}}</span>
												</div>
												<div class="truncate">{{item.description}}</div>
												<div class="truncate text-gray-500">{{item.country}}</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<Pagination :itemList="companiesEnriched" :payload="payload" :pageSize="pageSize"></Pagination>
				</div>

			</div>
		</template>
	</base-layout>
</template>

<script>
// import { gmapApi } from 'vue2-google-maps'
import Vue from 'vue'
import BaseLayout from '@/components/BaseLayout.vue'
import ListMixin from '@/components/mixins/List'
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
	mixins: [ListMixin],
  	name: 'Companies',
  	components: {
    	BaseLayout,
  	},
  	data: () => ({
		isGoogleMapsLoaded: false,
		google: null,
		payload: {
			query: null, 
			country: "",
			page: 1,
			orderCol: "",
			orderDesc: false,
			showSearch: false,
		},
		fetchActionName: "getCompanies",
		numResultsName: "nrCompanies", //used in list-mixin
		mapOptions: {
			zoomControl: true,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: true,
			disableDefaultUI: false
		},
		isMapShown: true,

		// Infowindow stuff
		infoWindowPos: null,
		infoWinOpen: false,
		currentMidx: null,

		infoOptions: {
		content: '',
			//optional: offset infowindow so it visually sits nicely on top of our marker
			pixelOffset: {
				width: 0,
				height: -35
			}
		},
  	}),
	// Hack to load google maps
	beforeCreate: function () {

		const self = this
		function dynamicallyLoadComponents(){
			self.$options.components.GmapMap =  window.GmapVue.Map
			self.$options.components["gmap-info-window"] =  window.GmapVue.InfoWindow
			self.$options.components.GmapMarker =  window.GmapVue.Marker
			self.isGoogleMapsLoaded = true
		}
		//If google maps already loaded -> init
		if(window.GmapVue) return dynamicallyLoadComponents()

		// Load external script and call conditionallyInitGoogleMaps() when done
		const gmapScript = document.createElement('script')
		gmapScript.setAttribute('src', 'https://unpkg.com/gmap-vue@1.2.2/dist/gmap-vue.js')
		gmapScript.addEventListener('load', () => {
			
			Vue.use(window.GmapVue, { //VueGoogleMaps
				load: { key: window.tbs.googleApiKey },
				installComponents: false, //TODO: might optimize
			})
			dynamicallyLoadComponents();
		})
		document.head.appendChild(gmapScript)
	},
	created(){
		if(window.GmapVue){ //on subsequent loads
			this.isGoogleMapsLoaded = true
		}
	},
  async mounted () {
		const i = setInterval(() => {
			if(this.isGoogleMapsLoaded){
				clearInterval(i)
				this.doRenderMarkers = false;
				this.$refs.mapRef.$mapPromise.then(map => {
					this.mapUnderlying = map
					this.google = window.GmapVue.gmapApi()
					this.loadMap(); //deferred here. This makes sure this CPU-intensive task (bc. lots of markers) is done AFTER remainder of page is rendered
				})
			}
		})
  },
  computed: {
  	...mapState([ 'nrCompanies', 'nrTraders', 'nrCountries']),
		...mapGetters(['companiesEnriched', 'countriesOptions', 'markersEnriched']),
		numTraderText(){
			if(!this.isBootstrapped || this.isLoading) return "Loading..."
			if(!this.nrCompanies) return ``
			if(!this.payload.country) return `${this.nrTraders} ${this.nrTraders !== 1 ? "traders" : "trader"} from ${this.nrCompanies} ${this.nrCompanies !== 1 ? "companies" : "company"} from ${this.nrCountries} ${this.nrCountries !== 1 ? "different countries" : "country"}  found`
			return `${this.nrTraders} ${this.nrTraders !== 1 ? "traders" : "trader"} from ${this.nrCompanies} ${this.nrCompanies !== 1 ? "companies" : "company"} found`
		},
  },
  methods: {
  	...mapActions(["getCompanies", "getCountryCoords"]),
  	doSearchAndMap(){
  		this.payload.showSearch = true;
  		this.doSearch()
  		this.loadMap();
  		this.isMapShown = false; //animate away map on explicit search. (this doesn't happen on page load which may already include a search)
  	},
  	async loadMap(countryName = null){
  		const country = countryName || this.payload.country;
  		if(!country){
  			this.resetMap();
  			this.isMapShown = true;
  		} else{

  			// this.isMapShown = false;
				const countryCoords = await this.getCountryCoords(country)
				this.mapUnderlying.setCenter(countryCoords.payload.location);
				const bounds = new this.google.maps.LatLngBounds(countryCoords.payload.viewport.southwest,countryCoords.payload.viewport.northeast)
				this.mapUnderlying.fitBounds(bounds);
				
  		}
  	},
  	navToCompany(id){
			if(event.ctrlKey || event.metaKey) return window.open(this.$router.resolve({ name: 'companyDetail', params: { id } }).href, '_blank');
			this.$router.push({ name: 'companyDetail', params: { id } })
		},
		resetMap(){
			if(!this.isGoogleMapsLoaded) return
			this.payload.country = '';
			this.mapUnderlying.setCenter({lat: 20, lng: 20});
			this.mapUnderlying.setZoom(2);
		},
		toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;

      }
    },
    sort(orderCol){
		if(this.payload.orderCol === orderCol){
			this.payload.orderDesc = !this.payload.orderDesc
		} else {
			this.payload.orderCol = orderCol
		}
		this.doSearch();
	},
	favoritesCount() {
		if (this.user) {
			if (this.user.favCompanies.length===1) {
				return this.user.favCompanies[0]==0 ? this.user.favCompanies[0] : this.user.favCompanies.length
			}
			return this.user.favCompanies.length
		}
	}
  },
  watch: {
  	"payload.country"(country){
  		this.loadMap(country);
  	}
  }
}
</script>
<style scoped>
	.member_trusted{
		width: 24px; 
		height: 24px;
		background: url("../assets/trusted_small.png");
		background-size: cover;
	}
	.member_gold{
		width: 24px; 
		height: 24px;
		background: url("../assets/gold_small.png");
		background-size: cover;
	}
	.member_diamond{
		width: 24px; 
		height: 24px;
		background: url("../assets/diamond_small.png");
		background-size: cover;
	}
	.map-wrapper{
		width: 100%; 
		height: 500px;
		transition: height 0.5s;
	}
	.map-wrapper-hidden{
		height: 0;
	}
</style>
