<template>
	<base-layout>
		<template v-slot:header>
			Gold Members
		</template>
		<template v-slot:main>
			<div>

				<p class='py-4'>
				Gold Members have been on www.TheBrokerSite.com for <strong>10</strong> or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.<br/>
				Our current listing of <strong>{{companies.length}}</strong> TBS Gold Members:
				</p>

				<a v-for='item in companies' href='javascript:void(0)' :key='item.id' @click='showCompany(item)' class='text-sm block text-red-600 hover:underline'>
					{{item.name}}
				</a>

				<modal v-if='selectedItem' v-show="showModal" @submit="closeModal" @cancel="closeModal"
					:doSkipHeader="true"
					:doSkipCancel="true"
					customCssClasses="sm:max-w-2xl"
					affirmativeButtonLabel='Close'>
					<template v-slot:body>
						<div>

							<strong>{{selectedItem.name}} is a Gold Member of The Broker Site</strong>

							<p class='py-4'>
							{{selectedItem.name}} has been on www.TheBrokerSite.com since {{dateHuman()}} and has earned the TBS Gold Members Sign as they have been on TBS for 10 or more years, we have no unresolved complaints listed against them and they offer a 1 month return to base warranty. 
							</p>
							<p class='py-4'>
								Please feel free to <a class='text-red-600 hover:underline' href='javascript:void(0)' @click='navContact'>contact us</a> or give us a call on <a class='text-red-600 hover:underline' href='tel:+31621546725'>+31 621546725</a> if you want to know more about {{selectedItem.name}}.
							</p>
							<p class='py-4'>
							Please visit the <a class='text-red-600 hover:underline' href='javascript:void(0)' @click='navToSelectedCompany'>TBS Profile of {{selectedItem.name}}</a> for contact information.
							</p>
							
						</div>
				  </template>
				</modal>

			</div>
		</template>
	</base-layout>
</template>

<script>
import { mapActions } from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'
import modal from '@/components/modal.vue'

export default {
  name: 'GoldMembers',
  components: {
    BaseLayout,
    modal,
  },
  data: () => ({
  	companies: [], 
  	showModal: false,
  	selectedItem: null,
  }),
  async created(){
  	this.companies = (await this.getGoldMembers()).payload.results
  },
  methods: {
  	...mapActions(["getGoldMembers"]),
  	dateHuman(){
  		const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  		const dateString = new Date(this.selectedItem.sinceDT).toLocaleDateString("en", options)
  		return dateString.substring(dateString.indexOf(",") + 1); //remove weekday
  	},
  	showCompany(item){
  		this.showModal = true;
  		this.selectedItem = item;
  	},
  	closeModal(){
  		this.showModal = false;
  	},
  	navToSelectedCompany(){
  		this.$router.push({ name: 'companyDetail', params: { id: this.selectedItem.id } })
  	},
  	navContact(){
  		this.$router.push({ path: "/contact" })
  	},
  }
}
</script>