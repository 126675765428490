import _ from "lodash";

import Pagination from '../pagination';
import { mapActions, mapState} from 'vuex';
import Modal from '@/components/modal.vue'
import CompanyBody from '@/components/CompanyBody.vue'

// For partsearch multi
const extraFields = ['tempQuery', 'tempQuery2', 'tempQuery3', 'tempQuery4', 'tempQuery5'];

export default {
  props: {
    broadcastsExplicit: Array
  },  
  components: {
    Pagination,
    Modal,
    CompanyBody,
  },
  data: () => ({
    isBootstrapped: false,
    isLoading: false,
    payloadDefaults: {},
    tempQuery: "",

    // Show company in modal
    selectedCompanyContacts:[],
    selectedCompany: {},
    showCompanyModal: false,
  }),
  created() {
    _.each(this.payload, (v, k) => {
      this.$set(this.payloadDefaults, k, v);
    })
    if(this.broadcastsExplicit) return
    this.load()
  },
  watch: {
    // Route changes with different query params -> reload
    '$route.params'() {
      if(this.broadcastsExplicit) return
      this.load();
    }
  },
  computed: {
    ...mapState(['route', 'user']),
    availPageSizes(){
      return [{
        value: 10, 
        label: "10 results per page",
      },{
        value: 20, 
        label: "20 results per page",
      },{
        value: 50, 
        label: "50 results per page",
      },{
        value: 100, 
        label: "100 results per page",
      }]
    },
    paginationConfig(){

      const curPage = +this.payload.page;
      const numPages = Math.ceil(this.numResults / this.pageSize);
      if(numPages <= 7 ){
        return  _.map(_.times(numPages), pageNum => ({ num: pageNum + 1, isActive: pageNum + 1 === curPage }));
      }

      const pageItems = []
      if(curPage <= 4 && curPage !== 0){
        pageItems.push(..._.map(_.times(curPage - 1), pageNum => ({num: pageNum + 1})));
      } else if(curPage > 4){
        pageItems.push({ num: 1})
        pageItems.push({ isSpacer: true})
        pageItems.push({ num: curPage - 2}) // -2
        pageItems.push({ num: curPage -1 }) // -1
      }
      pageItems.push({ num: curPage, isActive: true}) 

      if(curPage >= numPages - 3 && curPage !== numPages){
        pageItems.push(..._.map(_.times(numPages - curPage), pageNum => ({num: curPage + pageNum + 1})));
      } else if(curPage < numPages - 3){
        pageItems.push({ num: curPage + 1}) 
        pageItems.push({ num: curPage + 2}) 
        pageItems.push({ isSpacer: true})
        pageItems.push({ num: numPages})

      }

      return pageItems;
    },
    numResults(){
      return this[this.numResultsName];
    },
    pageSize: {
			get: function (){
				return this.$store.state.pageSize
			}, 
			set: function (pageSize){
				this.setPageSize(+pageSize)
				this.load(); //reload given new pageSize
			}
		},
  },
  methods: {
    ...mapActions(["getCompany", 'setPageSize']),

    fetchCompanyAndDisplayModal(id){
      this.getCompany(id).then(resp => {
        if(resp.status !== 200) throw new Error("TODO: not found. show error page")
        this.selectedCompany = resp.company;
        this.selectedCompanyContacts = resp.contacts;
        this.showCompanyModal = true;
      })
    },
    closeCompanyModal(){
      this.showCompanyModal = false;
    },
    navToCompany(item, skipIfNotLoggedIn){
      if(skipIfNotLoggedIn && !this.user) return;
      // console.log(item)
      if(this.isPossibleMatches || !this.user) return window.open(this.$router.resolve({path: `/companies/${item.companyId}`}).href, '_blank'); // brian added !this.user, so that anonymous users can still view limited company details
      this.fetchCompanyAndDisplayModal(item.companyId);
    },
    pageTo(page){
      if(page === "previous"){
        this.payload.page--;
      } else if(page === "next"){
       this.payload.page++;
      } else {
        this.payload.page = +page;
      }
      this.doSearch();
    },
    load(){

      // Used by news
      if(this.route.params.id) return this.loadDetail(this.route.params.id)

      this.route.query.query = (this.route.query.query || "").trim().replaceAll('"', ''); // Use trim to prevent single space searches, use replaceAll as a hack to prevent " used in elasticsearch

      // Don't load anything if query not at least 2 chars
      // For broadcasts this is allowed
      // if(!~["broadcasts", "home", 'inventoryHits', 'news', 'companies'].indexOf(this.route.name)  && this.route.query.query.length < 2) return; 
      
      this.tempQuery = this.route.query.query
      this.tempQuery2 = this.route.query.query2
      this.tempQuery3 = this.route.query.query3
      this.tempQuery4 = this.route.query.query4
      this.tempQuery5 = this.route.query.query5
      
      // Multiple terms -> show advanced search
      this.doShowAdvanced = _.compact([this.tempQuery, this.tempQuery2, this.tempQuery3, this.tempQuery4, this.tempQuery5]).length > 1;

      _.each(this.payload, (v, k) => {
        this.payload[k] = this.route.query[k] ? this.route.query[k] : this.payloadDefaults[k];
      })

      // Fix pageSize to 5 for broadcasts when on homepage
      if(this.$route.name === 'home'){
        if (!this.user) {
          this.payload.pageSize = 10;
        } else {
          this.payload.pageSize = 5;
        }
      }

      this.isLoading = true;
      this[this.fetchActionName](_.extend({pageSize: this.pageSize}, this.payload)).then(() => {
        this.isBootstrapped = true; 
        this.isLoading = false;
      })

      // If broadcast search on saved-search tab -> change to broadcast tab
      if(this.activeTab !== undefined){
        if(this.isSelectedCompanyMyCompany){
          this.activeTab = '1'
        } else if(this.activeTab === '2'){
          this.activeTab = '0'
        } 
      }
       
    },
    doSearch(doStayOnHomepageRaw = false){

      const doStayOnHomepage = doStayOnHomepageRaw === true
      if(this.route.name === 'inventoryHits'){
        return this.$router.replace({ query: this.payload });
      }

      if(this.route.name === 'news'){
        if(!this.payload.id){
          delete this.payload.id;
        }
        return this.$router.replace({ query: this.payload });
      }

      // console.log(this.payload)
      // If resolves to falsey -> omit
      // This ensures clean querystring where possible
      const payloadToRoute = _.reduce(this.payload, (agg, v, k) => {
        if(k === 'page' && v === 1){
          agg[k] = undefined;
        } else {
          agg[k] = v ? v : undefined;
        }
        return agg;
      }, {})

      payloadToRoute.query = this.tempQuery;
      payloadToRoute.query2 = this.tempQuery2 || undefined;
      payloadToRoute.query3 = this.tempQuery3 || undefined;
      payloadToRoute.query4 = this.tempQuery4 || undefined;
      payloadToRoute.query5 = this.tempQuery5 || undefined;

      const diffMap = _.compact(_.map(payloadToRoute, (v, k) => {
        if(k === "page") return false;
        return (v !== this.route.query[k])
      }))

      const isHomePage = this.$route.name === 'home';

      // Search changed -> reset page
      if(diffMap.length || payloadToRoute.page !== this.route.query.page){
        if(diffMap.length && payloadToRoute.page === this.route.query.page){ //If pagination is different don't reset. This hack is needed for 'go back from detail page'
          payloadToRoute.page = undefined;
        }
        if(isHomePage && !doStayOnHomepage){
          this.$router.push({ name: "broadcasts", query: payloadToRoute })
        } else {
          this.$router.replace({ query: payloadToRoute }); // Update url while not creating history.
        }
      } else {
        //skip: route not changed.
      }
    },

  }
}