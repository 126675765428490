<template>
	<base-layout :doSkipHeader="true">
		<template v-slot:main>
			<div>
			
			  <transition name="component-fade" mode="out-in">

				  <div v-if='stage === 1' key='stage-1'>

				  	<div class="pt-12 sm:pt-16 lg:pt-20">
					    <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
					      <div class="text-center">
					        <h2 class="text-2xl sm:text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
					          Simple no-tricks pricing
					        </h2>
					        <!-- <p class="mt-4 text-xl leading-7 text-gray-600">
					          If you're not satisfied, contact us within the first 14 days and we'll send you a full refund.<br/>
					          <strong>Wellicht iets soortgelijk pakkends als hierboven?</strong>
					        </p> -->
					      </div>
					    </div>
					  </div>

					  <div class="mt-8 pb-4 ">
					    <div class="relative">
					      <div class="absolute inset-0 h-1/2 bg-gray-100"></div>
					      <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
					        <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex bg-white">
					          <div class="bg-white px-6 py-8 lg:p-12">
					            <h3 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-2xl sm:text-3xl leading-9">
					              Plus Account
					            </h3>
					            <div class="mt-8">
					              <div class="flex items-center">
					                <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
					                  What's included
					                </h4>
					                <div class="flex-1 border-t-2 border-gray-200"></div>
					              </div>
					              <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
					                <li class="flex items-start lg:col-span-1">
					                  <div class="flex-shrink-0">
					                    <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
					                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
					                    </svg>
					                  </div>
					                  <p class="ml-3 text-sm leading-5 text-gray-700">
					                    Unlimited inventory uploading
					                  </p>
					                </li>
					                <li class="mt-5 flex items-start lg:col-span-1 lg:mt-0">
					                  <div class="flex-shrink-0">
					                    <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
					                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
					                    </svg>
					                  </div>
					                  <p class="ml-3 text-sm leading-5 text-gray-700">
					                    Unlimited website/member list access
					                  </p>
					                </li>
					                <li class="mt-5 flex items-start lg:col-span-1 lg:mt-0">
					                  <div class="flex-shrink-0">
					                    <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
					                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
					                    </svg>
					                  </div>
					                  <p class="ml-3 text-sm leading-5 text-gray-700 underline">
					                    max. <strong>2</strong> email addresses on all broadcast lists
					                  </p>
					                </li>
					              </ul>
					            </div>
					          </div>
					          <div class="lg:ml-4 py-8 px-6 text-center lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
					            <p class="text-lg leading-6 font-medium text-gray-900">
					              Price per year
					            </p>
					            <div class="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900">
					              <span>
					                €675
					              </span>
					              <span class="ml-3 text-xl leading-7 font-medium text-gray-500">
					                EUR
					              </span>
					            </div>
					            <p class="mt-4 text-sm leading-5">
					              <a href="javascript:void(0)"  @click='showContractModal = true' class="font-medium text-gray-500 underline">
					                Learn about our contract terms
					              </a>
					            </p>
					            <div class="mt-6">
					              <div class="rounded-md shadow">
					                <a href="javascript:void(0)"  @click='stage = 2; payload.option = 0' class="flex items-center justify-center btn btn-primary px-5 py-3">
					                  Order Now
					                </a>
					              </div>
					            </div>
					            
					          </div>
					        </div>
					      </div>
					    </div>
					  </div>
					  <div class="mt-8 pb-4">
					    <div class="relative">
					      <div class="absolute inset-0 h-1/2 bg-gray-100"></div>
					      <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
					        <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex bg-white">
					          <div class="bg-white px-6 py-8 lg:p-12">
					            <h3 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-2xl sm:text-3xl leading-9">
					              Broker Account
					            </h3>
					            <div class="mt-8">
					              <div class="flex items-center">
					                <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
					                  What's included
					                </h4>
					                <div class="flex-1 border-t-2 border-gray-200"></div>
					              </div>
					              <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
					                <li class="flex items-start lg:col-span-1">
					                  <div class="flex-shrink-0">
					                    <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
					                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
					                    </svg>
					                  </div>
					                  <p class="ml-3 text-sm leading-5 text-gray-700">
					                    Unlimited inventory uploading
					                  </p>
					                </li>
					                <li class="mt-5 flex items-start lg:col-span-1 lg:mt-0">
					                  <div class="flex-shrink-0">
					                    <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
					                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
					                    </svg>
					                  </div>
					                  <p class="ml-3 text-sm leading-5 text-gray-700">
					                    Unlimited website/member list access
					                  </p>
					                </li>
					                <li class="mt-5 flex items-start lg:col-span-1 lg:mt-0">
					                  <div class="flex-shrink-0">
					                    <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
					                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
					                    </svg>
					                  </div>
					                  <p class="ml-3 text-sm leading-5 text-gray-700 underline">
					                    max. <strong>10</strong> email addresses on all broadcast lists
					                  </p>
					                </li>
					              </ul>
					            </div>
					          </div>
					          <div class="py-8 px-6 text-center  lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
					            <p class="text-lg leading-6 font-medium text-gray-900">
					              Price per year
					            </p>
					            <div class="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900">
					              <span>
					                €1350
					              </span>
					              <span class="ml-3 text-xl leading-7 font-medium text-gray-500">
					                EUR
					              </span>
					            </div>
					            <p class="mt-4 text-sm leading-5">
					              <a href="javascript:void(0)"  @click='showContractModal = true' class="font-medium text-gray-500 underline">
					                Learn about our contract terms
					              </a>
					            </p>
					            <div class="mt-6">
					              <div class="rounded-md shadow">
					                <a href="javascript:void(0)"  @click='stage = 2; payload.option = 1' class="flex items-center justify-center btn btn-primary px-5 py-3">
					                  Order Now
					                </a>
					              </div>
					            </div>
					            
					          </div>
					        </div>
					      </div>
					    </div>
					  </div>
					  <div class="mt-8 pb-4 mb-24">
					    <div class="relative">
					      <div class="absolute inset-0 h-1/2 bg-gray-100"></div>
					      <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
					        <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex bg-white">
					          <div class="py-8 px-6 text-center lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12 mx-auto">
								  <div class="mb-4">
									Next to direct wire, the following payment options are supported:
								  </div>
								  	<div class="mx-auto">
										<div class="w-auto">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_visa.png" title="VISA">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_amex.png" title="American Express">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_mc.png" title="MasterCard">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_bancontact.png" title="Bancontact/Mister Cash">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_maestro.png" title="Maestro">
										</div>
										<div class="w-auto">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_ideal.png" title="iDEAL">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_giropay.png" title="giropay">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_belfius.png" title="Belfius Direct Net">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_kbc.png" title="KBC Bank">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_cbc.png" title="CBC Banque">
										</div>
										<div class="w-auto mb-5">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_sofos_de.png" title="Sofortüberweisung DE">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_sofos_at.png" title="Sofortüberweisung AT">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_sofos_nl.png" title="Sofort Banking NL">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_sofos_be.png" title="Sofort Banking BE">
											<img class="float-left object-fill h-45 border m-3 rounded" src="/img/pay_sofos_it.png" title="Sofort Banking IT">
										</div>
									</div>
								  <div class="clear-both mt-3 text-sm">
									Upon completion of the order, your credit card will be charged immediately.<br />
					            	After payment we will contact your company within 24 hours to activate your account.
								  </div>
					          </div>
					        </div>
					      </div>
					    </div>
					  </div>

					</div>

					<div v-else-if='stage === 2' key='stage-2' class='mt-8'>
						<a href="javascript:void(0)"  @click='stage = 1' class="block text-sm text-red-600 hover:underline">
              « Back to payment options
            </a>

            <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex bg-white mt-6">
							
	            <ValidationObserver ref="paymentReq" class='w-1/2 pb-6 order-1'>
	            	<h1 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-2xl sm:text-3xl leading-9 px-4 sm:px-6 mt-12 block">Payment</h1>
								<dl class='mt-4 mb-4'>
									<div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 px-4 sm:px-6">
										<dt class="text-sm leading-9 font-medium text-gray-500">Membership</dt>
										<dd class="-mt-2 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
											<div class='leading-6 leading-9 font-bold'>
												{{membershipText}}
											</div>
										</dd>
									</div>

									<div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 px-4 sm:px-6">
										<dt class="text-sm leading-9 font-medium text-gray-500">Your full name</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<input v-model='payload.name' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</dd>
									</div>

									<div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 px-4 sm:px-6">
										<dt class="text-sm leading-9 font-medium text-gray-500">Email address</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<ValidationProvider rules="required|email" v-slot="{ errors }">
												<input v-model='payload.email' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</dd>
									</div>

									<div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 px-4 sm:px-6">
										<dt class="text-sm leading-9 font-medium text-gray-500">Company name</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<input v-model='payload.company' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</dd>
									</div>

									<div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 px-4 sm:px-6">
										<dt class="text-sm leading-9 font-medium text-gray-500">Company address</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<input v-model='payload.address' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</dd>
									</div>

									<div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 px-4 sm:px-6">
										<dt class="text-sm leading-5 font-medium text-gray-500">Company ZIP Code</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<input v-model='payload.zip' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</dd>
									</div>

									<div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 px-4 sm:px-6">
										<dt class="text-sm leading-9 font-medium text-gray-500">Company City</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<input v-model='payload.city' class="form-input block w-full">
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</dd>
									</div>

									<div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 px-4 sm:px-6">
										<dt class="text-sm leading-9 font-medium text-gray-500">Company Country</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<ValidationProvider rules="required" v-slot="{ errors }">

												<select v-model="payload.country" class="form-select block w-full">
													<option v-for="item in countriesOptionsPleaseSelect" :value="item.value" :key='item.value'>{{item.name}}</option>
												</select>
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</dd>
									</div>

									<div class="py-2 sm:grid sm:grid-cols-4 sm:gap-4 px-4 sm:px-6">
										<dt class="text-sm leading-9 font-medium text-gray-500">{{vatLabel}}</dt>
										<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<input v-model='payload.vat' class="form-input block w-full">
												<span class='text-gray-500 text-xs mt-2 block leading-4'>Incorrect or missing {{vatLabel}} numbers will result in addition of appropriate taxes</span>
												<span class='text-red-500 text-sm mt-2 block'>{{ errors[0] }}</span>
											</ValidationProvider>
										</dd>
									</div>

									<div class="flex items-center py-2 px-4 sm:px-6">
										<input v-model='payload.isContractAgreed' id="rfq_sendAll" type="checkbox" class="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out">
										<label for="rfq_sendAll" class="ml-4 block text-sm leading-5">
											I have read, understood and agree to the 
											<a href="javascript:void(0)"  class='text-red-700 hover:underline' @click='showContractModal = true'>contract terms</a>
										</label>
									</div>

									<div class='clearfix'>
										<button :disabled='disableSubmit' @click='toOrderPage' type="button" class="float-right mt-4 mr-6 rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5" :class='submitClass'>Next</button>
									</div>

								</dl>
							</ValidationObserver>
							
							<div class="lg:w-1/2 bg-red-100 py-8 px-8 order-2">

								<h1 class="text-lg leading-8 font-extrabold text-gray-900 sm:text-xl leading-9 mt-6 block">Payment Intructions</h1>
								<div class='font-bold text-red-500'>This form must be filled out by an authorized contact person</div>
								<ul class='mt-4 text-sm'>
									<li>1. Read the <a href="javascript:void(0)"  class='text-red-700 hover:underline' @click='showContractModal = true'>contract terms</a> before proceeding</li>
									<li>2. Fill out the entire form</li>
									<li>3. Check your information for mistakes</li>
									<li>4. Click 'Next' to proceed.</li>
								</ul>

								<p class="text-xs mt-8 text-red-900">
								NOTE: Companies from The Netherlands will be charged an additional 21% BTW<br/>
								According to Dutch law all European Companies (Including Dutch Companies) need to fill out their VAT!
								</p>
								<p class="text-xs mt-4 text-red-900">
								Any information displayed on this page may change without prior notice.
								</p>
							</div>
						</div>
					</div>

					<div v-else-if='stage === 3' key='stage-3' class='mt-8'>

						<a href="javascript:void(0)"  @click='stage = 2' class="block text-sm text-red-600 hover:underline">
              « Change Info
            </a>

            <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none bg-white mt-6 px-4 sm:px-6 lg:px-8 py-6">
            	<h1 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-2xl sm:text-3xl leading-9 mb-4 block">Order</h1>
							
							<div class='clearfix'>
								<div class='text-sm mt-4 lg:float-left'>
									<div class='font-bold'>To:</div>
									<div>{{payload.company}}</div>
									<div>{{payload.name}}</div>
									<div>{{payload.email}}</div>
									<div>{{payload.address}}</div>
									<div>{{payload.zip}}, {{payload.city}}</div>
									<div>{{payload.country}}</div>

								</div>
								<div class='text-sm mt-4 lg:float-right'>
									<div class='font-bold'>From:</div>

									The Broker Site BV<br/>
									PO Box 4011<br/>
									4900 CA, Oosterhout, NL<br/>
								</div>
							</div>

							<!-- large -->
							<div class="py-2 align-middle inline-block min-w-full hidden lg:block">
					      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mt-10">
					        <table class="min-w-full divide-y divide-gray-200">
					          <thead>
					            <tr>
					              <th class="px-6 py-3 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
					                Quantity
					              </th>
					              <th class="px-6 py-3 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
					                Description
					              </th>
					              <th class="px-6 py-3 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
					                Unit Price
					              </th>
					              <th class="px-6 py-3 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
					                Price
					              </th>
					            </tr>
					          </thead>
					          <tbody>
					            <tr class="bg-gray-100 border-b">
					              <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">1</td>
					              <td class="px-6 py-4 text-sm leading-5 text-gray-500">
					                {{membershipTitle}}<br/>
					                {{membershipDesc}}
					              </td>
					              <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 font-bold">
					                € {{priceExVat.toLocaleString('en-US', {minimumFractionDigits:2})}}
					              </td>
					              <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 font-bold">
					                € {{priceExVat.toLocaleString('en-US', {minimumFractionDigits:2})}}
					              </td>
					            </tr>
					            <tr class="bg-gray-100 border-b">
					            	<td class="px-6 py-4 text-sm leading-5 font-bold text-gray-900" colspan="3">
					            		Subtotal<br/>
					            		{{vatLabel}} Number: {{payload.vat}} <br/>
					            		{{vatExplain}}
					            	</td>
					           	  <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold text-gray-900">
					           	  	€ {{priceExVat.toLocaleString('en-US', {minimumFractionDigits:2})}}<br/><br/>
					           	  	€ {{vat.toLocaleString('en-US', {minimumFractionDigits:2})}}<br/><br/>
					           	  </td>
					            </tr>
					            <tr class="bg-gray-100">
					            	<td class="px-6 py-4  text-sm leading-5 font-medium text-gray-900" colspan="3">
					            		<span class='font-bold'>Invoice Total</span> (payment for one (1) year)
					            	</td>
					           	  <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold text-gray-900">
					           	  	€ {{priceInclVat.toLocaleString('en-US', {minimumFractionDigits:2})}}
					           	  </td>
					            </tr>
					          </tbody>
					        </table>
					      </div>
					    </div>

					    <!-- Small -->
					    <div class="py-2 align-middle inline-block min-w-full block lg:hidden">
					      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mt-10">
					        <table class="min-w-full divide-y divide-gray-200">
					          <thead>
					            <tr>
					              <th class="px-6 py-3 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
					                Description
					              </th>
					              <th class="px-6 py-3 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
					                Unit Price
					              </th>
					              <th class="px-6 py-3 bg-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
					                Price
					              </th>
					            </tr>
					          </thead>
					          <tbody>
					            <tr class="bg-gray-100 border-b">
					              <td class="px-6 py-4 text-sm leading-5 text-gray-500">
					                {{membershipTitle}}<br/>
					                {{membershipDesc}}
					              </td>
					              <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 font-bold">
					                € {{priceExVat.toLocaleString('en-US', {minimumFractionDigits:2})}}
					              </td>
					              <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900 font-bold">
					                € {{priceExVat.toLocaleString('en-US', {minimumFractionDigits:2})}}
					              </td>
					            </tr>
					            <tr class="bg-gray-100 border-b">
					            	<td class="px-6 py-4 text-sm leading-5 font-bold text-gray-900" colspan="2">
					            		Subtotal<br/>
					            		{{vatLabel}} Number: {{payload.vat}} <br/>
					            		{{vatExplainShort}}
					            	</td>
					           	  <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold text-gray-900">
					           	  	€ {{priceExVat.toLocaleString('en-US', {minimumFractionDigits:2})}}<br/><br/>
					           	  	€ {{vat.toLocaleString('en-US', {minimumFractionDigits:2})}}<br/><br/>
					           	  </td>
					            </tr>
					            <tr class="bg-gray-100">
					            	<td class="px-6 py-4  text-sm leading-5 font-medium text-gray-900" colspan="2">
					            		<span class='font-bold'>Invoice Total</span> (payment for one (1) year)
					            	</td>
					           	  <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-bold text-gray-900">
					           	  	€ {{priceInclVat.toLocaleString('en-US', {minimumFractionDigits:2})}}
					           	  </td>
					            </tr>
					          </tbody>
					        </table>
					      </div>
					    </div>

						<script type='application/ld+json'>
						{
							"@context": "http://www.schema.org",
							"@type": "product",
							"brand": "The Broker Site",
							"name": "TBS Membership",
							"image": "https://www.thebrokersite.com/media/img/tbs_logo.png",
							"description": "One year membership on TheBrokerSite.com includes Unlimited inventory uploading, Unlimited website/member list access, Company Logo on your profile and 1 or 2 users on our Broadcast.",
							"sku": "827636237447",
							"mpn": "TBS20XXDATE",
							"aggregateRating": {
								"@type": "aggregateRating",
								"ratingValue": "5",
								"reviewCount": "2410"
							},
							"offers": {
								"@type": "Offer",
								"url": "https://www.thebrokersite.com/payment",
								"itemCondition": "http://schema.org/NewCondition",
								"availability": "http://schema.org/InStock",
								"price": "675",
								"priceCurrency": "EUR"
							}
						}
						</script>        


							<vue-recaptcha v-if='!user' class='mt-6 float-left'
								ref="recaptcha"
								@verify="onVerify"
								:sitekey="recaptchaKey">
							</vue-recaptcha>

					    <div class='clearfix'>
								<button  @click='orderNow' :disabled='disableSubmit2' type="button" class="float-right mt-4 mr-6 rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5" :class='submitClass2'>Pay for TBS Membership</button>
							</div>
            </div>
					</div>

					<div v-else-if='stage === 4' key='stage-4' class='mt-8'>

						<a href="javascript:void(0)"  @click='stage = 3' class="block text-sm text-red-600 hover:underline">
              « Verify Order
            </a>

						<div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none bg-white mt-6 px-4 sm:px-6 lg:px-8 py-6">
							<h1 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-2xl sm:text-3xl leading-9 mb-4 block">
								Order Confirmation
							</h1>

							<div class='font-bold'>Your order has been placed successfully.</div>
							<div class="text-gray-600">An invoice was sent to you by email.</div>
						</div>
					</div>

					<div v-else-if='stage === 5' key='stage-5' class='mt-8'>

						<a href="javascript:void(0)"  @click='stage = 3' class="block text-sm text-red-600 hover:underline">
              « Verify Order
            </a>

						<div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none bg-white mt-6 px-4 sm:px-6 lg:px-8 py-6">
							<h1 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-2xl sm:text-3xl leading-9 mb-4 block">
								Payment Error
							</h1>

							<div class='font-bold'>The payment process was not completed.</div>
							<div class="text-gray-600">Contact us at sales@thebrokersite.com to find out what went wrong and to receive further instructions on how to proceed.</div>
							<div class="text-gray-600">Please use the buttons at the top of the page to navigate further on The Broker Site.</div>
						</div>

					</div>

				</transition>

			  <modal v-show="showContractModal" @submit="showContractModal = false"
					:doSkipHeader="true"
					:doSkipCancel="true"
					customCssClasses="sm:max-w-6xl"
					affirmativeButtonLabel='Close'>
					<template v-slot:body>
						<ContractComponent></ContractComponent>
				  </template>
				</modal>

			</div>
		</template>
	</base-layout>
</template>

<script>

import _ from 'lodash'
import { mapState, mapGetters, mapActions} from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import eventBus from '../utils/eventBus'

import BaseLayout from '@/components/BaseLayout.vue'
import ContractComponent from '@/components/ContractComponent.vue'
import Modal from '@/components/modal.vue'
import captchaMixin from '@/components/mixins/captcha'

export default {
	mixins: [captchaMixin],
  name: 'Payment',
  components: {
    BaseLayout,
    ContractComponent,
    Modal,
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
  	showContractModal: false,
  	stage: 1, //1
  	payload: {
  		option: 0,
  		name: null,
  		email: null,
  		company: null, 
  		address: null,
  		zip: null,
  		city: null,
  		country: "", 
  		vat: null,
  		isContractAgreed: false,
  	}, 
  	// payload: {
  	// 	option: 0,
  	// 	name: "Geert-Jan Brots",
  	// 	email: "gbrits@gmail.com",
  	// 	company: "Wrappt", 
  	// 	address: "Ginnekenweh 268b",
  	// 	zip: "4835NK",
  	// 	city: "Breda",
  	// 	country: "Netherlands", 
  	// 	vat: "1234567890",
  	// 	isContractAgreed: true,
  	// }, 
  	disableSubmit: true, 
  }),
  computed: {
		...mapState(['user']),	
  	...mapGetters(['countriesOptionsPleaseSelect', 'countriesReverseCharge']),
  	membershipText(){
  		if(!this.payload.option){
  			return "TBS Plus Account (€ 675)"
  		}
  		return "TBS Broker Account (€ 1350)"
  	},
  	membershipTitle(){
  		if(!this.payload.option){
  			return "TBS Plus Account"
  		}
  		return "TBS Broker Account"
  	},
  	membershipDesc(){
  		if(!this.payload.option){
  			return "Unlimited inventory uploading, Unlimited website/member list access, and max. 2 addresses on all broadcast lists"
  		}
  		return "Unlimited inventory uploading, Unlimited website/member list access, and max. 10 addresses on all broadcast lists"
  	},
  	priceExVat(){
  		return !this.payload.option ? 675.0 : 1350.0
  	},
  	vat(){
  		return (this.payload.country === "Netherlands") ? 0.21 * this.priceExVat : 0;
  	},
  	vatExplain(){
  		if(this.payload.country === "Netherlands") return "VAT Total (21% because company is in the Netherlands)"
  		if(~this.countriesReverseCharge.indexOf(this.payload.country)) return "VAT Total (Reverse Charge)";
  		return `${this.vatLabel} Total`;
  	},
  	vatExplainShort(){
  		if(this.payload.country === "Netherlands") return "VAT Total (21% - NL)" 
  		if(~this.countriesReverseCharge.indexOf(this.payload.country)) return "VAT Total (Reverse Charge)";
  		return `${this.vatLabel} Total`;
  	},
  	priceInclVat(){
  		return this.priceExVat + this.vat;
  	},
  	submitClass(){
  		if(this.disableSubmit){
  			return "border-gray-400 bg-gray-400 cursor-default"
  		} else {
  			return "bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red "
  		}
  	},
		submitClass2(){
  		if(this.disableSubmit2){
  			return "border-gray-400 bg-gray-400 cursor-default"
  		} else {
  			return "bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red "
  		}
  	},
		disableSubmit2(){
			return !this.user && !this.captchaResponse
		},
		vatLabel(){
			return this.payload.country === 'UK' ? "EORI" : "VAT"
		},
  },
	created(){

		if(this.$route.query.stage === 'ok'){
			this.stage = 4;
		}else if(this.$route.query.stage === 'fail'){
			this.stage = 5;
		}
	},
  watch: {
  	"payload.isContractAgreed"(isAgreed){
  		this.disableSubmit = !isAgreed;
  	}
  },
  methods: {	
		...mapActions(['requestInvoice']),
  	async toOrderPage(){
  		const isValid = await this.$refs.paymentReq.validate();
      if (!isValid) return;
      this.stage = 3;
  	},
  	async orderNow(){

			try{
				const payload = _.extend({captcha: this.captchaResponse}, this.payload)
				const resp = await this.requestInvoice(payload)

				if(resp.status !== 200) throw resp.data
				location.href = resp.data.link
			} catch(err){

				let msg = err.payload ? err.payload.message : err.message
				if(err.payload.errors) {
					msg = _.map(err.payload.errors, (dto) => `field '${dto.param}' => ${dto.msg}`).join("<br/>")
				}

				eventBus.$emit("createSnackbar", {
					 type: "error", 
            title: "Invoice could not be processed",
            body: `Invoice could not be processed with message <br/> ${msg}. <br/><br/> Please try again. <br/> If the problem persists please contact The Broker Site`,
            timeoutInMS: 5000,
				})
				console.error("InvoiceError", msg)
			}
  	}
  }
}
</script>

<style>
	/* Enter and leave animations can use different */
	/* durations and timing functions.              */
	.slide-fade-enter-active {
	  transition: all .3s ease;
	}
	.slide-fade-leave-active {
	  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}
	.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */ {
	  transform: translateX(10px);
	  opacity: 0;
	}

	.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
