<template>
	<base-layout>
		<template v-slot:header>
			About
		</template>
		<template v-slot:main>
			<div >
				<h2 class="text-5xl font-semibold text-center m-4 text-red-700 flex items-center justify-center">
					Empowering The Circular IT Industry Since 1999
					<img src="/img/3.png" class="w-20 ml-4">
				</h2>
				<div class="mt-6 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4">
					<div class="max-w-7xl mx-auto py-6 px-0 sm:px-4">
						<div class="text-center px-6 sm:px-2">
							<div>
							Want to buy or sell your IT products safely and efficiently? Look no further than The Broker Site, <strong>the leading B2B marketplace for IT trading</strong>, where businesses can securely trade new, used and refurbished IT products. Our network consists of <strong>3,000+ verified members</strong>, including brokers, refurbishers, resellers, service providers and wholesalers.<br><br>
							<router-link to="/register" class="mt-8 rounded-md border border-transparent text-small sm:text-l bg-red-600 py-4 px-4 sm:px-8 font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">Start your 1-month free trial</router-link>
							</div>
						</div>
					</div>
				</div>

				<div class="md:grid grid-cols-3 gap-4">
					<div class="mt-6 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4 text-center flex flex-col justify-between">
						<div>
							<h3 class="text-4xl text-bold m-4">Send Broadcasts</h3>
							As a member of The Broker Site, you have the possibility to send your Want To Buy (WTB) and Want To Sell (WTS) <strong><a href="/broadcasts" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">broadcasts</a></strong> to our 3000+ members. We offer 12 different categories to choose from and you can customize your broadcasts to fit your specific needs.<br><br>
						</div>
						<a href="/help" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Learn how to create broadcasts</a>
					</div>
					<div class="mt-6 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4 text-center flex flex-col justify-between">
						<div>
							<h3 class="text-4xl text-bold m-4">WTB &amp; WTS</h3>
							With our Want To Buy/Sell messaging system, you can broadcast messages to all TBS members who are interested in the same products as you. You can even RSS the WTB/WTS messages and search our broadcast database to find the products you're looking for.<br><br>
						</div>
						<a href="/help" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Learn how to send offers</a>
					</div>
					<div class="mt-6 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4 text-center flex flex-col justify-between">
						<div>
							<h3 class="text-4xl text-bold m-4">Save Searches</h3>
							Only on The Broker Site you can subscribe to a search performed in the broadcast and communication system. You will only receive the information you are looking for, and only for as long as you need to.<br><br>
						</div>
						<a href="/help" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Learn how to save broadcasts</a>
					</div>
				</div>

				<div class="mt-6 w-full bg-red-600 shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4 text-center text-white">
					<h2 class="text-4xl text-bold m-4">Our Broadcast Categories</h2>
					<div class="max-w-7xl mx-auto py-6 px-0 sm:px-4">
						<div class="md:grid grid-cols-4 gap-4 pl-8">
							<div class="text-left text-xl">
								&bull; PC<br>
								&bull; Laptop<br>
								&bull; Components<br>
							</div>
							<div class="text-left text-xl">
								&bull; Server/Storage<br>
								&bull; Networking/Telecom<br>
								&bull; Mobile<br>
							</div>
							<div class="text-left text-xl">
								&bull; Tablet<br>
								&bull; Printer<br>
								&bull; Electronics<br>
							</div>
							<div class="text-left text-xl">
								&bull; Monitor<br>
								&bull; POS<br>
								&bull; Software<br>
							</div>
						</div>
					</div>
				</div>

				<div class="mt-6 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4">
					<div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
						<div class="block md:hidden">
							<div class="text-center px-8">
								<h2 class="text-4xl text-bold m-4">Upload Your Inventory</h2>
								<div>
								In addition to broadcasting, it is also possible to post your inventory on The Broker Site. This will allow both members and non-members to easily find your products through our <a href="/parts" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Parts Search</a>. When posting your inventory, you can also see which members have searched for your products, which will help you increase your visibility and gain insights into the demand of your products.
								</div>
							</div>
						</div>
						<div class="hidden md:block">
							<div class="custom-grid-left">
								<div>
									<img src="/img/4.png" style="width: 248px;">
								</div>
								<div class="text-center px-8">
									<h2 class="text-4xl text-bold m-4">Upload Your Inventory</h2>
									<div>
									In addition to broadcasting, it is also possible to post your inventory on The Broker Site. This will allow both members and non-members to easily find your products through our <a href="/parts" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Parts Search</a>. When posting your inventory, you can also see which members have searched for your products, which will help you increase your visibility and gain insights into the demand of your products.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="mt-6 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4">
					<div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
						<div class="hidden md:block">
							<div class="custom-grid-right">
								<div class="text-center px-8">
									<h2 class="text-4xl text-bold m-4">Supporting a Circular Economy</h2>
									<div>
									As E-waste continues to increase, it is essential for businesses to be aware of the option to repurpose functional devices or parts. The Broker Site offers a marketplace where you trade used and refurbished IT equipment to give it a new life.<br><br>
									<a href="/grading-guidelines" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">See our grading guidelines</a>
									</div>
								</div>
								<div>
									<img src="/img/3.png" style="width: 248px;">
								</div>
							</div>
						</div>
						<div class="block md:hidden">
							<div class="text-center px-8">
								<h2 class="text-4xl text-bold m-4">Supporting a Circular Economy</h2>
								<div>
								As E-waste continues to increase, it is essential for businesses to be aware of the option to repurpose functional devices or parts. The Broker Site offers a marketplace where you trade used and refurbished IT equipment to give it a new life.<br><br>
								<a href="/grading-guidelines" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">See our grading guidelines</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="mt-6 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4">
					<div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
						<div class="hidden md:block">
							<div class="custom-grid-left">
								<div>
									<img src="/img/1.png" style="width: 248px;">
								</div>
								<div class="text-center px-8">
									<h2 class="text-4xl text-bold m-4">Trusted, Gold &amp; Diamond Members</h2>
									<div>
									At The Broker Site, we offer four different member types: Regular, 
									<a href="/trusted-members" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Trusted</a>,
									<a href="/gold-members" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Gold</a>, and
									<a href="/diamond-members" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Diamond</a>. Regular Members have been with us for less than 3 years, Trusted Members for 3 years or more, Gold Members for 10 years or more, and Diamond Members for 20 years or more. To attain Trusted, Gold, or Diamond status, members must meet specific criteria. They should not have any unsolved complaints and they should offer a 1 month return to base (RTB) warranty.
									</div>
								</div>
							</div>
						</div>
						<div class="block md:hidden">
							<div class="text-center px-8">
								<h2 class="text-4xl text-bold m-4">Trusted, Gold &amp; Diamond Members</h2>
								<div>
								At The Broker Site, we offer four different member types: Regular, 
								<a href="/trusted-members" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Trusted</a>,
								<a href="/gold-members" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Gold</a>, and
								<a href="/diamond-members" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Diamond</a>. Regular Members have been with us for less than 3 years, Trusted Members for 3 years or more, Gold Members for 10 years or more, and Diamond Members for 20 years or more. To attain Trusted, Gold, or Diamond status, members must meet specific criteria. They should not have any unsolved complaints and they should offer a 1 month return to base (RTB) warranty.
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="mt-6 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4">
					<div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
						<div class="hidden md:block">
							<div class="custom-grid-right">
								<div class="text-center px-8">
									<h2 class="text-4xl text-bold m-4">Verified Traders</h2>
									<div>
									At The Broker Site, our top priority is to ensure safe trading for all our members. We have a <strong>strict verification process</strong> for every new member to ensure a safe trading environment. Additionally, our member profiles offer complete transparency, so you know exactly who you are trading with.<br><br>
									<a href="/contact" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Our team</a> consistently monitors our members. Although we understand that sometimes deals do not go as planned, we expect our members to resolve their complaints otherwise they risk losing their membership.
									</div>
								</div>
								<div>
									<img src="/img/2.png" style="width: 248px;">
								</div>
							</div>
						</div>
						<div class="block md:hidden">
							<div class="text-center px-8">
								<h2 class="text-4xl text-bold m-4">Verified Traders</h2>
								<div>
									At The Broker Site, our top priority is to ensure safe trading for all our members. We have a <strong>strict verification process</strong> for every new member to ensure a safe trading environment. Additionally, our member profiles offer complete transparency, so you know exactly who you are trading with.<br><br>
									<a href="/contact" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Our team</a> consistently monitors our members. Although we understand that sometimes deals do not go as planned, we expect our members to resolve their complaints otherwise they risk losing their membership.
								</div>
							</div>
						</div>
					</div>
				</div>

				<h2 class="text-5xl font-semibold text-center m-4 mt-12 text-red-700 flex items-center justify-center">Discover Our Memberships</h2>
				<div class="md:grid grid-cols-2 gap-4">
					<div class="mt-2 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4 text-center">
						<h3 class="text-2xl text-bold m-4 text-red-700">TBS Plus Account</h3>
						<div class="flex justify-center">
							<ul class="mt-4 text-left">
								<li class="flex items-start mb-2">
									<div class="flex-shrink-0">
										<svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
										</svg>
									</div>
									<p class="ml-3 text-sm leading-5 text-gray-700">
										Unlimited inventory uploading
									</p>
								</li>
								<li class="flex items-start mb-2">
									<div class="flex-shrink-0">
										<svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
										</svg>
									</div>
									<p class="ml-3 text-sm leading-5 text-gray-700">
										Unlimited website/member list access
									</p>
								</li>
								<li class="flex items-start mb-2">
									<div class="flex-shrink-0">
										<svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
										</svg>
									</div>
									<p class="ml-3 text-sm leading-5 text-gray-700 underline">
										max. <strong>2</strong> email addresses on all broadcast lists
									</p>
								</li>
							</ul>
						</div>
						<div class="my-6">
							<strong>&euro; 675 per year</strong>
						</div>
						<div class="my-6">
							<router-link to="/register" class="mt-8 rounded-md border border-transparent text-small sm:text-l bg-red-600 py-4 px-4 sm:px-8 font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">Become a member</router-link>
						</div>
					</div>
					<div class="mt-2 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4 text-center">
						<h3 class="text-2xl text-bold m-4 text-red-700">TBS Broker Account</h3>
						<div class="flex justify-center">
							<ul class="mt-4 text-left">
								<li class="flex items-start mb-2">
									<div class="flex-shrink-0">
										<svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
										</svg>
									</div>
									<p class="ml-3 text-sm leading-5 text-gray-700">
										Unlimited inventory uploading
									</p>
								</li>
								<li class="flex items-start mb-2">
									<div class="flex-shrink-0">
										<svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
										</svg>
									</div>
									<p class="ml-3 text-sm leading-5 text-gray-700">
										Unlimited website/member list access
									</p>
								</li>
								<li class="flex items-start mb-2">
									<div class="flex-shrink-0">
										<svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
											<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
										</svg>
									</div>
									<p class="ml-3 text-sm leading-5 text-gray-700 underline">
										max. <strong>10</strong> email addresses on all broadcast lists
									</p>
								</li>
							</ul>
						</div>
						<div class="my-6">
							<strong>&euro; 1350 per year</strong>
						</div>
						<div class="my-6">
							<router-link to="/register" class="mt-8 rounded-md border border-transparent text-small sm:text-l bg-red-600 py-4 px-4 sm:px-8 font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">Become a member</router-link>
						</div>
					</div>
				</div>

				<div class="mt-6 w-full bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-2 sm:px-6 mb-4">
					<div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
						<div class="hidden md:block">
							<div class="custom-grid-left">
								<div>
									<img src="/img/5.png" style="width: 348px;">
								</div>
								<div class="text-center px-8">
									<h2 class="text-4xl text-bold m-4">The European Broker Meeting</h2>
									<div>
									Every year, The Broker Site organizes <strong>the leading get-together for the IT trading industry</strong>: the European Broker Meeting! Since 2004, this has been the event where the industry comes together to meet new and existing customers and suppliers, all in one place.<br><br>
									<a href="https://www.europeanbrokermeeting.com" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Learn more about the European Broker Meeting</a>
									</div>
								</div>
							</div>
						</div>
						<div class="block md:hidden">
							<div class="text-center px-8">
								<h2 class="text-4xl text-bold m-4">The European Broker Meeting</h2>
								<div>
									Every year, The Broker Site organizes <strong>the leading get-together for the IT trading industry</strong>: the European Broker Meeting! Since 2004, this has been the event where the industry comes together to meet new and existing customers and suppliers, all in one place.<br><br>
								<a href="https://www.europeanbrokermeeting.com" rel="noopener" target="_blank" class="text-red-600 font-bold hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Learn more about the European Broker Meeting</a>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'About',
  components: {
    BaseLayout,
  }
}
</script>

<style>
.custom-grid-left {
  display: grid;
  grid-template-columns: 300px 1fr;
}
.custom-grid-right {
  display: grid;
  grid-template-columns: 1fr 300px;
}
</style>