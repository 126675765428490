<template>
	<div v-if='possibleMatches'>
      <modal v-show="possibleMatches.doShow"
      @submit="closePossibleMatchesModal" 
      :doSkipCancel='true'
      :doSkipHeader='true'
      customCssClasses="sm:max-w-7xl"
      affirmativeButtonLabel='Close'>
      <template v-slot:body>
        <div>
          <div v-if='possibleMatches.broadcast && showNewMatchesInModal'>
            <div class='mt-4 -mb-4 text-lg leading-6  text-gray-500'>
              Old method:<br />
              Your {{possibleMatches.broadcast.side}} broadcast 
              <span class='text-gray-900 font-medium'> {{possibleMatches.broadcast.subject}}</span><br/>

              <span v-if='possibleMatches.resultsOld && possibleMatches.resultsOld.length'>has the following recent possible {{possibleMatches.broadcast.side === 'WTS' ? 'WTB' : 'WTS'}} matches</span>
              <span v-else class='block mb-4'><strong>doesn't</strong> have any recent possible {{possibleMatches.broadcast.side === 'WTS' ? 'WTB' : 'WTS'}} matches</span>
            </div>
            <BroadcastList v-if='possibleMatches.resultsOld && possibleMatches.resultsOld.length' :isPossibleMatches='true' :possibleMatchId="possibleMatches.broadcastId" :broadcastsExplicit='possibleMatches.resultsOld'></BroadcastList>
          </div>
              <div class="w-full border-b border-gray-300 pb-4 my-2">
                <div v-if='settingsMap.enableBroadcastShareButtons == 1 && possibleMatches.broadcast' class='grid' style="grid-template-columns: auto 32px 32px 32px; gap: 8px;">
                  <div class="text-lg text-right pt-1 pr-3">
                    Share this broadcast
                  </div>

                  <div>
                    <a :href="'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://news.thebrokersite.com/?id='+possibleMatches.broadcast.id+'&bc=1&share=facebook')" target="_blank" rel="noopener" title="Share on Facebook"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                      <path d="M 85.033 90 C 87.776 90 90 87.776 90 85.033 V 4.967 C 90 2.223 87.776 0 85.033 0 H 4.967 C 2.223 0 0 2.223 0 4.967 v 80.066 C 0 87.776 2.223 90 4.967 90 H 85.033 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(60,90,153); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      <path d="M 50.916 83.204 V 48.351 h 11.699 l 1.752 -13.583 h -13.45 v -8.672 c 0 -3.933 1.092 -6.612 6.731 -6.612 l 7.193 -0.003 V 7.332 c -1.244 -0.166 -5.513 -0.535 -10.481 -0.535 c -10.37 0 -17.47 6.33 -17.47 17.954 v 10.017 H 25.16 v 13.583 h 11.729 v 34.853 H 50.916 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                    </g>
                    </svg></a>
                  </div>

                  <div>
                    <a :href="'https://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent('https://news.thebrokersite.com/?id='+possibleMatches.broadcast.id+'&bc=1&share=linkedin')" target="_blank" rel="noopener" title="Share on LinkedIn"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                      <path d="M 0 6.447 C 0 2.887 2.978 0 6.651 0 h 76.698 C 87.022 0 90 2.887 90 6.447 v 77.106 C 90 87.114 87.022 90 83.349 90 H 6.651 C 2.978 90 0 87.114 0 83.553 V 6.447 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,102,153); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      <path d="M 20.485 29.151 c 4.74 0 7.691 -3.121 7.691 -7.021 c -0.088 -3.988 -2.95 -7.022 -7.601 -7.022 c -4.65 0 -7.69 3.034 -7.69 7.022 c 0 3.9 2.95 7.021 7.512 7.021 H 20.485 L 20.485 29.151 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      <path d="M 27.282 75.339 v -40.64 H 13.688 v 40.64 H 27.282 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                      <path d="M 34.804 75.339 h 13.594 V 52.644 c 0 -1.215 0.088 -2.428 0.447 -3.296 c 0.983 -2.427 3.219 -4.94 6.975 -4.94 c 4.919 0 6.887 3.727 6.887 9.19 v 21.741 h 13.592 V 52.037 c 0 -12.483 -6.706 -18.291 -15.65 -18.291 c -7.333 0 -10.553 4.073 -12.342 6.847 h 0.091 v -5.894 H 34.804 C 34.982 38.513 34.804 75.339 34.804 75.339 L 34.804 75.339 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                    </g>
                    </svg></a>
                  </div>

                  <div>
                    <a :href="'https://twitter.com/intent/tweet?text=' + possibleMatches.broadcast.subject + '+' + encodeURIComponent('https://news.thebrokersite.com/?id='+possibleMatches.broadcast.id+'&bc=1&share=x')" target="_blank" rel="noopener" title="Share on X (Twitter)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="30" height="30" viewBox="0 0 256 256" xml:space="preserve">
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                      <polygon points="24.89,23.01 57.79,66.99 65.24,66.99 32.34,23.01 " style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
                      <path d="M 0 0 v 90 h 90 V 0 H 0 z M 56.032 70.504 L 41.054 50.477 L 22.516 70.504 h -4.765 L 38.925 47.63 L 17.884 19.496 h 16.217 L 47.895 37.94 l 17.072 -18.444 h 4.765 L 50.024 40.788 l 22.225 29.716 H 56.032 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                    </g>
                    </svg></a>
                  </div>
                </div>                
              </div>

          <div v-if='possibleMatches.broadcast'>
            <div class='mt-8 -mb-4 text-lg leading-6  text-gray-500'>
              <div v-if="showNewMatchesInModal">New AI method:<br /></div>
              <div class="custom-grid">
                <div>
                  Your {{possibleMatches.broadcast.side}} broadcast 
                  <span class='text-gray-900 font-medium'>{{possibleMatches.broadcast.subject}}</span><br/>
                  <span v-if='possibleMatches.results && possibleMatches.results.length'>has the following recent possible {{possibleMatches.broadcast.side === 'WTS' ? 'WTB' : 'WTS'}} matches</span>
                  <span v-else class='block mb-4'><strong>doesn't</strong> have any recent possible {{possibleMatches.broadcast.side === 'WTS' ? 'WTB' : 'WTS'}} matches</span>
                </div>
                <div class="mr-0"><img src="/img/ai_powered_grey.png" style="width: 60px;"></div>
              </div>
            </div>

            <BroadcastList v-if='possibleMatches.results && possibleMatches.results.length' :isPossibleMatches='true' :possibleMatchId="possibleMatches.broadcastId" :broadcastsExplicit='possibleMatches.results'></BroadcastList>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class='float-left  mb-2' v-if='possibleMatches.results && possibleMatches.results.length'>
          <div class="sm:absolute sm:left-0 ml-8 text-sm">
            <label for="disableSuggestions">
              <input v-model="user.possibleMatchesDisabled" type="checkbox" id="disableSuggestions" class="float-left h-4 w-4 text-red-600 border-gray-300 focus:ring-red-500">
              <span class="ml-2 -mt-0.5 font-medium float-left text-gray-900">Don't show these possible broadcast matches again</span>
            </label>
          </div>
        </div>
      </template>
    </modal>
	</div>
</template>

<script>

import { mapState, mapActions} from 'vuex';
import modal from '@/components/modal.vue'
import BroadcastList from '@/components/BroadcastList.vue'

export default {
  data() {
    return {
      showNewMatchesInModal: false,
      isOwnBroadcast: true,
    }
  },
  name: 'ModalPossibleMatches',
  
  components: {
    modal,
    BroadcastList,
	},
  mounted() {
    this.initializeWatcher()
  },
  computed: {
  	...mapState(['user', 'settingsMap']),
    possibleMatches(){
      return this.$parent.$parent.possibleMatches
    },
  }, 
  methods: {
  	...mapActions([]),
		closePossibleMatchesModal(){
			this.$parent.$parent.possibleMatches.doShow = false
		},
    initializeWatcher() {
      // Ensure each parent level exists to avoid errors
      if (this.$parent && this.$parent.$parent) {
        this.$watch(
          () => this.$parent.$parent.showNewMatches,
          (newValue, oldValue) => {
            // Logic to execute when showNewMatches changes
            // console.log(`showNewMatches changed from ${oldValue} to ${newValue}`);
            this.handleShowNewMatchesChange(newValue);
          },
          {
            immediate: true, // Run the handler immediately with the current value
            deep: true // Watch for nested changes, though not necessary for a simple boolean
          }
        )
      } else {
        console.error('Parent components are not properly set up.');
      }
    },
    handleShowNewMatchesChange(newValue) {
      // Implement what should happen when showNewMatches changes
      this.showNewMatchesInModal = newValue
    },
  }
}
</script>
<style>
.custom-grid {
  display: grid;
  grid-template-columns: 1fr 120px; /* 1fr for the first column and 120px for the second */
}
</style>
