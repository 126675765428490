<template>
	<base-layout>
		<template v-slot:header>
			About The Broker Site BV
		</template>
		<template v-slot:main>
			<div >
				<div class='bg-white shadow overflow-hidden sm:rounded-lg pt-2 pb-6 px-6 mb-4'>
					<h2 class="text-lg font-bold leading-5 text-red-700 mt-4 mb-4">The Broker Site BV</h2>
					www.TheBrokerSite.com (TBS) is the leading B2B e-marketplace for buying and selling computer parts and equipment in Europe. TBS was founded in 1999, and has grown to become the leading European on-line platform for buying and selling used, refurbished and new hardware.
					<br /><br />
					<em>Vision</em><br />
					A safe online trading environment for the computer industry.
					<br /><br />
					<em>Mission</em><br />
					For the last couple of years TBS has been the leading European Online Computer Trading Exchange. TBS aims to keep this position and grow even further by providing a simple, reliable and cost effective online trading exchange.
					<br /><br />
					<em>Trademark</em><br />
					The Broker Site has been trademarked since January 2010<br />
					<a href='../media/TBS_Trademark.pdf' target="_blank" class='text-red-600 hover:underline '>Trademark (PDF)</a>
					<br /><br />
					<em>Statistics (as of 29/04/2024)</em><br />
					Number of members: 2800+<br />
					Different countries represented: 74<br />
					Daily Average sessions: >3,000<br />
					Daily Average Page Views: >18,000<br />
					Broadcasts sent on a daily basis: >300,000<br />
					Parts in Database: >2,000,000
					<br /><br />
					<em>The Broker Site LOGO (1280x243)</em><br />
					<a href="../media/img/tbs_logo.jpg" target="_blank" class='text-red-600 hover:underline '>The Broker Site Logo JPEG</a><br />
					<a href="../media/img/tbs_logo.png" target="_blank" class='text-red-600 hover:underline '>The Broker Site Logo PNG</a><br />
					<a href="../media/img/tbs_logo.bmp" target="_blank" class='text-red-600 hover:underline '>The Broker Site Logo BMP</a><br />
					<a href="../media/img/tbs_logo.gif" target="_blank" class='text-red-600 hover:underline '>The Broker Site Logo GIF</a><br />
					<a href="../media/img/tbs_logo.tif" target="_blank" class='text-red-600 hover:underline '>The Broker Site Logo TIFF</a><br />
				</div>
			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'AboutTBS',
  components: {
    BaseLayout
  }
}
</script>