<template>
	<base-layout>
		<template v-slot:header>
			Billing History <span class='text-base'>(since 2021)</span>
		</template>
		<template v-slot:main>
			<div v-if='invoices.length'>
        <h2 class='mb-2 text-xl font-semibold leading-7 text-gray-900 sm:truncate'>
          <!-- The Broker Site -->
        </h2>
        <InvoiceTable></InvoiceTable>
			</div>

			<div v-if='creditNotes.length'>
        <h2 class='mt-12 mb-2 text-xl font-semibold leading-7 text-gray-900 sm:truncate'>
          <!-- The Broker Site -->
        </h2>
        <CnTable></CnTable>
			</div>
		</template>
	</base-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import _ from 'lodash'
import BaseLayout from '@/components/BaseLayout.vue'
import InvoiceTable from '@/components/InvoiceTable.vue'
import CnTable from '@/components/CnTable.vue'

export default {
  name: 'Invoices',
  components: {
    BaseLayout,
    InvoiceTable,
    CnTable
  },
  async created(){
    await this.getInvoices()
    await this.getCreditNotes()
  },
  computed: {
    ...mapState(["user", 'invoices', 'creditNotes']),
    invoicesEnriched(){
      return _.map(this.invoices, invoice => {
        invoice = this.enrichInvoiceGeneric(invoice)
        if (invoice.account === "Plus") {accType = "TBS Plus Account";}
        else if (invoice.account === "Broker") {accType = "TBS Broker Account";}
        else if (invoice.account === "EBM") {accType = "EBM Attendance";}
        else {var accType = "TBS Broker Account";}
        return _.extend({
          // accountType: invoice.account === "Plus" ? "TBS Plus Account" : "TBS Broker Account",
          accountType: accType
        }, invoice)
      })
    },
    creditNotesEnriched(){
      return _.map(this.creditNotes, creditNote => {
        creditNote = this.enrichCreditNoteGeneric(creditNote)
        if (creditNote.account === "Plus") {accType = "TBS Plus Account";}
        else if (creditNote.account === "Broker") {accType = "TBS Broker Account";}
        else if (creditNote.account === "EBM") {accType = "EBM Attendance";}
        else if (creditNote.account === "Custom") {accType = "Cancellation or refund";}
        else {var accType = "TBS Broker Account";}
        return _.extend({
          // accountType: creditNote.account === "Plus" ? "TBS Plus Account" : "TBS Broker Account",
          accountType: accType
        }, creditNote)
      })
    },
  },
  methods: {
    enrichInvoiceGeneric(invoice){
      let paymentStatusHuman;
      let paymentStatusClasses;
      if(invoice.paymentStatus){
          if ((invoice.paymentStatus==1) || (invoice.paymentStatus==2)) {
            paymentStatusHuman = "Paid"
            paymentStatusClasses = "bg-green-100 text-green-800"
          } else if (invoice.paymentStatus==9) {
            paymentStatusHuman = "VOID"
            paymentStatusClasses = "bg-red-100 text-red-800"
          }
      } else if(invoice.isPastDue){
        paymentStatusHuman = "Overdue"
        paymentStatusClasses = "bg-red-100 text-red-800"
      } else {
        paymentStatusHuman = "Due"
        paymentStatusClasses = "bg-orange-100 text-orange-800"
      }
      return _.extend({ 
        paymentStatusHuman,
        paymentStatusClasses
      }, invoice)
    },
    ...mapActions(["getInvoices"]),

    enrichCreditNoteGeneric(creditNote){
      let paymentStatusHuman;
      let paymentStatusClasses;
      if(creditNote.paymentStatus){
          if ((creditNote.paymentStatus==1) || (creditNote.paymentStatus==2)) {
            paymentStatusHuman = "Paid"
            paymentStatusClasses = "bg-green-100 text-green-800"
          } else if (creditNote.paymentStatus==9) {
            paymentStatusHuman = "VOID"
            paymentStatusClasses = "bg-red-100 text-red-800"
          }
      } else if(creditNote.isPastDue){
        paymentStatusHuman = "Overdue"
        paymentStatusClasses = "bg-red-100 text-red-800"
      } else {
        paymentStatusHuman = "Due"
        paymentStatusClasses = "bg-orange-100 text-orange-800"
      }
      return _.extend({ 
        paymentStatusHuman,
        paymentStatusClasses
      }, creditNote)
    },
    ...mapActions(["getCreditNotes"]),
  }
}


</script>
