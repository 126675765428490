<template>
	<base-layout>
		<template v-slot:header>
			Privacy Policy
		</template>
		<template v-slot:main>
			<div class='text-sm'>
				
				<h2 class="text-xl font-bold leading-7 text-gray-900 mt-8 ">What information do we collect?</h2>

				<p>
				We collect information from you when you register on our site, place an order, subscribe to our newsletter or fill out a form.
				</p>
				<p>
				When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address, mailing address, phone number or Company Information. You may, however, visit our site anonymously.
				</p>



				<h2 class="text-xl font-bold leading-7 text-gray-900 mt-8 ">What do we use your information for?</h2>

				<p>
				Any of the information we collect from you may be used in one of the following ways:
				To improve our website
				(we continually strive to improve our website offerings based on the information and feedback we receive from you).
				To improve customer service
				(your information helps us to more effectively respond to your customer service requests and support needs).
				To process transactions
				Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.
				To send periodic emails
				The email address you provide for order processing, will only be used to send you information and updates pertaining to your order.
				</p>

				<p class='mt-4'>
				Note: If at any time you would like to unsubscribe from receiving future emails, Please send an email requesting to stop your account to Sales@TheBrokerSite.com.
				</p>



				<h2 class="text-xl font-bold leading-7 text-gray-900 mt-8 ">How do we protect your information?</h2>

				<p>
				We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.
				</p>
				<p>
				After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.
				</p>



				<h2 class="text-xl font-bold leading-7 text-gray-900 mt-8 ">Do we use cookies or local storage?</h2>

				<p>
				Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.
				</p>
				<p class='mt-4'>
				We use browser local storage only for authentication of your account on www.TheBrokerSite.com. We also use Google Analytics which uses its own cookies that may be stored on your computer, according to Google's own policies. These cookies are called 3rd party cookies.
				These cookies and other forms of local storage on your device can be deleted by you at any time. However, this may impact the functioning of our website. Blocking the use of cookies will also limit the functioning of our website.
				</p>



				<h2 class="text-xl font-bold leading-7 text-gray-900 mt-8 ">Do we disclose any information to outside parties?</h2>

				<p>
				We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
				</p>



				<h2 class="text-xl font-bold leading-7 text-gray-900 mt-8 ">Third party links</h2>

				<p>
				Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
				</p>



				<h2 class="text-xl font-bold leading-7 text-gray-900 mt-8 ">Your Consent</h2>

				<p>
				By using our site, you consent to our website's privacy policy.
				</p>



				<h2 class="text-xl font-bold leading-7 text-gray-900 mt-8 ">Changes to our Privacy Policy</h2>

				<p>
				If we decide to change our privacy policy, we will post those changes on this page.
				</p>



				<h2 class="text-xl font-bold leading-7 text-gray-900 mt-8 ">Contacting Us</h2>

				<p>
				If there are any questions regarding this privacy policy you may contact us using the information below.
				</p>

				<p class='mt-4'>
				www.TheBrokerSite.com<br/>
				P.O. Box 4011<br/>
				Oosterhout, Noord Brabant 4900CA<br/>
				The Netherlands<br/>
				Sales@TheBrokerSite.com<br/>
				+31 621546725<br/>
				</p>

			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'Privacy',
  components: {
    BaseLayout
  }
}
</script>