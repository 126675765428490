<template>

	<div>	
		<!-- Table Footer / Pagination --> 
		<div v-if='numResults'>
			<div v-if='!$parent.isHome' class="py-4 flex items-center justify-between border-t border-gray-200">
				<div class="flex-1 flex justify-between md:hidden">

					<button v-if="payload.page > 1" type="button" @click='pageTo("previous")' 
						class="relative items-center btn btn-muted text-indigo-500 focus:outline-none focus:shadow-none">Back</button>
					<button v-else type="button" disabled class="relative items-center btn btn-gray text-gray-600 focus:outline-none focus:shadow-none">Back</button>

					<button v-if="payload.page <= Math.floor(numResults / pageSize)" 
						type="button" @click='pageTo("next")' class="ml-3 relative inline-flex items-center btn btn-muted text-indigo-500 focus:outline-none focus:shadow-none">Next</button>
					<button v-else type="button" disabled class="relative items-center btn btn-gray text-gray-600 focus:outline-none focus:shadow-none">Next</button>
				</div>

				<div class="hidden md:flex-1 md:flex md:items-center md:justify-between">
					<div>
						<p v-if='numResults' class="text-sm leading-5 text-gray-700">
						Showing
							<span class="font-medium">{{((payload.page - 1) * pageSize) + 1 }}</span>
							to
							<span class="font-medium">{{((payload.page - 1) * pageSize) + itemList.length }}</span>
							of
							<span class="font-medium">{{numResults}}</span>
						results
						</p>
					</div>
					<div>
						<span v-if='numResults' class="relative z-0 inline-flex shadow-sm">
							
							<button v-if="payload.page > 1" type="button" class="btn btn-muted px-2 rounded-none rounded-l-md text-indigo-500 focus:outline-none focus:shadow-none"  @click='pageTo("previous")'>
								<svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
									<path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
								</svg>
							</button>
							<button v-else type="button" disabled class="btn btn-gray px-2 rounded-none rounded-l-md text-gray-600 focus:outline-none focus:shadow-none" >
								<svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
									<path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
								</svg>
							</button>

							<button v-if="!numResults" type="button" disabled class="pageItem pageItem-spacer">No Results</button>
							<button v-else v-for="(pageItem) in paginationConfig" :key="pageItem.num" @click='!pageItem.isSpacer ? pageTo(pageItem.num): void(0)' type="button" :disabled="pageItem.isSpacer" class="pageItem" :class='{"pageItem-active": pageItem.isActive,  "pageItem-spacer": pageItem.isSpacer}'>
								{{pageItem.isSpacer ? "..." : pageItem.num}}
							</button>

							<button v-if="payload.page <= Math.floor(numResults / pageSize)" 
								type="button" class="-ml-px btn btn-muted px-2 rounded-none rounded-r-md text-indigo-500 focus:outline-none focus:shadow-none transition" @click='pageTo("next")'>
								<svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
									<path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
								</svg>
							</button>
							<button v-else type="button" disabled  class="-ml-px btn btn-gray px-2 rounded-none rounded-r-md text-gray-600 focus:outline-none focus:shadow-none">
								<svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
									<path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
								</svg>
							</button>

						</span>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="text-sm leading-5 text-gray-700 font-bold">
			<div v-if='$parent.activeTab === "1"'>You currently do not have any broadcasts.</div>
			<div v-else>No results found for this query, please broaden your search.</div>
		</div>
	</div>

</template>

<script>

	export default {
		props: {
			pageSize: Number,
			itemList: Array,
			payload: {
				type: Object,
				default(){
					return {
						query: null, 
			  		listType: null, 
			  		type: null, 
			  		page: 1,
					}
				}
			},
		},
		data: () => ({
			grandParentWithData: null,
		}),
		created(){
			// ugly hack: getting parent or grandparent. Depends on where this component is used. 
			this.grandParentWithData = this.$parent.pageTo ? this.$parent : this.$parent.$parent;
		},
		computed: {
			numResults(){
				return this.grandParentWithData.numResults
			},
			paginationConfig(){
				return this.grandParentWithData.paginationConfig
			}
		},
  	methods: {
  		pageTo(to){
  			this.grandParentWithData.pageTo(to);
  		}
  	}
	}

</script>
