<template>
	<base-layout>
		<template v-slot:header>
			Contract Terms
		</template>
		<template v-slot:main>
			<div >
				<ContractComponent></ContractComponent>
			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import ContractComponent from '@/components/ContractComponent.vue'
	
export default {
  name: 'Contract',
  components: {
    BaseLayout,
    ContractComponent
  }
}
</script>
