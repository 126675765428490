<template>
	<base-layout>
		<template v-slot:header>
			RSS Subscription
		</template>
		<template v-slot:main>
			<div class='text-sm'>
				You can subscribe to the RSS for the search you just performed by clicking on the URL below, or by copying it to your RSS reader:<br/>
				<a :href='rssURL' class='text-red-600 hover:underline text-sm mt-2'>{{rssURL}}</a>

				<p class='mt-2'>For more instructions on how to subscribe to your search results, see below.</p>

				<h2 class="text-xl font-bold leading-7 text-gray-900 mt-8 ">How to use RSS</h2>

				<h2 class="text-l font-bold leading-5 text-red-700 mt-4 mb-2">RSS Feeds for every Broadcast Search</h2>
				www.TheBrokerSite.com now offers the possibility to subscribe to a search on the Broadcast, this means that you will receive the Broadcasts that match your specific search with your RSS reader.


				<h2 class="text-l font-bold leading-5 text-red-700 mt-4 mb-2">Why RSS?</h2>
				It allows you to easily stay informed by retrieving the latest broadcasts from www.TheBrokerSite.com you are interested in. You save time by not needing to visit the site each time or reading through all the emails, you only get to see the messages you are interested in your RSS reader.

				<h2 class="text-l font-bold leading-5 text-red-700 mt-4 mb-2">What do I need?</h2>
				Feed Reader or News Aggregator software allows you to grab the RSS feeds from various sites and display them for you to read and use.

				<h2 class="text-l font-bold leading-5 text-red-700 mt-4 mb-2">Where do I get the RSS reader?</h2>
				Some of the most commonly used programs which offer RSS reading are Microsoft Outlook, Mozilla FireFox and Mozilla ThunderBird.

			<!-- 	Commented out until help page is up-to-date with new videos
				<h2 class="text-l font-bold leading-5 text-red-700 mt-4 mb-2">How does it Work?</h2>
				<ul>
					<li>RSS using Microsoft Office Outlook</li>
					<li>RSS using Mozilla FireFox</li>
					<li>RSS using Mozilla ThunderBird</li>
				</ul>
				 -->


			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'RSS',
  components: {
    BaseLayout
  },
  computed: {
  	rssURL(){
  		const loc = window.location;
  		const isDevMode = loc.port === "8080";
  		const p = this.$route.fullPath;
			let q = ""
			if(~p.indexOf("?")){
				q = "&" + p.substring(p.indexOf("?") + 1);
			}
			const url = (!isDevMode ? 'https://www.thebrokersite.com/api/broadcasts?contentType=rss' : `${loc.protocol}//${loc.hostname}:3000/api/broadcasts?contentType=rss`) + q;
			return url;

  	}
  },
}
</script>