<template>
	<base-layout>
		<template v-slot:header>
			Help and Tutorials
		</template>
		<template v-slot:main>
			<div v-if='selectedVideo' class='mb-8 pb-16 border-b text-lg leading-6 font-medium space-y-1'>
				<div itemprop="video" itemscope itemtype="http://schema.org/VideoObject"  class="relative pb-2/3">
					<h4 itemprop="name" class='pb-2'>{{mainVideo.title}}</h4>
					<iframe class="absolute object-cover h-full w-full shadow-lg rounded-lg" :src="mainVideo.embedUrl" frameborder="0" allowfullscreen>
					</iframe>
					<span class="classyschema-videoobject-embedurl" :data-content="mainVideo.embedUrl" style="display: none;" itemprop="embedUrl" :content="mainVideo.embedUrl">{{mainVideo.embedUrl}}</span>
					<div itemprop="potentialAction" itemscope itemtype="http://schema.org/SeekToAction"><meta itemprop="target" :content="mainVideo.url"><meta itemprop="startOffset-input" content="required name=seek_to_second_number"></div>
					<span class="classyschema-videoobject-seektoaction" :data-content="mainVideo.url" style="display: none;">{{mainVideo.url}}</span>
				</div>
			</div>
			<div>
				<ul class="space-y-12 sm:grid sm:grid-cols-2 sm:col-gap-6 sm:row-gap-12 sm:space-y-0 lg:grid-cols-3 lg:col-gap-8">
					<li v-for='item in items' :key='item.embedUrl' class='mb-8'>
						<div v-if='selectedVideo!=item.id' class="space-y-4">
							<div class="relative pb-2/3">
							<iframe class="absolute object-cover h-full w-full shadow-lg rounded-lg" :src="item.embedUrl" frameborder="0" allowfullscreen>
								</iframe>
							</div>

							<div class="space-y-2">
							<div class="text-lg leading-6 font-medium space-y-1 mt-4">
								<h4>{{item.title}}</h4>
							</div>
							</div>
						</div>
					</li>
				</ul>
			</div>

			<div class="mt-8 pb-4 ">
				<div class="relative">
					<div class="absolute inset-0 h-1/2 bg-gray-100"></div>
					<div class="relative max-w-screen-xl mx-auto px-0">
						<div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex bg-white">
							<div class="bg-white px-6 py-8 lg:p-12">
								<h3 class="text-xl leading-6 font-bold text-gray-900 sm:text-xl">Frequently Asked Questions</h3>
								<div class="mt-8">
									<ul class="space-y-3">
										<li v-for='faq in faqs' :key='faq.id' @click='toggleFaq(faq.id)'>
											<div class="text-md font-bold cursor-pointer">
												{{faq.question}}
											</div>
											<Transition name="fade">
											<div v-show="expandedFaqId===faq.id" class="text-md pb-4">
												{{faq.answer}}
											</div>
											</Transition>
										</li>
									</ul>

									<div class="mt-8">
										For any other questions you may still have, please <a href="/contact" target="_blank" rel="noopener" class="text-red-600 hover:underline">Contact us</a> or visit our <a href="/about" target="_blank" rel="noopener" class="text-red-600 hover:underline">About page</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</base-layout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  name: 'Help',
  components: {
    BaseLayout
  },
  async created(){
  },
  computed: {
		selectedVideo() {return this.$route.query.id },
		mainVideo() {
			let videoEmbedUrl = ''
			let videoUrl = ''
			let videoTitle = ''
			for (var i=0; i<this.items.length; i++) {
				if (this.selectedVideo==this.items[i].id) {
					videoUrl = this.items[i].url
					videoEmbedUrl = this.items[i].embedUrl
					videoTitle = this.items[i].title
				}
			}
			return {embedUrl: videoEmbedUrl, url: videoUrl, title: videoTitle}
		}
  },
  methods: {
		toggleFaq(faqId) {
			this.expandedFaqId = this.expandedFaqId === faqId ? null : faqId
		},
  },
  data: () => ({
	expandedFaqId: false,
  	items: [
	  	{
			id: 1,
	  		title: "How to send TBS Broadcasts", 
			url: "https://youtu.be/m-oUKUkDww0?t={seek_to_second_number}",
	  		embedUrl: "https://www.youtube.com/embed/m-oUKUkDww0",
			category: "broadcasts",
	  	},
	  	{
			id: 2,
	  		title: "How to search for and save Broadcasts", 
			url: "https://youtu.be/qBof1HcldRY?t={seek_to_second_number}",
	  		embedUrl: "https://www.youtube.com/embed/qBof1HcldRY",
			category: "broadcasts",
	  	},
	  	// {
		// 	id: 2,
	  	// 	title: "How to save TBS Broadcast Searches", 
	  	// 	url: "https://www.youtube.com/embed/1",
		// 	category: "broadcasts",
	  	// },
	  	// {
		// 	id: 3,
	  	// 	title: "How to change your Broadcast Settings", 
	  	// 	url: "https://www.youtube.com/embed/2",
		// 	category: "broadcasts",
	  	// },
  		// {
		// 	id: 4,
	  	// 	title: "How to use the TBS Parts Search", 
	  	// 	url: "https://www.youtube.com/embed/IyiJVong2pI", 
		// 	category: "parts",
	  	// },
	  	// {
		// 	id: 5,
	  	// 	title: "How to use the TBS Company Search", 
	  	// 	url: "https://www.youtube.com/embed/EtZpxe0Xg1M", 
		// 	category: "companies",
	  	// },
  	],
	faqs: [
		{
			id: 1,
			question: "I cannot login, what do I do now?",
			answer: "To login to www.TheBrokerSite.com, you need to use your email address and password. If you forgot your password, you can click on 'Sign In' at the right top of the page, and click on the 'Forgot your password?' link. This will send you an email with a link and instructions on how to reset your password. Always make sure to also check your spam or junk mail folder as it may end up in there.",
		},
		{
			id: 2,
			question: "My account has been suspended, what can I do?",
			answer: "There can be different reasons why an account gets suspended. After logging into your account, the prompt will tell you exactly what needs to be done in order to re-activate your account. Usually this is because of a payment that is due, so always make sure any outstanding invoices are paid.",
		},
		{
			id: 3,
			question: "How can I pay for TBS Membership?",
			answer: "At the bottom of every page on our website, you can click on the 'Pay TBS Membership' link. This will allow you to choose which account type you would like to pay and provide further payment instructions. If you are logged into www.TheBrokerSite.com, you can always click on your profile icon at the top right, and go to the Billing History page. Any outstanding invoice will be listed here, which you can download and open. Clicking on the link in the invoice will redirect you to the online payment section to complete your payment.",
		},
		{
			id: 4,
			question: "What are the advertising possibilities on www.TheBrokerSite.com?",
			answer: "We have a banner rotation system that randomly shows 4 advertising banners on every page on www.TheBrokerSite.com. Banner advertisements are contracted on an annual basis, and we limit the number of banners that are allowed in our rotation. These advertisement slots are very popular and fill up fast. To advertise your banner, please contact us at sales@thebrokersite.com.",
		},
		{
			id: 5,
			question: "How can I promote my services on www.TheBrokerSite.com?",
			answer: "The TBS Services page is a great way to showcase your core business and expose your company to thousands of views every day. Your company will be listed in one or more categories on our Services page and includes promotional posts on our social media accounts. For more information, please contact rhody@thebrokersite.com.",
		},
	]
  })
}
</script>
<style scoped>
.fade-enter-active {
	transition: opacity .5s;
}
.fade-leave-active {
	transition: opacity .0s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>