<template>
	<base-layout>
		<template v-slot:header>
			<div v-if="!part">
				Page not found
			</div>

			<div v-else>
				{{ part.partnumber }}
				<div class="text-sm text-gray-400">{{ part.manufacturer }}</div>
			</div>
		</template>
		<template v-slot:main>
			<div v-if="!part">
				<strong>Oops! The content you are looking for is not available or no longer exists.</strong>
                <br><br>
                Please check the spelling of the URL you requested and try again.<br>
                Feel free to <router-link to='/contact' class='text-red-600 hover:underline'>contact us</router-link> and let us know what information you are looking for if you think you are viewing this message by mistake.
                <br><br>
                <router-link to='/broadcasts' class='text-red-600 hover:underline'>Looking for Broadcasts?</router-link><br>
                <router-link to='/parts' class='text-red-600 hover:underline'>Looking for Parts?</router-link><br>
                <router-link to='/companies' class='text-red-600 hover:underline'>Looking for Companies?</router-link><br><br>
                <router-link to='/news?catId=1' class='text-red-600 hover:underline'>Fraud warnings</router-link><br>
                <router-link to='/news' class='text-red-600 hover:underline'>TBS News</router-link><br><br>
                <router-link to='/' class='text-red-600 hover:underline'>TBS Homepage</router-link><br>
                <br><br>
                <br><br>
			</div>

			<div v-else>

				<div v-if="!user" class='mb-6 text-sm btn btn-muted text-red-700 border-red-700 hover:text-red-700 block'>
					<a href='javascript:void(0)' @click="toFreeTrialPage" class='hover:underline'>Please register here for your free trial to see full company details!</a>
				</div>

				<div v-if="!part">
					Page not found
				</div>
				<div v-else>
					<div class="grid grid-cols-12 gap-6">
						<div class="col-span-12 lg:col-span-6 bg-white shadow overflow-hidden sm:rounded-lg">
							<div class="px-4 py-5 border-b border-gray-200 sm:px-6">
								<h3 class="text-lg leading-6 font-medium text-gray-900">Item Details</h3>
							</div>
							<div class="px-4 py-5 sm:px-0">
								<dl class='mt-2 mb-4'>
									<div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt class="text-m text-gray-600">Part Number</dt>
										<dd class="mt-1 text-m text-gray-600 sm:mt-0 sm:col-span-2">{{part.partnumber || '-'}}</dd>
									</div>
									<div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt class="text-m text-gray-600">Manufacturer</dt>
										<dd class="mt-1 text-m text-gray-600 sm:mt-0 sm:col-span-2">{{part.manufacturer || '-'}}</dd>
									</div>
									<div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt class="text-m text-gray-600">Description</dt>
										<dd class="mt-1 text-m text-gray-600 sm:mt-0 sm:col-span-2">{{part.description || '-'}}</dd>
									</div>
									<div v-if="showStats.countries" class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt class="text-m text-gray-600">Conditions available</dt>
										<dd class="mt-1 text-m text-gray-600 sm:mt-0 sm:col-span-2">{{showStats.allConditions}}</dd>
									</div>
								</dl>
							</div>
						</div>
						<div class="col-span-12 lg:col-span-6 bg-white shadow overflow-hidden sm:rounded-lg">
							<div class="px-4 py-5 border-b border-gray-200 sm:px-6">
								<h3 class="text-lg leading-6 font-medium text-gray-900">Statistics and availability</h3>
							</div>
							<div class="px-4 py-5 sm:px-0">
								<dl class='mt-2 mb-4'>
									<div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt class="text-m text-gray-600">Lowest price</dt>
										<dd class="mt-1 text-m text-gray-600 sm:mt-0 sm:col-span-2">{{showStats.minPrice}}</dd>
									</div>
									<div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt class="text-m text-gray-600">Highest price</dt>
										<dd class="mt-1 text-m text-gray-600 sm:mt-0 sm:col-span-2">{{showStats.maxPrice}}</dd>
									</div>
									<div class="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
										<dt class="text-m text-gray-600">Total quantity</dt>
										<dd class="mt-1 text-m text-gray-600 sm:mt-0 sm:col-span-2">{{showStats.totalQuantity}}</dd>
									</div>
									<div class="px-4 py-2 sm:grid sm:px-6 text-gray-600">
										<span v-if="showStats.traderCount!==0">Available with {{showStats.traderCount}} on TBS</span>
										<span v-else>
											Currently not available with any traders on TBS<br>
											You can also check out our <router-link :to="'/broadcasts?query=' + origPartNumber" class='text-red-600 hover:underline' target="_blank">broadcasts</router-link> for more availability!
										</span>
									</div>
									<div v-if="showStats.traderCount!==0" class="px-4 py-2 sm:grid sm:px-6 text-gray-600">
										From {{showStats.countries}}
									</div>
									<div v-if="showStats.traderCount!==0" class="px-4 py-2 sm:px-6 mt-2 text-gray-600">
										<strong>Did not find what you're looking for?</strong> You can also check out our <router-link :to="'/broadcasts?query=' + origPartNumber" class='text-red-600 hover:underline' target="_blank">broadcasts</router-link> for more availability!
									</div>
								</dl>
							</div>
						</div>
					</div>

					<div v-if="showStats.results!==0">
						<div v-if="showStats.results===1" class="mt-8 font-medium">TBS member trading this part:</div>
						<div v-else class="mt-8 font-medium">All TBS members trading this part:</div>

						<div class="flex flex-col mt-1 sm:mt-8 mb-4 sm:mb-12">
							<div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">

								<div v-if='allTraders' >
									<!-- Big screen --> 
									<div class="align-middle hidden lg:inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
										<table class="w-full table-fixed">
											<thead>
												<tr>
													<th class="table-cell-header hover:text-red-700 w-24" @click='sort("Description")'>Partnumber / Description</th>
													<th class="table-cell-header hover:text-red-700 w-12" @click='sort("Manufacturer")'>Manufacturer</th>
													<th class="table-cell-header hover:text-red-700 w-6" @click='sort("Quantity")'>Quantity</th>
													<th class="table-cell-header hover:text-red-700 w-6" @click='sort("PartCondition")'>Condition</th>
													<th class="table-cell-header w-10" @click='sort("Price")'>
														<span class='hover:text-red-700'>Price</span>
														<!-- <div class='inline-block'>
															<a href='javascript:void(0)' @click.stop='changeCurrency("EUR")' class='text-red-600 hover:text-red-700 ml-2'>€</a>
															<a href='javascript:void(0)' @click.stop='changeCurrency("USD")' class='text-red-600 hover:text-red-700 ml-1'>$</a>
															<a href='javascript:void(0)' @click.stop='changeCurrency("GBP")' class='text-red-600 hover:text-red-700 ml-1'>£</a>	 
														</div> -->

													</th>
													<th class="table-cell-header pr-0 pl-0 w-2"><!-- Emblem gold/trusted --></th>
													<th class="table-cell-header pl-2 hover:text-red-700 w-20" @click='sort("inv_companyname")'>Company</th>
													<th class="table-cell-header w-8"></th>
												</tr>
											</thead>
											<tbody>
												<tr @click="navToPart(item, $event)" v-for="(item, index) in allTraders" :key="index" class="bg-white hover:bg-red-50 cursor-pointer transition duration-150 ease-in-out hover-higlight-anchor" :class='{"": index % 2}'>
													<td class="table-cell text-gray-900 hover-higlight">
														<div class="text-red-600 font-semibold leading-4  max-w-72  hover-higlight transition duration-150 ease-in-out truncate">{{item.Partnumber}}</div>
														<div class='leading-4 max-w-72 truncate' :title='item.description'>{{item.Description}}</div>
													</td>
													<td class="table-cell">{{item.Manufacturer}}</td>
													<td class="table-cell">{{item.Quantity}}</td>
													<td class="table-cell">{{item.PartCondition}}</td>
													<td class="table-cell">
														<span v-if="item.Price != '0'">{{currencySymbol}} {{item.Price}}</span>
														<span v-else>CALL</span>
													</td>
													<td class="table-cell pr-0 pl-0">
														<div v-if="item.inv_trusted==2" class='member_gold_small' title="This company is a 'Gold Member'. Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
														<div v-if="item.inv_trusted==1" class='member_trusted_small' title="This company is a 'Trusted Member'. Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
													</td>
													<td class="table-cell text-gray-500 company-cell font-semibold pl-2"  :title='item.inv_companyname' @click.stop="toCompanyPage(item.CompanyID)">
														<!-- <div v-if="item.isGold || item.isTrusted" class='truncate inline-block max-w-full align-middle pr-16'>
															<span v-if="item.isGold" title='Gold member' class="tag ml-2 bg-yellow-200 text-yellow-800 float-right -mr-16"><div class='member_gold'></div></span>
															<span v-if="item.isTrusted" title='Trusted member' class="tag ml-2 bg-gray-100 text-gray-800 float-right -mr-16"><div class='member_trusted'></div></span>
															{{item.companyName}}
														</div>
														<div v-else>{{item.companyName}}</div> -->
														<div class='max-w-72 truncate'>{{item.inv_companyname}}</div>
														<div class='leading-4 text-gray-500 font-normal text-xs max-w-72 truncate'>
															{{item.inv_country}} &bull; {{item.inv_telephone}}
															<!-- {{item.country}} -->
														</div>
													</td>

													<td class="table-cell">
														<button @click.stop='$refs.modalRFQ.openModal(item)' class="relative items-center btn btn-muted text-red-700 border-red-700 hover:text-red-700">RFQ</button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<!-- Small screen --> 
									<div class="align-middle inline-block lg:hidden min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
										<table class="w-full table-fixed">
											<thead>
												<tr>
													<th class="table-cell-header hover:text-red-700 w-24">Parts</th>
												</tr>
											</thead>
											<tbody>
												<tr @click="navToPart(item, $event)" v-for="(item, index) in allTraders" :key="index" class="bg-white hover:bg-red-50 cursor-pointer transition duration-150 ease-in-out hover-higlight-anchor" :class='{"": index % 2}'>
													<td class="table-cell text-gray-900 hover-higlight">
														<div class="text-red-600 ">
															<a href='javascript:void(0)' @click.stop='$refs.modalRFQ.openModal(item)' class="float-right ml-2 inline-block text-red-700 underline">[RFQ]</a>
															<div class='truncate mr-12'>
																<span v-if='item.Manufacturer'>{{item.Manufacturer}} - {{item.Partnumber}}</span>
																<span v-else>{{item.Partnumber}}</span>
															</div>
														</div>
														<div class='truncate font-semibold' :title='item.companyName' @click.stop="toCompanyPage(item.CompanyID)">
															<div v-if="item.isDiamond" class='member_diamond float-left mr-2' title="This company is a 'Diamond Member'. Diamond members have been on www.TheBrokerSite.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
															<div v-if="item.isGold" class='member_gold float-left mr-2' title="This company is a 'Gold Member'. Gold members have been on www.TheBrokerSite.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
															<div v-if="item.isTrusted" class='member_trusted float-left mr-2' title="This company is a 'Trusted Member'. Trusted members have been on www.TheBrokerSite.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty."></div>
															{{item.inv_companyname}}
														</div>
														<div class='truncate' :title='item.description'>{{item.Description}}</div>

														<div class='hidden md:block truncate text-gray-500'>
															Quantity: {{item.Quantity}} - Condition: {{item.PartCondition}} - 
															Price: 
															<span v-if="item.Price!==0">{{currencySymbol}} {{item.Price}}</span>
															<span v-else>CALL</span>
														</div>
														
														<div class='block md:hidden text-gray-500'>
															<div class='truncate'>Quantity: {{item.Quantity}}</div>
															<div class='truncate'>Condition: {{item.PartCondition}}</div>
															<div class='truncate'>
																Price: 
																<span v-if="item.Price != '0'">{{currencySymbol}} {{item.Price}}</span>
																<span v-else>CALL</span>
															</div>
														</div>
													</td>

													<!-- <td class="table-cell">
														
													</td> -->
												</tr>
											</tbody>
										</table>
									</div>

								</div>

								
							</div>
						</div>

					</div>
				</div>

				<ModalRFQ ref='modalRFQ'></ModalRFQ>

			</div>

		</template>
	</base-layout>
</template>

<script>


import { mapState, mapGetters } from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'
import ModalRFQ from '@/components/ModalRFQ.vue'

import store from '../store'

export default {
  name: 'PartStats',
  components: {
    BaseLayout,
    ModalRFQ,
  },

    beforeRouteEnter(to, from, next) {
		let givenPartNumber = to.fullPath.replace(/[^a-zA-Z0-9]/g, '').toUpperCase() // replace all special characters from the string and convert to uppercase
		store.dispatch('getPartStat', givenPartNumber).then(resp => {
			next(vm => {
				vm.part = resp.result.part[0]
				vm.partStats = resp.result.partStats
				if (!vm.part) {
					return
				}

				document.title =  `${vm.part.partnumber} - ${vm.part.manufacturer} - The Broker Site`
				document.querySelector('meta[name="description"]').setAttribute("content", vm.part.description);
				document.querySelector('meta[name="keywords"]').setAttribute("content", vm.part.manufacturer + ", " + vm.part.partnumber + ", TBS, The, Broker, Site, Part, Statistics, Availability");
			})
		})
    },
    data(){
		return {
			part: null,
			sortBy: 'Price',
			sortDirection: 'asc',
		}
	},
	computed: {
		...mapState(["route", 'lastSearch', "user", 'nrParts', 'currency']),
		...mapGetters(['partsEnriched', 'countriesOptions', 'companyNameMap']),
		currencySymbol(){
			switch(this.currency){
				case "EUR": return "€";
				case "USD": return "$";
				case "GBP": return "£";
			}
			return "€"
		},
		allTraders() {
			const partStatsSorted = this.partStats.slice() // we slice it so a new array is made. Can't make changes to original data in computed

			return partStatsSorted.sort((p1,p2) => {
				let modifier = 1;
				if(this.sortDirection === 'desc') modifier = -1;
				if(p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier; if(p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
				return 0;
			});
		},
		origPartNumber() {
			// return window.location.pathname.split("/").pop() // this returns the partnumber that was entered
			return this.part.partnumber
		},
		showStats() {
			// we have all the data for the statistics and company listing in this.partStats
			// we have to filter this and show the results

			if (this.partStats.length === 0) {
				return {
					results: 0,
					minPrice: 'N/A',
					maxPrice: 'N/A',
					traderCount: 0,
					totalQuantity: 0,
					countries: null,
				}
			}

			let min = Math.min(...this.partStats.filter(partStat => parseInt(partStat.Price) > 0).map(item => item.Price))
			let max = Math.max(...this.partStats.filter(partStat => parseInt(partStat.Price) > 0).map(item => item.Price))
			let traderCount = [...new Set(this.partStats.map(x => x.inv_companyname))].length
			let arrCountries = [...new Set(this.partStats.map(x => x.inv_country))]
			let arrCountriesFormatted = [arrCountries.slice(0, -1).join(', '), arrCountries.slice(-1)[0]].join(arrCountries.length < 2 ? '' : ' and ')
			let countries = arrCountries.length>1 ? `countries: ${arrCountriesFormatted}` : `country: ${arrCountriesFormatted}`
			
			let allConditions = [...new Set(this.partStats.map(x => x.PartCondition))].join(", ")
			// TO DO: remove conditions from this list that are empty or have '' or "CALL" as their conditions

			let totalQuantity = this.partStats.reduce(function(prev, cur) {
				return prev + cur.Quantity;
			}, 0);

			min = min == 'Infinity' ? 'N/A' : `€ ${min}`
			max = max == '-Infinity' ? 'N/A' : `€ ${max}`

			return {
				results: traderCount,
				minPrice: min,
				maxPrice: max,
				traderCount: traderCount>1 ? `${traderCount} traders` : `${traderCount} trader`,
				totalQuantity,
				allConditions,
				countries: countries,
			}
		}
	},
	methods: {
		toCompanyPage(companyId){
			return window.open(this.$router.resolve({path: `/companies/${companyId}` }).href, '_blank');
		},
     	toFreeTrialPage(){
     		this.$router.push({name: `freeTrial` });
     	},		
	  	navToPart(item, event){
			return window.open(this.$router.resolve({path: `/parts/${item.id}` }).href, '_blank');
			// if(event.ctrlKey || event.metaKey) return window.open(this.$router.resolve({path: `/parts/${item.id}` }).href, '_blank');
			// this.$router.push({path: `/parts/${item.id}` });
		},
		sort: function (s) {
			if(s === this.sortBy) {
				this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
			}
			this.sortBy = s;
		}
	}
}
</script>

<style scoped>
	.member_trusted{
		width: 80px; 
		height: 80px;
		background: url("../assets/trusted.png");
		background-size: cover;
	}
	.member_gold{
		width: 80px; 
		height: 80px;
		background: url("../assets/gold.png");
		background-size: cover;
	}
	.member_diamond{
		width: 80px; 
		height: 80px;
		background: url("../assets/diamond.png");
		background-size: cover;
	}
	.member_trusted_small{
		width: 24px; 
		height: 24px;
		background: url("../assets/trusted.png");
		background-size: cover;
	}
	.member_gold_small{
		width: 24px; 
		height: 24px;
		background: url("../assets/gold.png");
		background-size: cover;
	}
	.member_diamond_small{
		width: 24px; 
		height: 24px;
		background: url("../assets/diamond.png");
		background-size: cover;
	}
</style>
