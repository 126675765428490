
<template>
  <div>
    <div v-if='doShowOnSeperatePage'>
      <div class='mt-4'>
        <strong>Please change your broadcast settings below</strong><br/>
        If you have problems changing your broadcast settings, please 
        <a href='javascript:void(0)' class='hover:underline text-blue-500' @click='navigateToContact'>contact us</a>

        <div class='mt-4 mb-4'>
          <div v-if='user.emailAlt'>
            Email: 
            <select v-model='isAlternateMailSelected' class="form-select inline-block md:ml-4 pr-8">
              <option :value="false">Primary ({{user.email}})</option>
              <option :value="true">Alternative ({{user.emailAlt}})</option>
            </select>
          </div>
          <div v-else class='leading-9'>Email: <span class='font-bold'>{{user.email}}</span></div>
        </div>

        <div class="md:flex md:flex-row-reverse mt-6 mb-8 gap-8 ">
          <div class="md:w-2/5 bg-gray-200 px-4 py-4 mb-8 rounded-md shadow">
            <p>
              <div class='font-bold'>No-mail</div>
              You will not receive any broadcasts from the chosen lists, but you can still send to all broadcast lists.
            </p>
            <p class='mt-4'>
              <div class='font-bold'>Standard</div>
              You will receive each broadcast as they are sent by our members.
            </p>
            <p class='mt-4'>
              <div class='font-bold'>Digest</div>
              You will receive one daily summary of the broadcasts that have been sent that day
            </p>
          </div>
          <div class="md:w-3/5 px-4 py-4 rounded-md bg-white shadow overflow-hidden">
            <table >
              <tr class='border-b-2'>
                <th class='px-4 py-2'>Broadcast List</th>
                <th class='px-4 py-2'>No Email</th>
                <th class='px-4 py-2'>Standard</th>
                <th class='px-4 py-2'>Digest</th>
              </tr>
              <tr v-for='setting in settings' :key="setting.listId" class='border-b'>
                <td class='px-4 py-2'>{{setting.label}}</td>
                <td class='px-4 py-2'><input type="radio" :value="2" v-model="setting.type"></td>
                <td class='px-4 py-2'><input type="radio" :value="0" v-model="setting.type"></td>
                <td class='px-4 py-2'><input type="radio" :value="1" v-model="setting.type"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div v-if='!doNoBroadcastSettingsSave' class="mt-5 md:mt-4 md:flex md:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm md:ml-3 md:w-auto">
          <button @click='updateSettings' type="button" 
          class="btn btn-primary inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 md:text-sm md:leading-5">
          Save Settings</button>
        </span>
      </div>
    </div>
    <div v-else-if='user'>
    	<modal  v-show="showModal" @submit="updateSettings" @cancel="showModal = false"
        :doSkipHeader="true"
        :disableSubmit='doNoBroadcastSettingsSave'
  			customCssClasses="md:max-w-4xl"
  			affirmativeButtonLabel='Save Settings'>
  			<template v-slot:body>
          <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
            <a class="hover:underline" href="javascript:void(0)" @click="navToBroadcastSettings">
              Update Broadcast Settings
              <svg class="inline-block ml-2 -mt-1" width="18" height="18" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </a>
          </h3>

  				<div class='mt-4'>
  					<strong>Please change your broadcast settings below</strong><br/>
  					If you have problems changing your broadcast settings, please 
  					<a href='javascript:void(0)' class='hover:underline text-blue-500' @click='navigateToContact'>contact us</a>.

            <div class='mt-4 mb-4'>
              <div v-if='user.emailAlt'>
                Email: 
                <select v-model='isAlternateMailSelected' class="form-select inline-block md:ml-4 pr-8">
                  <option :value="false">Primary ({{user.email}})</option>
                  <option :value="true">Alternative ({{user.emailAlt}})</option>
                </select>
              </div>
              <div v-else class='leading-9'>Email: <span class='font-bold'>{{user.email}}</span></div>
            </div>

  					<div class="md:flex md:flex-row-reverse mt-6 mb-8">
              <div class="md:w-2/5 bg-gray-200 px-4 py-4 rounded-md">
  					  	<p>
  						  	<div class='font-bold'>No-mail</div>
  								You will not receive any broadcasts from the chosen lists, but you can still send to all broadcast lists.
  							</p>
  							<p class='mt-4'>
  								<div class='font-bold'>Standard</div>
  								You will receive each broadcast as they are sent by our members.
  							</p>
  							<p class='mt-4'>
  								<div class='font-bold'>Digest</div>
  								You will receive one daily summary of the broadcasts that have been sent that day
  							</p>
  					  </div>
  					  <div class="md:w-3/5 overflow-hidden">
  					  	<table >
  								<tr class='border-b-2'>
  							    <th class='px-4 py-2'>Broadcast List</th>
  							    <th class='px-4 py-2'>No Email</th>
  							    <th class='px-4 py-2'>Standard</th>
  							    <th clasxs='px-4 py-2'>Digest</th>
  							  </tr>
  								<tr v-for='setting in settings' :key="setting.listId" class='border-b'>
  									<td class='px-4 py-2'>{{setting.label}}</td>
  									<td class='px-4 py-2'><input type="radio" :value="2" v-model="setting.type"></td>
  									<td class='px-4 py-2'><input type="radio" :value="0" v-model="setting.type"></td>
  									<td class='px-4 py-2'><input type="radio" :value="1" v-model="setting.type"></td>
  								</tr>
  							</table>
  					  </div>

  					</div>
  				</div>
  		  </template>
  		</modal>
    </div>
  </div>
</template>

<script>

  import { mapActions, mapState } from 'vuex';
  import _ from 'lodash'

  import Modal from './modal';
  import eventBus from '../utils/eventBus'

  export default {
    name: 'BroadcastSettings',
    props: {
      doShowOnSeperatePage:{
        type: Boolean,
        default: false
      },
    },  
    data: () => ({
      doNoBroadcastSettingsSave: false, //during test
    	showModal: false,
    	settings: [],
      settingsBothEmail: {},
      isAlternateMailSelected: false,
    }),
    components: {
    	Modal
    },
    created(){
      if(this.doShowOnSeperatePage){
        this.loadBroadcastSettingsForCurrentEmail();
       
      } else {
        this.$on("showModal", () => {
          this.showModal = true;
          this.loadBroadcastSettingsForCurrentEmail();
        })
      }
    },
    computed: {
    	...mapState(["user"]),
    },
    watch: {
      isAlternateMailSelected(isAlternate){
        this.settings = this.settingsBothEmail[isAlternate ? "alternate" : "primary"]
      },
    },
    methods: {
    	...mapActions(["getBroadcastSettings", 'updateBroadcastSettings']),
      async loadBroadcastSettingsForCurrentEmail(){
         this.settingsBothEmail = await this.getBroadcastSettings()
         this.settings = this.settingsBothEmail.primary;
      },
    	async updateSettings(){

        const allSettings = [..._.values(this.settingsBothEmail.primary), ..._.values(this.settingsBothEmail.alternate)]

    		const resp = await this.updateBroadcastSettings(allSettings) ;

    		if(resp.data.status !== 200){
     			eventBus.$emit("createSnackbar", {
     				type: "error", 
     				title: "Something went wrong",
     				body: "Broadcast settings could not be updated. Please try again. <br/> If the problem persists please contact The Broker Site",
     				timeoutInMS: 5000,
     			})
     		} else {
     			eventBus.$emit("createSnackbar", {
     				type: "success", 
     				title: "Broadcast settings succesfully updated",
     			})

     			this.showModal = false;
     		}

    	},
      navToBroadcastSettings(){
        window.open(this.$router.resolve({name: 'broadcastSettings'}).href, '_blank');
      },
    	navigateToContact(){
    		this.$router.push('/contact')
    	}
    }
  };
</script>
