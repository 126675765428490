
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <!-- <div class="mb-5">As of January 1, 2021</div> -->
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <!-- larger screens -->
          <table class="min-w-full divide-y divide-gray-200 hidden md:table">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Credit Note<br />
                </th>
                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Account type
                </th> -->
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Credit note date
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Payment date
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Payment status
                </th>
                <th scope="col" class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(creditNote, invoiceIdx) in creditNotesEnriched" :key="creditNote.id" :class="invoiceIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                  <div class='text-gray-900'>{{ creditNote.invoiceNumber }}</div>
                  {{ creditNote.accountType }}
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  &euro; {{ Number(creditNote.total).toFixed(2) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ dateHuman(creditNote.invoiceDate) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ !creditNote.datePaid ? '-' : dateHuman(creditNote.datePaid) }}
                </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full" :class='creditNote.paymentStatusClasses'>
                    {{ creditNote.paymentStatusHuman }}
                  </span>
                </td>
                <td class="pl-6 pr-6 py-4 whitespace-nowrap text-right text-sm font-medium">

                  <button type="button"  @click='downloadPDFCn(creditNote)' class="-ml-px relative inline-flex items-center px-4 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500">
                    Pdf
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- small -->
          <table class="min-w-full divide-y divide-gray-200 md:hidden">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Credit Note
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(creditNote, invoiceIdx) in creditNotesEnriched" :key="creditNote.id" :class="invoiceIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500 relative">
                  <span class="float-right px-2 inline-flex text-xs leading-5 font-semibold rounded-full" :class='creditNote.paymentStatusClasses'>
                    {{ creditNote.paymentStatusHuman }}
                  </span>

                  <div class='text-gray-900'>{{ creditNote.invoiceNumber }}</div>
                  <div>{{ creditNote.accountType }}</div>
                  

                  <div class='absolute bottom-4 right-6'>
                    <span v-if='!creditNote.paymentStatus' class="relative z-0 inline-flex shadow-sm rounded-md">
                      <!-- <a type="button" :href='creditNote.link' target="_blank" class="relative inline-flex items-center px-4 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-red-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500">
                        Pay
                      </a>
                      <button type="button" @click='downloadPDF(invoice)' class="-ml-px relative inline-flex items-center px-4 py-1 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500">
                        Pdf
                      </button> -->
                    </span>
                    <button v-else type="button"  @click='downloadPDFCn(creditNote)' class="-ml-px relative inline-flex items-center px-4 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red-500 focus:border-red-500">
                      Pdf
                    </button>
                  </div>

                  <div>Invoice Date: {{ dateHuman(creditNote.invoiceDate) }}</div>
                  <div>Payment Date: {{ !creditNote.datePaid ? '-' : dateHuman(creditNote.datePaid) }}</div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import _ from 'lodash'

  export default {
    name: 'modal',
    props: {
      isEBM: Boolean,
    },
    methods: {
      ...mapActions(["downloadPDFCn"]),
      dateHuman(dtString){
        const dt = new Date(dtString);
        return `
          ${dt.getDate().toString().padStart(2, '0')}/${(dt.getMonth()+1).toString().padStart(2, '0')}/${dt.getFullYear().toString().padStart(4, '0')}
        `
      },
    },
    computed: {
    	creditNotesEnriched(){
        return this.$parent.$parent['creditNotesEnriched']
      }
    },
  };

</script>

<style>

</style>